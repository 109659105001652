import React, { useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import Translate from "../../Components/Multilanguage/Translate";
const PaypalButtonWrapper = ({ subscriptionData }) => {
  const style = { layout: "vertical", color: "blue" };
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const navigate = useNavigate();
  //   useEffect(() => {
  //     if (
  //       subscriptionData?.subscription_type_id === 3 ||
  //       subscriptionData?.subscription_type_id === 4
  //     ) {
  //       dispatch({
  //         type: "resetOptions",
  //         value: {
  //           ...options,
  //           intent: "subscription",
  //         },
  //       });
  //     } else {
  //       dispatch({
  //         type: "resetOptions",
  //         value: {
  //           ...options,
  //           currency: subscriptionData?.currency,
  //         },
  //       });
  //     }
  //   }, [subscriptionData]);

  const onApprove = (data, actions) => {
    const subscriptionID = data.subscriptionID;
    const orderID = data.orderID;
    if (subscriptionData?.subscription_type_id === 3 || subscriptionData?.subscription_type_id === 4) {
      navigate(`/success?token=${subscriptionID}`)
    } else {
      navigate(`/success?token=${orderID}`)
    }
  };

  const onError = (err) => {
    navigate("/failed")
    // Handle payment failure here
  };

  if (subscriptionData?.subscription_type_id) {

    if (
      subscriptionData?.subscription_type_id === 3 ||
      subscriptionData?.subscription_type_id === 4
    ) {
      return (
        <PayPalButtons
          createSubscription={(data, actions) => {
            return actions.subscription
              .create({
                plan_id: subscriptionData?.paypal_keyword,
              })
              .then((orderId) => {
                return orderId;
              });
          }}
          onApprove={onApprove}
          style={{
            label: "subscribe",
            color: "blue"
          }}
        />
      );
    } else {
      return (
        <PayPalButtons
          style={style}
          disabled={false}
          forceReRender={[
            subscriptionData?.price,
            subscriptionData?.currency,
            style,
          ]}
          fundingSource={undefined}
          createOrder={(data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    description: "Rental",
                    amount: {
                      currency_code: subscriptionData?.currency,
                      value: subscriptionData?.price,
                    },
                  },
                ],
              })
              .then((orderId) => {
                return orderId;
              });
          }}
          onApprove={function (data, actions) {
            console.log("onapprove", data.orderID);
            console.log("data", data);
            return actions.order.capture().then(function (data) {
              onApprove(data)
            });
          }}
          // onApprove={onApprove}
          onError={onError}
        />
      );
    }
  }
};

export default PaypalButtonWrapper;
