import React, { useEffect, useState } from "react";
import { ReactComponent as PlayIcon } from "../../../../assets/Icons/play1.svg";
import { ReactComponent as AddToPlaylistIcon } from "../../../../assets/Icons/addtoplaylist.svg";
import { ReactComponent as MoreDetailsIcon } from "../../../../assets/Icons/moredetails.svg";
import { ReactComponent as RemoveFromPlaylistIcon } from "../../../../assets/Icons/removefromplaylist.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { updateWatchlistData } from "../../../../network/service";
import { useSelector, useDispatch } from "react-redux";
import { fetchWatchlistShows } from "../../../MyList/service";
import { getMyListData } from "../../../../Redux/MyList/MyListSlice";
import { toast } from "react-toastify";
import { imageUrlCheck, getDeviceType } from "../../../../utils/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import Translate from "../../../../Components/Multilanguage/Translate";
// import { ReactComponent as PremiumIcon } from "../../../assets/Icons/premium.svg";

import { EffectFade, Thumbs, Autoplay } from "swiper";
// import { Link, useNavigate } from "react-router-dom";
// import { imageUrlCheck, getDeviceType } from "../../../utils/utils";

const GaiaBanner = ({ data }) => {
  const [activeThumb, setActiveThumb] = useState();
  // const [isInWatchlist, setIsInWatchlist] = useState();
  const [watchlistStatus, setWatchlistStatus] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const deviceType = getDeviceType();

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const myList = useSelector((state) => state?.myList?.myList) || [];
  const isRTL = useSelector((state) => state?.rtl?.isRTL);

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

useEffect(() => {
  if (data?.shows?.length > 0) {
    const initialStatus = {};
    data.shows.forEach((item) => {
      initialStatus[item.show_id] = item.watchlist_flag;
    });
    setWatchlistStatus(initialStatus);
  }
}, [data]);



  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);
      if (response?.status === 200) {
        toast.success(flag === 1 ? "Added to watchlist" : "Removed from watchlist", { position: "bottom-center" });
        setWatchlistStatus((prevStatus) => ({
          ...prevStatus,
          [showId]: flag,
        }));
  
        fetchWatchlist(); 
      }
    } catch (err) {
      console.error(err);
    }
  };

  const addToWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 1);
    } else {
      navigate("/login", { state: { path: location?.pathname, showId: showId } });
    }
  };

  const removeFromWatchlist = (showId) => {
    if (user) {
      updateWatchlist(showId, 0);
    }
  };

  const eventClickHandler = (item) => {
      navigate(`/player`, {
        state: { showId: item?.event_id },
      });
  };

  const detailsClickHandler = (item) => {
    if (item?.show_id) {
      navigate(`/show-details/${item?.vanity_url ? item?.vanity_url : item?.show_id}`, {
        state: { showId: item?.show_id },
      });
    } else if (item?.event_id) {
      navigate(`/event/${item?.vanity_url ? item?.vanity_url : item?.event_id}`, {
        state: { showId: item?.event_id },
      });
    }
  };

  const showClickHandler = (item) => {
    if (item?.video_vanity_url){
      navigate(`/videos/${item?.video_vanity_url? item?.video_vanity_url : item?.show_id}`, {  
        state: { showId: item?.show_id },
      });
    }
    else{
      navigate(`/show-details/${item?.vanity_url ? item?.vanity_url : item?.show_id}`, {
        state: { showId: item?.show_id },
      });
    }
  }
  return (
    <div className={`gaiaFeatured ${isRTL ? "rtl" : ""}`}>
      <Swiper
        onSwiper={setActiveThumb}
        slidesPerView={1}
        watchSlidesProgress
        effect={"fade"}
        spaceBetween={0}
        className="mySwiper"
        modules={[EffectFade, Thumbs, Autoplay]}
        allowTouchMove={false}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
      // allowSlideNext={false}
      // allowSlidePrev={false}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
      <div className="bgImageContainer">
        {imageUrlCheck(item?.banner) ? (
          <img src={deviceType === "mobile" ? item?.banner_portrait : item?.banner} alt="BgImage" />
        ) : (
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}${deviceType === "mobile" ? item?.banner_portrait : item?.banner}`}
            alt="BgImage"
          />
        )}
      </div>

      <div className="contents">
        {item?.categories?.[0]?.category_name ? (
        <div className="categoryName">{item.categories[0].category_name} <Translate textKey={'chosen_for_you'} /></div>
        ) : null}
        <div className={`showCast ${!item?.show_cast ? 'hidden' : ''}`}>{item?.show_cast}</div>
        <h1 className="title">{item?.show_name}</h1>
              <div className={`details ${!(item?.director && item?.year) ? 'hidden' : ''}`}>
                <span className="director">{item?.director}</span>
                <span className="year">{item?.year}</span>
              </div>
              <p className="description">{item?.synopsis}</p>
              <div className={`rating ${!(item?.duration_text && item?.rating) ? 'hidden' : ''}`}>
                <span
                  className={`duration ${item?.year ? "showDot" : ""} ${/^[A-Za-z0-9\s]+$/.test(item?.duration_text) ? "ltr" : ""}`}
                >
                  {item.duration_text}
                </span>
                <span className="certificate">{item?.rating}</span>
              </div>

              <div className="buttonContainer">
                {item?.show_id && (<button className="watchNow" onClick={() => showClickHandler(item)}>
                <PlayIcon />
                <span><Translate textKey={'watch_now'} /></span>
                </button>) }
                {item?.event_id && (
                  <button className="watchNow" onClick={() => eventClickHandler(item)}>
                  <PlayIcon />
                  <span><Translate textKey={'watch_now'} /></span>
                  </button>
                )}
                {watchlistStatus[item.show_id] === 0 ? 
                (
                  <button className="addToPlaylist" onClick={()=>{addToWatchlist(item?.show_id);}}>
                  <AddToPlaylistIcon />
                  <Translate textKey={'add_to_mylist'} />
                </button>) : (
                  <button className="addToPlaylist" onClick={()=>{removeFromWatchlist(item?.show_id);}}>
                    <RemoveFromPlaylistIcon />
                    <Translate textKey={'remove_from_mylist'} />
                  </button>
                )}
            {(item?.video_vanity_url || item?.event_id) && (<button className="moredetails" onClick={()=> detailsClickHandler(item)}>
              <MoreDetailsIcon />
              <Translate textKey={'more_details'} />
            </button>)}
              </div>
            </div>
            </SwiperSlide>
        ))}
      </Swiper>
          </div>  
  );
};

export default GaiaBanner;
