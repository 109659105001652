import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigateToCategory, rowItemCount } from "../../../../utils/utils";
import Translate from "../../../../Components/Multilanguage/Translate";

const GaiaGenres = ({ data }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const navigate = useNavigate();
  const [swiperKey, setSwiperKey] = useState(0);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;

  const count = data?.length;
  let displayedData = data?.shows;

  if(isRTL && count > 5){
    displayedData = [...displayedData].reverse();
  }

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [isRTL]);
  
  return (
    <div className={`gaiaGenresContainer ${template === "TEMPLATE_9" ? "template-9" : ""} ${isRTL ? (count > 5 ? "ltr" : "rtl") : ""}`}>
      <div className="wrapper">
        <div className="header">
          <h1><Translate textKey={'genres'} /></h1>
        </div>
        <Swiper
        key={swiperKey}
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={5}
          slidesPerGroup={5}
          navigation={true}
          watchSlidesProgress
          loop={false}
          initialSlide={(isRTL && count > 5) ? count : 0}
          className="items"
          breakpoints={{
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 20,
            },
            980: {
              slidesPerGroup: 5,
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1200: {
              slidesPerGroup: 5,
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
        >
          {displayedData?.map((item, index) => (
            <SwiperSlide key={index} className="category-slide">
              <div
                className="category-name"
                onClick={() => navigateToCategory(navigate, displayedData[index])}
              >
                {/* <div className="image-container">
                <img 
                  src={item?.logo || projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE} 
                  onError={(e) => {
                    e.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE;
                  }}
                  alt="Banner"
                />
                </div> */}
                                {item?.logo ? (
                  <div className="image-container">
                    <img 
                      src={item?.logo} 
                      onError={(e) => {
                        e.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE;
                      }}
                      alt="Banner"
                    />
                  </div>
                ) : (
                  <div className="image-container placeholder">
                    <h1 className="placeholder-text">{item?.category_name}</h1>
                  </div>
                )}
                <div 
                  className={`genre-name ${
                    template === "TEMPLATE_10" 
                      ? "gaia" 
                      : template === "TEMPLATE_9" 
                      ? "template_9" 
                      : ""
                  }`}
                >
                  {item?.category_name}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default GaiaGenres;
