import React ,{useState ,useRef, useEffect} from "react";
import { useSelector,useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {  useLocation, useNavigate } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import ShowCard from "../../Components/ShowCard/ShowCard";
import { rowItemCount } from "../../utils/utils";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { authenticate2} from "../../network/service";
import Translate from "../../Components/Multilanguage/Translate";
import GaiaCard from "../../Components/ShowCard/GaiaCard";

const ShowAllEpisodes = () =>{

    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
    const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
    const user = useSelector((state) => state?.user?.value);
    const sessionId = useSelector((state) => state?.sessionId?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const template = projectInfo?.projectConfig?.config?.TEMPLATE;

    const [rowItemsClass,setRowItemsClass] = useState("")
    const [loading, setLoading] = useState(true);
    const [sliceCount,setSliceCount] = useState(20)
 
    const location = useLocation();
    const windowSize = useWindowWidth()
    const { state } = location;
    const {  selectedSeasonData = [],data, showname, showDetailsData } = state || {};

    const scrollPosition = useScrollPosition();
    const containerRef = useRef(null)

    const showId = location?.state?.showDetailsData?.show_id || location?.state?.showId;
   
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const appInfo = {
      projectDetails: projectInfo,
      accessToken: accessToken,
      sessionId,
    };

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
    }, [projectInfo,windowSize]);

    useEffect(()=>{
        if(!loading && sliceCount<data?.length){
          lazyLoad()
        }
      },[loading,scrollPosition,data])

      const lazyLoad = () => {
        const containerHeight = containerRef?.current?.clientHeight;
        if(scrollPosition + 2200 >= containerHeight){
          setSliceCount(sliceCount+20)
        }
      }
     
      const playVideo = async (videoDetails) => {
        if (videoDetails?.vanity_url) {
          navigate(`/videos/${videoDetails?.vanity_url}`, { state: { videoDetails, backURL: `/show-details/${showDetailsData?.vanity_url}`}});
        }
      };
      const userCheckAndVideoPlay = async (videoDetails) => {
        setLoading(true);
        if (!user)
        {
         navigate("/login", {
           state: { path: location?.pathname, 
            showId: showId ,
            data:data,
            showname:showname,
            showDetailsData:showDetailsData,
            selectedSeasonData:selectedSeasonData},
         });
       }
      };

      const watchVideoHandler = async (videoDetails) => {
       
        const registrationMandatory = projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true";
        const loginNeeded = userSubscriptionData?.login_needed;
    
        if (registrationMandatory) {
            playVideo(videoDetails);
            userCheckAndVideoPlay(videoDetails);
        } else {
            playVideo(videoDetails);
        }
    };
    
      const tokenAuthenticate = async () => {
        const response = await authenticate2(appInfo);
        if (response?.status === 200) {
          dispatch(
            getAccessToken({
              accessToken: response?.data?.token,
            })
          );
        }
      };
     
    return(
        <>
         <div className="showallPage" ref={containerRef}>
            <ToastContainer/>
           
            <div className="wrapper">
                {
                     <div className="topSection">
                         <h1 className="heading">{showname?showname:(location?.state?.show)} - <Translate textKey={'all_episodes'}/></h1>
                    </div>
                }
              <div className="bottomSection">
                <div className="itemsContainer">  

                {(selectedSeasonData?selectedSeasonData:(location.state.showDetailsData||location.state.selectedSeasonData))?.map((item, index) => (
                  <div
                    key={index}
                    className={thumbnailOrientation === 'PORTRAIT' ? `items portrait ${rowItemsClass}` : `items landscape ${rowItemsClass}`}
                    onClick={() => watchVideoHandler(item)}
                  >
                    {template === "TEMPLATE_10" ? (<GaiaCard
                      data={item}
                      key={index} 
                      imageUrl={true}
                      season={false}
                      metaData={true}
                    />) : (<ShowCard
                      data={item}
                      key={index} 
                      imageUrl={true}
                      season={false}
                      metaData={true}
                    />) }
                  </div>
                ))}
                  </div>
                </div>
            </div>          
        </div>
        </>
       
    );
};
export default ShowAllEpisodes;
