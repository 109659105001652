import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getMenuItems } from "./service";
import { ReactComponent as MenuIcon } from "../../assets/Icons/MenuIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";

import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { authenticate2 } from "../../network/service";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import Translate from "../Multilanguage/Translate";
const CustomHeader = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);

  const [menuAllShowsItems, setMenuAllShowsItems] = useState();
  const [hambergerMenu, setHambergerMenu] = useState(false);
  const [activeChildNav, setActiveChildNav] = useState("");

  const location = useLocation()
  const dispatch = useDispatch()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const productsMenuItems = [
    {
      label: "Asset Protection",
      link: "https://store.hisadvocates.org/asset-protection.html",
    },
    {
      label: "Crypto Training",
      link: "https://store.hisadvocates.org/crypto-training.html",
    },
    {
      label: "Living in the Private Program",
      link: "https://store.hisadvocates.org/Living-in-the-Private-Program_c_30.html",
    },
    {
      label: "State Citizen Passport",
      link: "https://store.hisadvocates.org/state-Citizen-Passport",
    },
    {
      label: "Private Consultation",
      link: "https://store.hisadvocates.org/private-consultation.html",
    },
    {
      label: "Seminars-Webinars",
      link: "https://store.hisadvocates.org/Seminars-Webinars_c_82.html",
    },
    {
      label: "VIP Membership",
      link: "https://www.hisadvocates.org/order?redirect=https%3A%2F%2Fwww.hisadvocates.org",
    },
    {
      label: "Private Trusts",
      link: "https://store.hisadvocates.org/trusts.html",
    },
    {
      label: "Private Wills",
      link: "https://store.hisadvocates.org/private-wills.html",
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    fetchMenuAllShowsItems();
  }, []);

  const fetchMenuAllShowsItems = async () => {
    try {
      const menuResponse = await getMenuItems(appInfo);
      if (menuResponse?.status === 200) {
        setMenuAllShowsItems(menuResponse?.data?.data);
      }
    } catch (err) { }
  };

  const logoutHandler = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedSubId");
    localStorage.removeItem("deviceType");
    localStorage.removeItem("selectedAmount");
    localStorage.removeItem("vanityUrl");
    localStorage.removeItem("selectedLanguageCode");
    localStorage.removeItem("selectedLanguageName");
    localStorage.removeItem("selectedLanguageId");
    setHambergerMenu(false)
    tokenAuthenticate();
    window?.FB && window.FB.logout(function (response) {
      // user is now logged out
    });
  };
  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      navigate("/home");
      setTimeout(() => {
        dispatch(
          getUser({
            user: null,
          })
        );
      }, 300);
    }
  };

  const hambergerChildNavHandler = (type) => {
    switch (type) {
      case "allshows":
        activeChildNav === "allshows" ? setActiveChildNav("") : setActiveChildNav("allshows")
        break;
      case "products":
        activeChildNav === "products" ? setActiveChildNav("") : setActiveChildNav("products")
        break;
      case "account":
        activeChildNav === "account" ? setActiveChildNav("") : setActiveChildNav("account")
        break;
      default:
        setActiveChildNav("")
    }

  }
  return (
    <div className="customHeaderComponent">
      <div className="wrapper">
        <div className="left">
          <div className="hamburgerMenuIconContainer">
            {
              hambergerMenu ? (
                <CloseIcon onClick={() => setHambergerMenu(false)} />
              ) : (
                <MenuIcon onClick={() => setHambergerMenu(true)} />

              )
            }
          </div>
          <h1 className="logo" onClick={() => navigate("/home")}>
            <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
          </h1>
        </div>
        <div className="center">
          <ul className="navlinks">
            <li className={location?.pathname === "/home" ? "active link" : "link"} onClick={() => navigate("/home")}>
              <span><Translate textKey={'home'} /></span>
            </li>
            <li className={location?.pathname === "/about-us" ? "active link" : "link"} onClick={() => navigate("/about-us")}>
              <span><Translate textKey={'about_us'} /></span>
            </li>
            <li className={location?.pathname === "/contact-support" ? "active link" : "link"} onClick={() => navigate("/contact-support")}>
              <span><Translate textKey={'contact_us'} /></span>
            </li>

            {
              menuAllShowsItems?.length > 0 &&
              <li className="link  allShows">
                <span><Translate textKey={'all_shows'} /></span>
                <i className="fa-solid fa-angle-down"></i>
                <ul className="childNavs scroll">
                  {menuAllShowsItems?.map((categoryItem, index) =>
                    categoryItem?.items?.map((shows) => (
                      <li className="childLink" onClick={() => navigate(`/category/${shows?.value}`, { state: { careers: shows?.key } })}>
                        <span>{shows?.value}</span>
                      </li>
                    ))
                  )}
                </ul>
              </li>
            }

            {/* <li className="link products">
              <span>Products</span>
              <i className="fa-solid fa-angle-down"></i>
              <ul className="childNavs">
                {productsMenuItems?.map((item, index) => (
                  <li key={index} className="childLink" onClick={() => window.open(item?.link, "_blank")}>
                    <span>{item?.label}</span>
                  </li>
                ))}
              </ul>
            </li> */}
            <li className="link  contentProducers" onClick={() => navigate("/blank")}>
              <span><Translate textKey={'content_producers'} /></span>
            </li>
            {
              user ? (
                <li className="link account">
                  <span><Translate textKey={'account'} /></span>
                  <i className="fa-solid fa-angle-down"></i>
                  <ul className="childNavs">
                    <li className="childLink" onClick={() => navigate("/my-list")}>
                      <span><Translate textKey={'my_list'} /></span>
                    </li>
                    <li className="childLink" onClick={() => navigate("/tv")}>
                      <span><Translate textKey={'link_tv_app'} /></span>
                    </li>
                    <li className="childLink" onClick={() => navigate("/account")}>
                      <span><Translate textKey={'settings'} /></span>
                    </li>
                    <li className="childLink" onClick={() => logoutHandler()}>
                      <span><Translate textKey={'sign_out'} /></span>
                    </li>
                  </ul>
                </li>

              ) : (
                <li className="link" onClick={() => navigate("/login")}>
                  <span><Translate textKey={'login'} /> /<Translate textKey={'register'} /></span>
                </li>
              )
            }
          </ul>
        </div>
        <div className="right" onClick={() => navigate("/player")}>
          <div className="liveLogo">
            <img src={require("../../assets/Icons/live.png")} alt="Live" />
          </div>
          <span className="live"><Translate textKey={'watch_live'} /></span>
        </div>
      </div>

      {
        hambergerMenu &&
        <div className="hambergurMenu showMenu">
          <ul className="navlinksResponsive">
            <li className={location?.pathname === "/home" ? "active link" : "link"} onClick={() => { navigate("/home"); setHambergerMenu(false); setActiveChildNav("") }}>
              <span><Translate textKey={'home'} /></span>
            </li>
            <li className={location?.pathname === "/about-us" ? "active link" : "link"} onClick={() => { navigate("/about-us"); setHambergerMenu(false); setActiveChildNav("") }}>
              <span><Translate textKey={'about_us'} /></span>
            </li>
            <li className={location?.pathname === "/contact-support" ? "active link" : "link"} onClick={() => { navigate("/contact-support"); setHambergerMenu(false); setActiveChildNav("") }}>
              <span><Translate textKey={'contact_us'} /></span>
            </li>
            {
              menuAllShowsItems?.length > 0 &&
              <li className={activeChildNav === "allshows" ? "link active" : "link"} onClick={() => hambergerChildNavHandler("allshows")}>
                <div className="expandLink">
                  <span><Translate textKey={'all_shows'} /></span>
                  <i className="fa-solid fa-angle-down"></i>
                </div>

                <ul className="childLinks scroll">
                  {menuAllShowsItems?.map((categoryItem, index) =>
                    categoryItem?.items?.map((shows) => (
                      <li className="childLink" onClick={() => { navigate(`/category/${shows?.value}`, { state: { careers: shows?.key } }); setActiveChildNav(""); setHambergerMenu(false) }}>
                        <span>{shows?.value}</span>
                      </li>
                    ))
                  )}
                </ul>
              </li>

            }

            {/* <li className={activeChildNav==="products"?"link active":"link"} onClick={()=>hambergerChildNavHandler("products")}>
            <div className="expandLink">
              <span>Products</span>
              <i className="fa-solid fa-angle-down"></i>
            </div>
            <ul className="childLinks scroll">
            {productsMenuItems?.map((item, index) => (
                  <li key={index} className="childLink" onClick={() => window.open(item?.link, "_blank")}>
                    <span>{item?.label}</span>
                  </li>
                ))}
            </ul>
          </li> */}
            <li className="link  contentProducers" onClick={() => navigate("/blank")}>
              <span><Translate textKey={'content_producers'} /></span>
            </li>
            {
              user ? (

                <li className={activeChildNav === "account" ? "link active" : "link"} onClick={() => hambergerChildNavHandler("account")}>
                  <div className="expandLink">
                    <span><Translate textKey={'account'} /></span>
                    <i className="fa-solid fa-angle-down"></i>
                  </div>
                  <ul className="childLinks">
                    <li className="childLink" oonClick={() => { navigate("/my-list"); setHambergerMenu(false); setActiveChildNav("") }}>
                      <span><Translate textKey={'my_list'} /></span>
                    </li>
                    <li className="childLink" onClick={() => { navigate("/tv"); setHambergerMenu(false); setActiveChildNav("") }}>
                      <span><Translate textKey={'link_tv_app'} /></span>
                    </li>
                    <li className="childLink" onClick={() => { navigate("/account"); setHambergerMenu(false); setActiveChildNav("") }}>
                      <span><Translate textKey={'settings'} /></span>
                    </li>
                    <li className="childLink" onClick={() => logoutHandler()}>
                      <span><Translate textKey={'sign_out'} /></span>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="link" onClick={() => { navigate("/login"); setHambergerMenu(false); setActiveChildNav("") }}>
                  <span><Translate textKey={'login'} /> / <Translate textKey={'register'} /></span>
                </li>
              )
            }
          </ul>
        </div>
      }
    </div>
  );
};

export default CustomHeader;
