import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading/Loading";
import ShowCard from "../ShowCard/ShowCard";
import { useWindowWidth } from "@react-hook/window-size";
import { rowItemCount } from "../../utils/utils";
import useScrollPosition from "../ScrollPosition/useScrollPosition";
import GaiaCard from "../ShowCard/GaiaCard";
import { ReactComponent as Arrow } from "../../assets/Icons/filledArrow.svg";

const GaiaShowsRow = ({ season, data, type, title, metaData,watchVideoHandler,setVideoPlayer,similarVideos,showname ,showDetailsData }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);

  const [shows, setShows] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [rowItemsClass, setRowItemsClass] = useState("");
  const [seasonButton, setSeasonButton] = useState(false);
  const [details, setDetails] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [seasonName, setSeasonName] = useState("");

  const { careers } = useParams();
  const navigate = useNavigate();
  const windowSize = useWindowWidth();
  const containerRef = useRef(null);
  const scrollPosition = useScrollPosition();

  const template = projectInfo?.projectConfig?.config?.TEMPLATE;
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  const thumbnailOrientation = location?.state?.thumbnailOrientation
    ? location?.state?.thumbnailOrientation
    : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;

  useEffect(() => {
    fetchCategoryDetails();
  }, [location]);

  useEffect(() => {
    if (windowSize > 980) {
      if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) {
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT));
      } else {
        setRowItemsClass("rowCount5");
      }
    } else {
      setRowItemsClass("rowCount5");
    }
  }, [projectInfo, windowSize]);

  useEffect(() => {
    if (data && !season) {
      setDetails(data);
    } else {
        setDetails(data[0]?.videos)
      setSeasons(data);
      if (data[0]?.season)
        { setSeasonName(data[0].season); } 
    }
  }, [data]);

  const fetchCategoryDetails = async () => {
    try {
      setShows(data);
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
      setLoading(false);
    }
  };

  const handleSeasonClick = (item) => {
    setDetails(item?.videos);
    setSeasonName(item?.season);
    setSeasonButton(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={`commonPage ${isRTL ? "rtl" : ""}`} ref={containerRef} style={{ paddingTop: "0px" }}>
              <div className="top">
                <h1 className="heading">{shows?.category_name}</h1>
                <p className="desc">{shows?.synopsis}</p>
              </div>

            <div className="bottom">
              <h1 style={{ paddingTop: "10px", paddingBottom: "20px" }}>{title}</h1>

              {season && (
                <div
                  className={seasonButton ? "seasonContainer zIndexController" : "seasonContainer"}
                  onClick={() => setSeasonButton(!seasonButton)}
                >
                  <span className="selected">{seasonName || seasons[0]?.season}</span>
                  <div className={season?.length > 5 ? "listContainer scroll" : "listContainer"}>
                    <div className={seasonButton ? "list active" : "list"}>
                      {seasons?.map((item, index) => (
                        <div
                          className="item"
                          key={index}
                          onClick={() => handleSeasonClick(item)}
                        >
                          <span>{item?.season}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Arrow className={seasonButton ? "active" : "no-active"} />
                </div>
              )}

              <div className="itemsContainer">
                    { details?.map((item,index) => (
                      <div
                      key={index}
                        className={
                          thumbnailOrientation === `PORTRAIT`
                            ? `items portrait ${rowItemsClass}`
                            : `items landscape ${rowItemsClass}`
                        }
                      >
                        {template === "TEMPLATE_10" ? (
                          <GaiaCard
                          season={season ? true : false}
                          data={item}
                          imageUrl={true}
                          metaData={metaData ? true : false}
                          type={type}
                          thumbnailOrientation={thumbnailOrientation}
                          />
                        ) : (
                          <ShowCard
                          season={season ? true : false}
                          data={item}
                          imageUrl={true}
                          metaData={metaData ? true : false}
                          type={type}
                          thumbnailOrientation={thumbnailOrientation}
                          />
                        )}
                      </div>
                    ))}
              </div>
            </div>
      </div>
    </>
  );
};

export default GaiaShowsRow;
