import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as AddRatings } from "../../assets/Icons/starRounded.svg";
import { ReactComponent as StarIcon } from "../../assets/Icons/star.svg";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "react-toastify/dist/ReactToastify.css";
import { getPlayerToken, getUserSubscription, updateWatchlistData } from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import { toast } from "react-toastify";
import { imageUrlCheck, onVideoPlayFunction } from "../../utils/utils";
import Loading from "../../Components/Loading/Loading";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import './scss/detailScreen.css';
import { stripeSession } from "../Subscription/service";
import TrailerModal from "../../Components/Modals/TrailerModal";
import RatingsAndReviewModal from "../../Components/Modals/RatingAndReviewModal";
import Translate from "../../Components/Multilanguage/Translate";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import AboutSection from "./Components/AboutSection";
import AwardsAndFestivals from "./Components/AwardsAndFestivals";
import CastAndCrew from "./Components/CastAndCrew";
import CategoriesSection from "./Components/CategoriesSection";
import Util from "./util";

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;

const DetailsScreen = () => {

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const [isShareActive, setIsShareActive] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [watchDuration, setWatchDuration] = useState("");
  const [pageURL, setPageURL] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState("");
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [categories, setCategories] = useState("");
  const [trailerPlaying, setTrailerPlaying] = useState(true);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [adUrl, setAdUrl] = useState("");
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(false);
  const [subscribedUser, setSubscribeduser] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const [pipMode, setPipMode] = useState(false);
  const [nowPlaying, setNowPlaying] = useState("");
  const [episodes, setEpisodes] = useState();
  const [nextEpisodeModal, setNextEpisodeModal] = useState(false);
  const [isFreeVideo, setIsFreeVideo] = useState(false); // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [subscribeButton, setSubscribeButton] = useState();
  const [subtitleInfo, setSubTitleInfo] = useState();
  const [countinueWatchingVideoPlayedStatus, setCountinueWatchingVideoPlayedStatus] = useState(false);
  const [isTrailer, setIsTrailer] = useState(false)
  const [ratingReviewModal, setRatingReviewModal] = useState(false);
  const [userRatedCount, setUserRatedValue] = useState();
  const position = useScrollPosition();
  const location = useLocation();
  const [showId,setShowId] = useState(); 
  const type = location?.state?.type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [videoType, setVideoType] = useState("video")

  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  let scrollDownRef = useRef(null);
  let episodeCount = 0;
  let isLive = false
  const buttonTextColor = projectInfo?.projectConfig?.config?.BUTTON_TEXT_COLOR ;
  const textColor = projectInfo?.projectConfig?.config?.TEXT_COLOR; 

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };
  const playerRef = useRef(null);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;

  const {
    fetchShowDetails,
    fetchShowDetailsByVanityUrl,
    fetchSimilarVideos,
    fetchPlayerToken,
    addToWatchlist,
    removeFromWatchlist,
    subscriptionCheck,
    handleSubscribe,
    watchVideoHandler,
    // handlePlayerReady,
    updateVideoAnalytics,
    findIndex,
    fetchUserSubscriptionDetails,
  } = Util();

  useEffect(() => {
    localStorage.removeItem("couponId");
  }, []);

  useEffect(() => {
    setPageURL(window.location.href);
    fetchUserSubscriptionDetails(appInfo, dispatch);
  }, []);

  useEffect(() => {
    if (location?.state?.thumbnailOrientation === "PORTRAIT" || 
        (!location?.state?.thumbnailOrientation && projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT")) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    localStorage.removeItem("couponId");
  }, []);
  useEffect(() => {
    setAdUrl(null);
    if (showDetails) {
      if (Object.entries(showDetails).length > 0) {
        if (showDetails?.user_rated === true) {
          setUserRatedValue(showDetails?.user_rating);
        }
        else {
          setUserRatedValue(null);
        }
        if (showDetails?.type === "linear_event") {
          setVideoType("event");
        } else {
          setVideoType("video");
        }
        if (showDetails?.categories) {
          let categoryNames;
          showDetails?.categories?.map((item) => {
            categoryNames = categoryNames ? categoryNames + item?.category_name + "," : item?.category_name + ",";
          });
          setCategories(categoryNames);
        }
        if (showDetails?.watchlist_flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
        if (type === "CONTINUE_WATCHING" && countinueWatchingVideoPlayedStatus === false) {
          if (showDetails?.single_video === 0) {
            if (showDetails?.videos && showDetails?.videos[0]?.videos) {
              setEpisodes(showDetails?.videos[0]?.videos);

              let filteredVideo = showDetails?.videos.map((item) => {
                return item?.videos?.filter((videoItem) => videoItem?.video_id === location?.state?.videoId);
              });
              setSelectedVideoDetails(filteredVideo[0][0]);

            } else {
              setEpisodes(showDetails?.videos);

              let filteredVideo = showDetails?.videos?.filter((item) => item?.video_id === location?.state?.videoId);
              setSelectedVideoDetails(filteredVideo[0]);

            }
          } else if (showDetails?.single_video === 1) {
            setEpisodes(null);
            if (showDetails?.videos?.length > 0) {
              setSelectedVideoDetails(showDetails?.videos[0]);

            }
          }
        } else {
          if (showDetails?.teaser_flag === 1) {
            let trailer = true;
            let arr = showDetails?.teaser?.split("/").reverse();
            fetchPlayerToken(
              trailer,
              arr[1],
              appInfo,
              setPlayerToken,
              setVideoUrl,
              showDetails,
              projectInfo
            );
            setNowPlaying("Trailer");
          }
          if (showDetails?.single_video === 1) {
            setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
            setEpisodes(null);
          } else {
            if (showDetails?.videos && showDetails?.videos[0]?.videos && showDetails?.videos[0]?.videos[0]) {
              setSelectedVideoDetails(showDetails?.videos[0]?.videos[0]);
              setEpisodes(showDetails?.videos[0]?.videos);
            } else {
              setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
              setEpisodes(showDetails?.videos);
            }
          }
        }
      }
    }
  }, [showDetails, type]);

  useEffect(() => {
    if (position === 0) {
      setPipMode(true);
    }
  }, [position]);

  useEffect(() => {
    window.scroll(0, 0);
    // if (accessToken && Object.keys(projectInfo).length > 0) {

    if (location?.pathname) {
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        fetchShowDetailsByVanityUrl(
          vanityUrl,
          appInfo,
          setLoading,
          setShowDetails,
	        setShowId,
          setSimilarVideos,
          showId
        );
      } else {
        fetchShowDetails(setLoading, setShowDetails, appInfo, showId);
      }

      if (showId) {
        fetchSimilarVideos(null, appInfo, setSimilarVideos, showId);
      }
    }
    // }
  }, [accessToken, projectInfo, location?.pathname]);

  useEffect(() => {
    if (selectedVideoDetails) {

      if (Object.entries(selectedVideoDetails).length > 0) {
        if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {

            if (selectedVideoDetails?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {

              let isSubscribedUser;
              if (showDetails?.subscriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, selectedVideoDetails?.subscriptions)
                    : false;
              }

              if (isSubscribedUser === true) {
                setSubscribeduser(true);
                setSubscribeButton(false);
              } else {
                setSubscribeduser(false);

                if (selectedVideoDetails?.free_video === true) {

                  // for inital free video checking for showing button name "subscribe" ,"play Now"
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              // setIsFreeVideo(true);
              setSubscribeduser(true);
              setSubscribeButton(false);
            }
          } else {
            setSubscribeduser(true);
            setSubscribeButton(false);
          }
        } else if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "false") {
          setSubscribeButton(false);
          if (userSubscriptionData?.login_needed === false) {
            setIsFreeVideo(true);
          } else {
            setIsFreeVideo(false);
          }
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
        }

        if (showDetails) {
          var updateDuration = selectedVideoDetails?.watched_duration;
          setWatchDuration(updateDuration);
        }
      }
    }
  }, [selectedVideoDetails]);

  useEffect(() => {
    // setVideoUrl("");
    // setPlayerToken("");
    setVideoPlayer(null);
    // setTrailerPlaying(false);
    // videoStarted = false;
    // videoPlaying = false;
  }, [showDetails]);

  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: videoPlay,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],

        // poster:require("../../../images/thumb.png")
      };
      setLoading(false);

      setSkeleton(false);
      setVideoPlayer(
        <VideoJS
          autoplay={true}
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
          isTrailer={true}
        />
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [videoUrl]);

  useEffect(() => {
    setPageURL(window.location.href);
  }, [showDetails]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !shareIconRef?.current?.contains(e.target) &&
        !shareRef?.current?.contains(e.target) &&
        !shareIconTwoRef?.current?.contains(e.target) &&
        !shareTwoRef?.current?.contains(e.target)
      ) {
        setIsShareActive(false);
      }
      if (!reportRef?.current?.contains(e.target) && !reportTwoRef?.current?.contains(e.target)) {
        setIsReport(false);
      }
    };
    window.addEventListener("click", handleOutClick);
  }, [shareRef, reportRef, shareTwoRef, reportTwoRef]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    if (!trailerPlaying) {
      player.on("play", () => {
        let event = videoStarted === true ? "POP09" : "POP02";
        videoStarted = true;
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
      });
      player.on("loadedmetadata", () => {
        player.pause();
        if (watchDuration > 0) {
          player.currentTime(watchDuration.toString());
          player.play();
          videoPlaying = true;
        } else {
          player.play();
          videoPlaying = true;
        }
      });
      player.on("timeupdate", function (e) {
        let event = "POP03";
        videoPlaying = true;
        var hasPlayedTime = player.currentTime();
        var intPlayedTime = parseInt(hasPlayedTime, 10);
        var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
        if (isSixty && debounce) {
          debounce = false;
          prevTime = intPlayedTime;
          updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
        } else {
          if (debounce == false && prevTime != intPlayedTime) {
            debounce = true;
          }
        }
      });

      player.on("pause", () => {
        let event = "POP04";
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
      });
      player.on("ended", () => {
        let event = "POP05";
        videoPlaying = false;
        episodeCount = findIndex( episodes, selectedVideoDetails ) + 1;

        updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
        if (episodes[episodeCount]) {
          setNextEpisodeModal(true);
        }
      });
      player.on("dispose", () => {
        videoPlaying = false;
        videoStarted = false;
        player.pause();
      });
    } else {
      player.on("timeupdate", () => {
        videoPlaying = true;
      });
      player.on("dispose", () => {
        videoPlaying = false;
      });
    }
  };

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="detailsScreen">
      {isTrailer && (
        <TrailerModal
          appInfo={appInfo}
          teaser={showDetails?.teaser}
          showName={showDetails?.show_name}
          setIsTrailer={setIsTrailer}
          showDetails={showDetails}
        />
      )}
      {ratingReviewModal && user !== "null" && (
        <RatingsAndReviewModal
          showName={showDetails?.show_name}
          director={showDetails?.director}
          year={showDetails?.year}
          showId={showDetails?.show_id}
          setRatingReviewModal={setRatingReviewModal}
          setUserRatedValue={setUserRatedValue}
        />
      )}
      <div className="wrapper">
        <div
          className="bgImageContainer"
          style={{
            top: template === "TEMPLATE_9" ? "100px" : "0",
          }}
        >
          {imageUrlCheck(showDetails?.logo_thumb) ? (
            <img src={`${showDetails?.logo_thumb}`} alt="Background" />
          ) : (
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb}`}
              alt="Background"
            />
          )}
          <div className="gradient"></div>
        </div>
        {videoPlayer && template !== "TEMPLATE_9" && (
          <div
            className={
              pipMode === false
                ? "videoContainer"
                : position > 700 &&
                  browserPipMode === false &&
                  videoPlaying === true
                ? "videoContainer heightIncrease"
                : "videoContainer"
            }
          >
            <div
              className={
                position > 700 &&
                browserPipMode === false &&
                videoPlaying === true &&
                pipMode === true
                  ? "player pipMode"
                  : "player"
              }
            >
              <div className="gradientTop"></div>
              <div className="closeIcon" onClick={() => setPipMode(false)}>
                <Closeicon />
              </div>

              <div className="nowPlaying">
                <h1><Translate textKey={'now_playing'}/> : {nowPlaying}</h1>
              </div>

              {videoPlayer && videoPlayer}
            </div>
          </div>
        )}
        <div className="details">
          <div className="leftContainer">
            <div className="showCardDetails">
              <div className="imageContainer">
                {imageUrlCheck(
                  thumbnailOrientation === "PORTRAIT"
                    ? showDetails?.logo
                      ? showDetails?.logo
                      : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
                    : showDetails?.logo_thumb
                    ? showDetails?.logo_thumb
                    : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
                ) ? (
                  <img
                    src={
                      thumbnailOrientation === "PORTRAIT"
                        ? `${
                            showDetails?.logo ||
                            projectInfo?.projectConfig?.config
                              ?.PLACEHOLDER_IMAGE
                          }`
                        : `${
                            showDetails?.logo_thumb ||
                            projectInfo?.projectConfig?.config
                              ?.PLACEHOLDER_IMAGE
                          }`
                    }
                    alt="Background"
                  />
                ) : (
                  <img
                    src={
                      thumbnailOrientation === "PORTRAIT"
                        ? `${process.env.REACT_APP_IMAGE_URL}${
                            showDetails?.logo ||
                            projectInfo?.projectConfig?.config
                              ?.PLACEHOLDER_IMAGE
                          }`
                        : `${process.env.REACT_APP_IMAGE_URL}${
                            showDetails?.logo_thumb ||
                            projectInfo?.projectConfig?.config
                              ?.PLACEHOLDER_IMAGE
                          }`
                    }
                    alt="Background"
                  />
                )}
              </div>
              {!showDetails?.hide_play_button && (
                <div
                  className="watchNow"
                  onClick={() =>
                    watchVideoHandler(
                      selectedVideoDetails,
                      showId,
                      setSelectedVideoDetails,
                      showDetails,
                      categories,
                      thumbnailOrientation
                    )
                  }
                >
                  {/* <button>
                  {
                    subscribeButton && !isFreeVideo ? "Subscribe" :
                      !subscribeButton && isFreeVideo ? "Watch Now" :
                        subscribeButton && isFreeVideo ? "Watch now for free with Ads" :
                          "Watch Now"
                  }
                </button> */}

                  <button
                    style={{
                      color:
                        template === "TEMPLATE_9" ? buttonTextColor : textColor,
                    }}
                  >
                    <Translate textKey={'watch_now'}/>
                  </button>
                </div>
              )}
              {showDetails?.teaser_flag === 1 &&
                showDetails?.teaser &&
                template === "TEMPLATE_9" && (
                  <div className="trailer" onClick={() => setIsTrailer(true)}>
                    <button><Translate textKey={'trailer'}/></button>
                  </div>
                )}
              {watchlistStatus === "added" ? (
                <div
                  className="addtoList"
                  onClick={() =>
                    removeFromWatchlist(
                      setWatchlistStatus,
                      appInfo,
                      showId,
                      user
                    )
                  }
                >
                  <button><Translate textKey={'remove_from_mylist'}/></button>
                </div>
              ) : (
                <div
                  className="addtoList"
                  onClick={() =>
                    addToWatchlist(setWatchlistStatus, appInfo, showId, user)
                  }
                >
                  <button><Translate textKey={'add_to_mylist'}/></button>
                </div>
              )}
              <div className="buttons">
                <div className="buttonContainerOne">
                  <button
                    className="share"
                    onClick={() => {
                      setIsShareActive(!isShareActive);
                      setIsReport(false);
                    }}
                    ref={shareIconRef}
                  >
                    <Translate textKey={'share'}/>
                  </button>
                  <div
                    className={
                      isShareActive ? "active shareContainer" : "shareContainer"
                    }
                    ref={shareRef}
                  >
                    <div
                      className="facebookContainer"
                      onClick={() => setIsShareActive(false)}
                    >
                      <FacebookShareButton
                        url={pageURL}
                        quote={"Share"}
                        className="facebook"
                      >
                        <Translate textKey={'facebook'}/>
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>

                    </div>
                    <div
                      className="twitterContainer"
                      onClick={() => setIsShareActive(false)}
                    >
                      <TwitterShareButton url={pageURL} className="twitter">
                      <Translate textKey={'twitter'}/>
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <div className="buttonContainerTwo">
                  <button
                    className="more"
                    onClick={() => {
                      setIsReport(!isReport);
                      setIsShareActive(false);
                    }}
                    ref={reportRef}
                  >
                    {isReport ? <Closeicon /> : "..."}
                  </button>
                  {isReport ? (
                    <div
                      className={
                        isReport
                          ? "reportProblemContainer active"
                          : "reportProblemContainer"
                      }
                      onClick={() => navigate("/contact-support")}
                    >
                      <span><Translate textKey={'report_problem'}/></span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rightContainer">
            <div className="showMetadata">
              <h1 className="name">{showDetails?.show_name}</h1>
              <div className="data">
                <span className="year">{showDetails?.year}</span>
                <span className={`duration ${showDetails?.year && "showDot"}  ${/^[A-Za-z0-9\s]+$/.test(showDetails?.duration_text) ? "ltr" : ""}`}>
                  {showDetails?.duration_text}
                </span>
                {showDetails?.rating && (
                  <span className="certificate">{showDetails?.rating}</span>
                )}
                {userRatedCount ? (
                  <div className="userRatings">
                    <div
                      className={
                        userRatedCount >= 1 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 2 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 3 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 4 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 5 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                  </div>
                ) : (
                  user && (
                    <div
                      className="addRating"
                      title={<Translate textKey={'rate_review'}/>}
                      onClick={() => setRatingReviewModal(true)}
                    >
                      <AddRatings />
                    </div>
                  )
                )}
              </div>
              <div className="genres">
                {showDetails?.categories?.map((item, index) => (
                  <span
                    key={index}
                    onClick={() =>
                      navigate(`/category/${item?.key}`, {
                        state: { careers: item?.key },
                      })
                    }
                  >
                    {item?.category_name}
                  </span>
                ))}
              </div>

              {!showDetails?.hide_play_button && (
                <div
                  className="watchNow"
                  onClick={() =>
                    watchVideoHandler(
                      selectedVideoDetails,
                      showId,
                      setSelectedVideoDetails,
                      showDetails,
                      categories,
                      thumbnailOrientation
                    )
                  }
                >
                  {/* <button>
                  {
                    subscribeButton && !isFreeVideo ? "Subscribe" :
                      !subscribeButton && isFreeVideo ? "Watch Now" :
                        subscribeButton && isFreeVideo ? "Watch now for free with Ads" :
                          "Watch Now"
                  }
                </button> */}

                  <button
                    style={{
                      color:
                        template === "TEMPLATE_9" ? buttonTextColor : textColor,
                    }}
                  >
                    <Translate textKey={'watch_now'}/>
                  </button>
                </div>
              )}
              {showDetails?.teaser_flag === 1 &&
                showDetails?.teaser &&
                template === "TEMPLATE_9" && (
                  <div className="trailer" onClick={() => setIsTrailer(true)}>
                    <button><Translate textKey={'trailer'}/></button>
                  </div>
                )}
              {watchlistStatus === "added" ? (
                <div
                  className="addtoList"
                  onClick={() =>
                    removeFromWatchlist(
                      setWatchlistStatus,
                      appInfo,
                      showId,
                      user
                    )
                  }
                >
                  <button><Translate textKey={'remove_from_mylist'}/></button>
                </div>
              ) : (
                <div
                  className="addtoList"
                  onClick={() =>
                    addToWatchlist(setWatchlistStatus, appInfo, showId, user)
                  }
                >
                  <button><Translate textKey={'add_to_mylist'}/></button>
                </div>
              )}

              <div className="buttons">
                <div className="buttonContainerOne">
                  <button
                    className="share"
                    onClick={() => {
                      setIsShareActive(!isShareActive);
                      setIsReport(false);
                    }}
                    ref={shareIconTwoRef}
                  >
                    <Translate textKey={'share'}/>
                  </button>
                  <div
                    className={
                      isShareActive ? "active shareContainer" : "shareContainer"
                    }
                    ref={shareTwoRef}
                  >
                    <div
                      className="facebookContainer"
                      onClick={() => setIsShareActive(false)}
                    >
                      <FacebookShareButton
                        url={pageURL}
                        quote={"Share"}
                        className="facebook"
                      >
                        <Translate textKey={'facebook'}/>
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>
                    </div>
                    <div
                      className="twitterContainer"
                      onClick={() => setIsShareActive(false)}
                    >
                      <TwitterShareButton url={pageURL} className="twitter">
                      <Translate textKey={'twitter'}/>
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <div className="buttonContainerTwo">
                  <button
                    className="more"
                    onClick={() => {
                      setIsReport(!isReport);
                      setIsShareActive(false);
                    }}
                    ref={reportTwoRef}
                  >
                    {isReport ? <Closeicon /> : "..."}
                  </button>
                  {isReport ? (
                    <div
                      className={
                        isReport
                          ? "reportProblemContainer active"
                          : "reportProblemContainer"
                      }
                      onClick={() => navigate("/contact-support")}
                    >
                      <span><Translate textKey={'report_problem'}/></span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p className="description">
                {showDetails?.synopsis || showDetails?.video_description}
              </p>
              <div className="info">
                {showDetails?.show_cast && (
                  <div className="starring">
                    <div className="left">
                      <h6><Translate textKey={'starring'}/></h6>
                    </div>
                    <div className="right">
                      <ul className="names">
                        <li>{showDetails?.show_cast}</li>
                      </ul>
                    </div>
                  </div>
                )}
                {showDetails?.director && (
                  <div className="director">
                    <div className="left">
                      <h6><Translate textKey={'directed_by'}/></h6>
                    </div>
                    <div className="right">
                      <h6>{showDetails?.director}</h6>
                    </div>
                  </div>
                )}
              </div>
              {!subscribedUser || selectedVideoDetails?.free_video === true ? (
                showDetails?.subscriptions?.length > 0 ||
                selectedVideoDetails?.free_video === true ? (
                  <div className="subscriptions">
                    {showDetails?.subscriptions?.map((item, index) => (
                      <div
                        className="package-item"
                        key={index}
                        onClick={() =>
                          handleSubscribe(
                            item,
                            user,
                            projectInfo,
                            appInfo,
                            showId
                          )
                        }
                      >
                        {item?.subscription_text}
                      </div>
                    ))}
                    {showDetails?.single_video === 1 &&
                      selectedVideoDetails?.free_video === true && (
                        <div
                          className="items free"                          
                          onClick={() =>
                            watchVideoHandler(
                              selectedVideoDetails,
                              showId,
                              setSelectedVideoDetails,
                              showDetails,
                              categories,
                              thumbnailOrientation
                            )
                          }
                        >
                          <Translate textKey={'free'}/>
                        </div>
                      )}
                  </div>
                ) : null
              ) : null}
            </div>
            {/* {showDetails?.categories?.length > 0 && (
                <CategoriesSection showDetails={showDetails} scrollDownRef={scrollDownRef} />
            )} */}
            {showDetails?.single_video === 0 &&
              showDetails?.videos?.length > 1 &&
              showDetails?.videos[0]?.videos && (
                <ShowsRow
                  season={true}
                  data={showDetails?.videos}
                  metaData={false}
                  watchVideoHandler={watchVideoHandler}
                  type="Episode"
                  vanityUrl={showDetails?.vanity_url}
                  showname={showDetails?.show_name}
                  showDetailsData={showDetails}
                  setSelectedVideoDetails={setSelectedVideoDetails}
                  categories={showDetails?.categories}
                />
              )}
            {showDetails?.single_video !== 1 &&
              showDetails?.videos?.length === 1 &&
              showDetails?.videos[0]?.season && (
                <ShowsRow
                  season={false}
                  data={showDetails?.videos[0]?.videos}
                  title={<Translate textKey={'episodes'}/>}
                  metaData={false}
                  watchVideoHandler={watchVideoHandler}
                  type="Episode"
                  vanityUrl={showDetails?.vanity_url}
                  showname={showDetails?.show_name}
                  showDetailsData={showDetails}
                  setSelectedVideoDetails={setSelectedVideoDetails}
                  categories={showDetails?.categories}
                />
              )}
            {showDetails?.single_video === 0 &&
              !showDetails?.videos[0]?.videos && (
                <ShowsRow
                  season={false}
                  data={showDetails?.videos}
                  title={<Translate textKey={'episodes'}/>}
                  metaData={false}
                  watchVideoHandler={watchVideoHandler}
                  type="Episode"
                  vanityUrl={showDetails?.vanity_url}
                  showname={showDetails?.show_name}
                  showDetailsData={showDetails}
                  setSelectedVideoDetails={setSelectedVideoDetails}
                  categories={showDetails?.categories}
                />
              )}

            {showDetails?.key_art_work?.length > 0 && <AboutSection showDetails={showDetails} />}

            {showDetails?.awards?.length > 0 && <AwardsAndFestivals showDetails={showDetails} />}

            {showDetails?.cast?.length > 0 && <CastAndCrew type="Cast" data={showDetails?.cast} />}

            {showDetails?.crew?.length > 0 && <CastAndCrew type="Crew" data={showDetails?.crew} />}


            {similarVideos?.length > 0 && (
              <ShowsRow
                season={false}
                data={similarVideos}
                title={<Translate textKey={'you_may_also_like'}/>}
                metaData={true}
                setVideoPlayer={setVideoPlayer}
                similarVideos={true}
              
                vanityUrl={showDetails?.vanity_url}


              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsScreen;
