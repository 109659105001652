import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import Loading from '../../Components/Loading/Loading';
import Categories from './Components/Categories';
import Featured from './Components/Featured';
import Live from './Components/Live';
import * as service from "./service";
import { checkOsVersion } from '../../utils/utils';
import useScrollPosition from '../../Components/ScrollPosition/useScrollPosition';
import TopTrendingEpisodes from '../../Components/TopTrending/TopTrendingEpisodes';
import { getRandomItem } from "../../utils/utils";
import RedeemBanner from './Components/HomeSlider/RedeemBanner';
import GaiaBanner from './Components/GaiaSlider/GaiaBanner';
import NewReleases from '../../Components/NewReleases/NewReleases';
import GaiaCategories from './Components/GaiaSlider/GaiaCategories';
import MoreTopics from './Components/GaiaSlider/MoreTopics';
import GaiaGenres from './Components/GaiaSlider/GaiaGenres';

const Homepage = () => {

  const projectInfo=useSelector((state)=>state?.projectInfo?.value);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;

  const accessToken=useSelector((state)=>state?.accessToken?.value);
  const user=useSelector((state)=>state?.user?.value);

  const [loading ,setLoading] = useState(true)
  const [homeData,setHomeData] = useState([])
  const [homeDataSliceCount, setHomeDataSliceCount] = useState(8);

  const homePageContainerRef = useRef()
  const scrollPosition = useScrollPosition();
  const appInfo={
    projectDetails:projectInfo,
    accessToken:accessToken
  }

  useEffect(()=>{
    if(!loading && homeDataSliceCount<homeData?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,homeData])

  useEffect(()=>{
    window.scroll(0,0)
   setLoading(true)
     fetchShowDetails();

  },[user])
  const fetchShowDetails = async()=>{
    try{
      const response = await  service.getShowsByCategory(appInfo);
      if(response?.status === 200){
        setHomeData(response?.data?.data)
        setLoading(false)
      }
    }
    catch{
      setLoading(true)
    }
  }

  const lazyLoad = () => {
    const containerHeight = homePageContainerRef?.current?.clientHeight;
    if(scrollPosition + 2500 >= containerHeight){
      setHomeDataSliceCount(homeDataSliceCount+5)
    }
  }

  // const categoryShowsData = homeData?.filter(item => item?.type === "CATEGORY_SHOWS")?.reverse();
  // const categoryNames = homeData?.filter(item => item?.banner);

if(loading){
  return(
    <Loading/>
  )
}

const categoryShowsData = homeData?.filter(item => item?.type === "CATEGORY_SHOWS")?.reverse();

  return (
    <div 
    className={template === "TEMPLATE_10" ? 'gaiaHomepage' : 'homePage'} 
    ref={homePageContainerRef}>
        {
          homeData?.map((item,index)=>{
            if(item?.type==="LIVE") {
              return <Live key={index} data={item}/>
            } else if(item?.type==="FEATURED") {
              {
                if (template === "TEMPLATE_9") {            
                  return <RedeemBanner key={index} data={item} />
                 } 
                 else if (template === "TEMPLATE_10"){
                  return <GaiaBanner key={index} data={item} />
                 }
                else {
                  return <Featured key={index} data={item} />
                } 
              }
	    }

             if(template === "TEMPLATE_10" && item?.type === "NEW_RELEASES"){
              return <NewReleases data={item} />
            } 
	     else if(item?.type === "TOP_TRENDING"){
              return <TopTrendingEpisodes data={item} type ={item?.type}/>
            }
            else if(item?.type === "GENRES"){
              return <GaiaGenres data={item}/>
            }
            else if(item?.type === "MORE_TOPICS"){
              return <MoreTopics data={item}/>
            }
            else if(item?.type !== "FEATURED" && item?.type !== "LIVE")
            {
		if(template === "TEMPLATE_10"){
			return <GaiaCategories key={index} data={item} />
		} else{
              return <Categories  key={index} data={item} />
            }
	}
})
        }
    </div>
  )
}

export default Homepage
