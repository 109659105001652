import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as AddToPlaylistIcon } from "../../assets/Icons/addtoplaylist.svg";
import { ReactComponent as RemoveFromPlaylistIcon } from "../../assets/Icons/removefromplaylist.svg";
import { ReactComponent as ShareIcon } from "../../assets/Icons/share.svg";
import { ReactComponent as ReportIcon } from "../../assets/Icons/warning.svg";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { ReactComponent as AddRatings } from "../../assets/Icons/starRounded.svg";
import { ReactComponent as StarIcon } from "../../assets/Icons/star.svg";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useWindowWidth } from "@react-hook/window-size";
import "react-toastify/dist/ReactToastify.css";
import RatingsAndReviewModal from "../../Components/Modals/RatingAndReviewModal";
import AboutSection from "./Components/AboutSection";
import AwardsAndFestivals from "./Components/AwardsAndFestivals";
import CastAndCrew from "./Components/CastAndCrew";
import CategoriesSection from "./Components/CategoriesSection";
import Util from "./util";
import {
  getPlayerToken,
  getUserSubscription,
  updateWatchlistData,
} from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import GaiaCard from "../../Components/ShowCard/GaiaCard";
import { toast } from "react-toastify";
import { imageUrlCheck, onVideoPlayFunction } from "../../utils/utils";
import Loading from "../../Components/Loading/Loading";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import "./scss/detailScreen.css";
import { stripeSession } from "../Subscription/service";
import TrailerModal from "../../Components/Modals/TrailerModal";
import CommonPage from "../../Components/CommonPage/CommonPage";
import GaiaShowsRow from "../../Components/ShowsRow/GaiaShowsRow";
import Translate from "../../Components/Multilanguage/Translate";

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;

const DetailsScreen = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const [isShareActive, setIsShareActive] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [watchDuration, setWatchDuration] = useState("");
  const [pageURL, setPageURL] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState("");
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [categories, setCategories] = useState("");
  const [trailerPlaying, setTrailerPlaying] = useState(true);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [adUrl, setAdUrl] = useState("");
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(false);
  const [subscribedUser, setSubscribeduser] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const [pipMode, setPipMode] = useState(false);
  const [nowPlaying, setNowPlaying] = useState("");
  const [episodes, setEpisodes] = useState();
  const [nextEpisodeModal, setNextEpisodeModal] = useState(false);
  const [isFreeVideo, setIsFreeVideo] = useState(false); // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [subscribeButton, setSubscribeButton] = useState();
  const [subtitleInfo, setSubTitleInfo] = useState();
  const [
    countinueWatchingVideoPlayedStatus,
    setCountinueWatchingVideoPlayedStatus,
  ] = useState(false);
  const [isTrailer, setIsTrailer] = useState(false);
  const [ratingReviewModal, setRatingReviewModal] = useState(false);
  const [userRatedCount, setUserRatedValue] = useState();
  const [visibleSection, setVisibleSection] = useState("info");

  const position = useScrollPosition();
  const location = useLocation();
  const [showId,setShowId] = useState(); 
  const type = location?.state?.type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [videoType, setVideoType] = useState("video");
  const windowSize = useWindowWidth();

  const descriptionRef = useRef(null);
  const metadataRef = useRef(null);

  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  let scrollDownRef = useRef(null);
  let episodeCount = 0;
  let isLive = false;
  const buttonTextColor = projectInfo?.projectConfig?.config?.BUTTON_TEXT_COLOR;
  const textColor = projectInfo?.projectConfig?.config?.TEXT_COLOR;

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };
  const playerRef = useRef(null);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;

  const {
    fetchShowDetails,
    fetchShowDetailsByVanityUrl,
    fetchSimilarVideos,
    fetchPlayerToken,
    addToWatchlist,
    removeFromWatchlist,
    subscriptionCheck,
    handleSubscribe,
    watchVideoHandler,
    // handlePlayerReady,
    updateVideoAnalytics,
    findIndex,
    fetchUserSubscriptionDetails,
  } = Util();

  useEffect(() => {
    setVisibleSection("info");
  }, [location?.pathname]);

  useEffect(() => {
    localStorage.removeItem("couponId");
  }, []);

  useEffect(() => {
    setPageURL(window.location.href);
    fetchUserSubscriptionDetails(appInfo, dispatch);
  }, []);

  useEffect(() => {
    if (
      location?.state?.thumbnailOrientation === "PORTRAIT" ||
      (!location?.state?.thumbnailOrientation &&
        projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION ===
          "PORTRAIT")
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    localStorage.removeItem("couponId");
  }, []);
  useEffect(() => {
    setAdUrl(null);
    if (showDetails) {
      if (Object.entries(showDetails).length > 0) {
        if (showDetails?.user_rated === true) {
          setUserRatedValue(showDetails?.user_rating);
        }
        else {
          setUserRatedValue(null);
        }
        if (showDetails?.type === "linear_event") {
          setVideoType("event");
        } else {
          setVideoType("video");
        }
        if (showDetails?.categories) {
          let categoryNames;
          showDetails?.categories?.map((item) => {
            categoryNames = categoryNames
              ? categoryNames + item?.category_name + ","
              : item?.category_name + ",";
          });
          setCategories(categoryNames);
        }
        if (showDetails?.watchlist_flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
        if (
          type === "CONTINUE_WATCHING" &&
          countinueWatchingVideoPlayedStatus === false
        ) {
          if (showDetails?.single_video === 0) {
            if (showDetails?.videos && showDetails?.videos[0]?.videos) {
              setEpisodes(showDetails?.videos[0]?.videos);

              let filteredVideo = showDetails?.videos.map((item) => {
                return item?.videos?.filter(
                  (videoItem) =>
                    videoItem?.video_id === location?.state?.videoId
                );
              });
              setSelectedVideoDetails(filteredVideo[0][0]);
            } else {
              setEpisodes(showDetails?.videos);

              let filteredVideo = showDetails?.videos?.filter(
                (item) => item?.video_id === location?.state?.videoId
              );
              setSelectedVideoDetails(filteredVideo[0]);
            }
          } else if (showDetails?.single_video === 1) {
            setEpisodes(null);
            if (showDetails?.videos?.length > 0) {
              setSelectedVideoDetails(showDetails?.videos[0]);
            }
          }
        } else {
          if (showDetails?.teaser_flag === 1) {
            let trailer = true;
            let arr = showDetails?.teaser?.split("/").reverse();
            fetchPlayerToken(
              trailer,
              arr[1],
              appInfo,
              setPlayerToken,
              setVideoUrl,
              showDetails,
              projectInfo
            );
            setIsTrailer(false);
            setNowPlaying("Trailer");
          }
          if (showDetails?.single_video === 1) {
            setSelectedVideoDetails(
              showDetails?.videos && showDetails?.videos[0]
            );
            setEpisodes(null);
          } else {
            if (
              showDetails?.videos &&
              showDetails?.videos[0]?.videos &&
              showDetails?.videos[0]?.videos[0]
            ) {
              setSelectedVideoDetails(showDetails?.videos[0]?.videos[0]);
              setEpisodes(showDetails?.videos[0]?.videos);
            } else {
              setSelectedVideoDetails(
                showDetails?.videos && showDetails?.videos[0]
              );
              setEpisodes(showDetails?.videos);
            }
          }
        }
      }
    }
  }, [showDetails, type]);

  useEffect(() => {
    if (isTrailer && position > 0) {
      setPipMode(true);
      videoPlaying = true;
    } 
     else if (position === 0 && pipMode) {
      videoPlaying = true;
      setPipMode(false);
      setIsTrailer(true);
    }
  }, [isTrailer, position, pipMode]);

  console.log("isTrailer, pipMode",isTrailer,pipMode)
  console.log("position , browserPipMode , videoPlaying , pipMode",position , browserPipMode , videoPlaying , pipMode);

  useEffect(() => {
    window.scroll(0, 0);

    if (location?.pathname) {
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        fetchShowDetailsByVanityUrl(
          vanityUrl,
          appInfo,
          setLoading,
          setShowDetails,
          setShowId,
          setSimilarVideos,
          showId
        );
      } else {
        fetchShowDetails(setLoading, setShowDetails, appInfo, showId);
      }

      if (showId) {
        fetchSimilarVideos(null, appInfo, setSimilarVideos, showId);
      }
    }
    // }
  }, [accessToken, projectInfo, location?.pathname]);

  useEffect(() => {
    if (selectedVideoDetails) {
      if (Object.entries(selectedVideoDetails).length > 0) {
        if (
          projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true"
        ) {
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
          if (
            projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true"
          ) {
            if (
              selectedVideoDetails?.subscriptions?.length > 0 ||
              showDetails?.subscriptions?.length > 0
            ) {
              let isSubscribedUser;
              if (showDetails?.subscriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(
                        userSubscriptionData?.data,
                        showDetails?.subscriptions
                      )
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(
                        userSubscriptionData?.data,
                        selectedVideoDetails?.subscriptions
                      )
                    : false;
              }

              if (isSubscribedUser === true) {
                setSubscribeduser(true);
                setSubscribeButton(false);
              } else {
                setSubscribeduser(false);

                if (selectedVideoDetails?.free_video === true) {
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              setSubscribeduser(true);
              setSubscribeButton(false);
            }
          } else {
            setSubscribeduser(true);
            setSubscribeButton(false);
          }
        } else if (
          projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "false"
        ) {
          setSubscribeButton(false);
          if (userSubscriptionData?.login_needed === false) {
            setIsFreeVideo(true);
          } else {
            setIsFreeVideo(false);
          }
          setInitialVideoSubscriptions(selectedVideoDetails?.subscriptions);
        }

        if (showDetails) {
          var updateDuration = selectedVideoDetails?.watched_duration;
          setWatchDuration(updateDuration);
        }
      }
    }
  }, [selectedVideoDetails]);

  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: videoPlay,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
      };
      setLoading(false);

      setSkeleton(false);
      setVideoPlayer(
        <VideoJS
          autoplay={true}
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
          isTrailer={true}
        />
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [videoUrl]);

  useEffect(() => {
    setPageURL(window.location.href);
  }, []);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !shareIconRef?.current?.contains(e.target) &&
        !shareRef?.current?.contains(e.target) &&
        !shareIconTwoRef?.current?.contains(e.target) &&
        !shareTwoRef?.current?.contains(e.target)
      ) {
        setIsShareActive(false);
      }
      if (
        !reportRef?.current?.contains(e.target) &&
        !reportTwoRef?.current?.contains(e.target)
      ) {
        setIsReport(false);
      }
    };
    window.addEventListener("click", handleOutClick);
  }, [shareRef, reportRef, shareTwoRef, reportTwoRef]);

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    if (!trailerPlaying) {
      player.on("play", () => {
        let event = videoStarted === true ? "POP09" : "POP02";
        videoStarted = true;
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
      });
      player.on("loadedmetadata", () => {
        player.pause();
        if (watchDuration > 0) {
          player.currentTime(watchDuration.toString());
          player.play();
          videoPlaying = true;
        } else {
          player.play();
          videoPlaying = true;
        }
      });
      player.on("timeupdate", function (e) {
        let event = "POP03";
        videoPlaying = true;
        var hasPlayedTime = player.currentTime();
        var intPlayedTime = parseInt(hasPlayedTime, 10);
        var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
        if (isSixty && debounce) {
          debounce = false;
          prevTime = intPlayedTime;
          updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
        } else {
          if (debounce == false && prevTime != intPlayedTime) {
            debounce = true;
          }
        }
      });

      player.on("pause", () => {
        let event = "POP04";
        videoPlaying = true;
        updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
      });
      player.on("ended", () => {
        let event = "POP05";
        videoPlaying = false;
        episodeCount = findIndex( episodes, selectedVideoDetails ) + 1;

        updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
        if (episodes[episodeCount]) {
          setNextEpisodeModal(true);
        }
      });
      player.on("dispose", () => {
        videoPlaying = false;
        videoStarted = false;
        player.pause();
      });
    } else {
      player.on("timeupdate", () => {
        videoPlaying = true;
      });
      player.on("dispose", () => {
        videoPlaying = false;
      });
    }
  };

  const handleSeeMore = () => {
    if (metadataRef.current) {
      metadataRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const truncateDescription = (description, maxLength = 250) => {
    if (!description) return "";
    if (description.length <= maxLength) {
      return description;
    }
    return description.slice(0, maxLength) + "...";
  };

  const handlePipClose = () => {
    // videoPlaying = false;
    setIsTrailer(false);
    setPipMode(false);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={`gaiaDetailsScreen ${isRTL ? "rtl" : ""}`}>
      {ratingReviewModal && user !== "null" && (
        <RatingsAndReviewModal
          showName={showDetails?.show_name}
          director={showDetails?.director}
          year={showDetails?.year}
          showId={showDetails?.show_id}
          setRatingReviewModal={setRatingReviewModal}
          setUserRatedValue={setUserRatedValue}
        />
      )}
      <div className="details">
        <div className="topContainer">
          <div className="imageContainer">
            {imageUrlCheck(
              thumbnailOrientation === "PORTRAIT"
                ? showDetails?.logo
                  ? showDetails?.logo
                  : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
                : showDetails?.logo_thumb
                ? showDetails?.logo_thumb
                : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
            ) ? (
              <img
                src={
                  thumbnailOrientation === "PORTRAIT"
                    ? `${
                        showDetails?.logo ||
                        projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
                      }`
                    : `${
                        showDetails?.logo_thumb ||
                        projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
                      }`
                }
                alt="Background"
              />
            ) : (
              <img
                src={
                  thumbnailOrientation === "PORTRAIT"
                    ? `${process.env.REACT_APP_IMAGE_URL}${
                        showDetails?.logo ||
                        projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
                      }`
                    : `${process.env.REACT_APP_IMAGE_URL}${
                        showDetails?.logo_thumb ||
                        projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
                      }`
                }
                alt="Background"
              />
            )}
            {showDetails?.teaser_flag === 1 && showDetails?.teaser && (
              <>
              <div
                className="trailerPlaceholder"
                onClick={() => setIsTrailer(true)}
              >
                <div className={`imageWrapper ${isTrailer ? 'hidden' : ''}`}>
                  <img src={showDetails?.logo_thumb} alt="Trailer Thumbnail" />
                  {windowSize > 480 ? (
                    <h2>
                      <Translate textKey={"watch_trailer"} />
                    </h2>
                  ) : (
                    <h2>
                      <PlayIcon /> <Translate textKey={"trailer"} />
                    </h2>
                  )}
                </div>
              </div>

              {((isTrailer) || (position > 0 && !browserPipMode && videoPlaying && pipMode)) && (
                <div
                  className={
                    isTrailer && !pipMode
                      ? "trailerOverlay"
                      : position > 0 && !browserPipMode && videoPlaying && pipMode
                      ? "player pipMode"
                      : ""
                  }
                >
                  <div
                    className={
                      position > 0 && !browserPipMode && videoPlaying && pipMode
                        ? "gradientTop"
                        : ""
                    }
                  />
                  <div
                    className={
                      isTrailer && !pipMode
                        ? "closeIcon1"
                        : position > 0 && !browserPipMode && videoPlaying && pipMode
                        ? "closeIcon2"
                        : ""
                    }
                    onClick={
                      isTrailer && !pipMode
                        ? () => setIsTrailer(false)
                        : position > 0 && !browserPipMode && videoPlaying && pipMode
                        ? handlePipClose
                        : undefined
                    }
                  >
                    <Closeicon />
                  </div>
                  <div className={isTrailer ? "trailer" : ""}>{videoPlayer && videoPlayer}</div>
                </div>
              )}

              </>
            )}

            {showDetails?.single_video === 1 &&
              selectedVideoDetails?.free_video === true && (
                <button
                  className="items"
                  onClick={() =>
                    watchVideoHandler(
                      selectedVideoDetails,
                      showId,
                      setSelectedVideoDetails,
                      showDetails,
                      categories,
                      thumbnailOrientation
                    )
                  }
                >
                  <Translate textKey={"free_caps"} /> !
                </button>
              )}

            <div className="showMetadata">
              {showDetails?.categories?.[0]?.category_name ? (
                <div className="categoryName">
                  {showDetails.categories[0].category_name}
                </div>
              ) : null}
              <h1 className="name">{showDetails?.show_name}</h1>
              <p ref={descriptionRef} className="description">
                {truncateDescription(
                  showDetails?.synopsis || showDetails?.video_description
                )}
                {(showDetails?.synopsis || showDetails?.video_description) &&
                  (showDetails?.synopsis?.length > 250 ||
                    showDetails?.video_description?.length > 250) && (
                    <span
                      className="see-more"
                      onClick={() => {
                        if (visibleSection === "related") {
                          setVisibleSection("info");
                        }
                        handleSeeMore();
                      }}
                    >
                      <Translate textKey={"see_more"} />
                    </span>
                  )}
              </p>

              <div className="spanning">
                {showDetails?.year && (
                  <span className="year">{showDetails?.year}</span>
                )}
                <span className={`duration ${/^[A-Za-z0-9\s]+$/.test(showDetails?.duration_text) ? "ltr" : ""}`}>{showDetails?.duration_text}</span>
                {showDetails?.rating && (
                  <span className="certificate">{showDetails?.rating}</span>
                )}
                {showDetails?.director && (
                  <span className="director">
                    {" "}
                    <Translate textKey={"directed_by"} />:{" "}
                    {showDetails?.director}
                  </span>
                )}
              </div>
              <div className="genres">
                {showDetails?.categories?.map((item, index) => (
                  <span
                    key={index}
                    onClick={() =>
                      navigate(`/category/${item?.key}`, {
                        state: { careers: item?.key },
                      })
                    }
                  >
                    {item?.category_name}
                  </span>
                ))}
              </div>
              {!showDetails?.hide_play_button && (
                <div
                  className="watchNow"
                  onClick={() =>
                    watchVideoHandler(
                      selectedVideoDetails,
                      showId,
                      setSelectedVideoDetails,
                      showDetails,
                      categories,
                      thumbnailOrientation
                    )
                  }
                >
                  <button style={{ color: textColor }}>
                    <Translate textKey={"watch_now"} />
                  </button>
                </div>
              )}
              {!subscribedUser || selectedVideoDetails?.free_video === true ? (
                showDetails?.subscriptions?.length > 0 ||
                selectedVideoDetails?.free_video === true ? (
                  <div className="subscriptions">
                    {showDetails?.subscriptions?.map((item, index) => (
                      <div
                        className="package-item"
                        key={index}
                        onClick={() =>
                          handleSubscribe(
                            item,
                            user,
                            projectInfo,
                            appInfo,
                            showId
                          )
                        }
                      >
                        {item?.subscription_text}
                      </div>
                    ))}
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
        <div className="bottomContainer">
          <div className="sectionToggle" ref={metadataRef}>
            <h1
              className={visibleSection === "info" ? "selected" : ""}
              onClick={() => setVisibleSection("info")}
            >
              <Translate textKey={"info"} />
            </h1>
            <h1
              className={visibleSection === "related" ? "selected" : ""}
              onClick={() => setVisibleSection("related")}
            >
              <Translate textKey={"related"} />
            </h1>
          </div>
          {visibleSection === "info" && (
            <div className="left">
              <div className="buttons">
                {watchlistStatus === "added" ? (
                  <button
                    className="addtoList"
                    onClick={() =>
                      removeFromWatchlist(
                        setWatchlistStatus,
                        appInfo,
                        showId,
                        user
                      )
                    }
                  >
                    <RemoveFromPlaylistIcon />
                    <Translate textKey={"remove_from_mylist"} />
                  </button>
                ) : (
                  <button
                    className="addtoList"
                    onClick={() =>
                      addToWatchlist(setWatchlistStatus, appInfo, showId, user)
                    }
                  >
                    <AddToPlaylistIcon />
                    <Translate textKey={"add_to_mylist"} />
                  </button>
                )}
                <div className="shareWrapper">
                  <button
                    className="share"
                    onClick={() => {
                      setIsShareActive(!isShareActive);
                      setIsReport(false);
                    }}
                    ref={shareIconRef}
                  >
                    <ShareIcon />
                    <Translate textKey={"share"} />
                  </button>
                  <div
                    className={
                      isShareActive ? "active shareContainer" : "shareContainer"
                    }
                    ref={shareRef}
                  >
                    <div
                      className="facebookContainer"
                      onClick={() => setIsShareActive(false)}
                    >
                      <FacebookShareButton
                        url={pageURL}
                        quote={"Share"}
                        className="facebook"
                      >
                        <Translate textKey={"facebook"} />
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>
                    </div>
                    <div
                      className="twitterContainer"
                      onClick={() => setIsShareActive(false)}
                    >
                      <TwitterShareButton url={pageURL} className="twitter">
                        <Translate textKey={"twitter"} />
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <button
                  className={
                    isReport
                      ? "reportProblemContainer active"
                      : "reportProblemContainer"
                  }
                  onClick={() => navigate("/contact-support")}
                >
                  <ReportIcon />
                  <Translate textKey={"report_problem"} />
                </button>
              </div>

              <div className="metadata">
                <p className="description">
                  {showDetails?.synopsis || showDetails?.video_description}
                </p>
                <div className="info">
                  {showDetails?.show_cast && (
                    <div className="starring">
                      <span className="left">
                        <h6>
                          <Translate textKey={"starring"} />
                        </h6>
                      </span>
                      <span className="right">
                        <ul className="names">
                          <li>{showDetails?.show_cast}</li>
                        </ul>
                      </span>
                    </div>
                  )}
                </div>
                {showDetails?.director && (
                  <div className="director">
                    <span className="left">
                      <h6>
                        <Translate textKey={"directed_by"} />
                      </h6>
                    </span>
                    <span className="right">
                      <h6>{showDetails?.director}</h6>
                    </span>
                  </div>
                )}
                {userRatedCount ? (
                  <div className="userRatings">
                    <div
                      className={
                        userRatedCount >= 1 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 2 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 3 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 4 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                    <div
                      className={
                        userRatedCount >= 5 ? "active star" : "no-active star"
                      }
                    >
                      <StarIcon />
                    </div>
                  </div>
                ) : (
                  user && (
                    <div
                      className="addRating"
                      title="Rate and Review"
                      onClick={() => setRatingReviewModal(true)}
                    >
                      <AddRatings />
                    </div>
                  )
                )}
                {showDetails?.key_art_work?.length > 0 && <AboutSection showDetails={showDetails} />}
                {showDetails?.awards?.length > 0 && <AwardsAndFestivals showDetails={showDetails} />}
                {showDetails?.cast?.length > 0 && <CastAndCrew type="Cast" data={showDetails?.cast} />}
                {showDetails?.crew?.length > 0 && <CastAndCrew type="Crew" data={showDetails?.crew} />}
                {/* {showDetails?.rating && (
                  <span className="certificate">{showDetails?.rating}</span>
                )} */}
              </div>
            </div>
          )}
          {visibleSection === "related" && (
            <div className="right">
            {/* {showDetails?.categories?.length > 0 && (
                    <CategoriesSection showDetails={showDetails} scrollDownRef={scrollDownRef} />
            )} */}
              {showDetails?.single_video === 0 &&
                showDetails?.videos?.length > 1 &&
                showDetails?.videos[0]?.videos && (
                  <GaiaShowsRow
                    season={true}
                    data={showDetails?.videos}
                    metaData={false}
                    watchVideoHandler={watchVideoHandler}
                    type="Episode"
                    // type="episodes"
                    vanityUrl={showDetails?.vanity_url}
                    showname={showDetails?.show_name}
                    showDetailsData={showDetails}
                  />
                )}
              {showDetails?.single_video !== 1 &&
                showDetails?.videos?.length === 1 &&
                showDetails?.videos[0]?.season && (
                  <GaiaShowsRow
                    season={false}
                    data={showDetails?.videos[0]?.videos}
                    title={<Translate textKey={"episodes"} />}
                    metaData={false}
                    watchVideoHandler={watchVideoHandler}
                    type="Episode"
                    // type="episodes"
                    vanityUrl={showDetails?.vanity_url}
                    showname={showDetails?.show_name}
                    showDetailsData={showDetails}
                  />
                )}
              {showDetails?.single_video === 0 &&
                !showDetails?.videos[0]?.videos && (
                  <GaiaShowsRow
                    season={false}
                    data={showDetails?.videos}
                    title={<Translate textKey={"episodes"} />}
                    metaData={false}
                    watchVideoHandler={watchVideoHandler}
                    type="Episode"
                    // type="episodes"
                    vanityUrl={showDetails?.vanity_url}
                    showname={showDetails?.show_name}
                    showDetailsData={showDetails}
                  />
                )}
              {similarVideos?.length > 0 && (
                <GaiaShowsRow
                  season={false}
                  data={similarVideos}
                  title={<Translate textKey={"you_may_also_like"} />}
                  metaData={true}
                  setVideoPlayer={setVideoPlayer}
                  similarVideos={true}
                  vanityUrl={showDetails?.vanity_url}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsScreen;
