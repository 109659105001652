import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { appInfo, fetchEventDetails } from "../../network/service";
import { updateWatchlistData } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { ReactComponent as PremiumIcon } from "../../assets/Icons/premium.svg";
import { imageUrlCheck } from "../../utils/utils";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import { getSelectedChannel } from "../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import Translate from "../Multilanguage/Translate";
import Util from "./util";

const ShowCard = ({ data, metaData, imageUrl ,type,thumbnailOrientation, playlist = true }) => {
  const {
    showHandleClick,
    replaceImage,
    addToWatchlist,
    removeFromWatchlist,
    subscriptionCheck,
  } = Util();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [imagURL, setImageURL] = useState(thumbnailOrientation === "PORTRAIT" ? require("../../assets/Images/loading-portrait.gif") : require("../../assets/Images/loading-landscape.gif"))
  const [isImageLoad, setIsImageLoad] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);

  useEffect(() => {
    setIsImageLoad(true)
    let image;
    if (
      imageUrlCheck(
        thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200
      )
    ) {
      image =
        thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200;
    } else {
      image =
      process.env.REACT_APP_IMAGE_URL +
        (thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200);
    }

    setImageURL(image);
  }, [data, thumbnailOrientation]);

  return (
    <div className={"showCardContainer showContents"} title={data?.show_name || data?.video_title}>
      {/* <ToastContainer /> */}     
      <div className="premiumStatus">
        { data?.is_free_video === true ? (
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
        )
         : data?.is_locked === true ?(
          <div className="lock">
            <img
            src={require("../../assets/Images/lock.png")}
            alt="LockIcon"
            />
          </div>
        )
        :null
        }
      </div>

      <div className="imageContainer">
        {/* {imageUrlCheck(thumbnailOrientation==='PORTRAIT'?(data?.logo || data?.thumbnail):(data?.logo_thumb || data?.thumbnail_350_200)) ===false ? (
          <img
            src={thumbnailOrientation==='PORTRAIT'?`${process.env.REACT_APP_IMAGE_URL}${
              (data?.logo || data?.thumbnail)
            }`:`${process.env.REACT_APP_IMAGE_URL}${
              (data?.logo_thumb || data?.thumbnail_350_200)
            }`}
            alt="ShowImage"
          />
        ) : (
          <img src={thumbnailOrientation==='PORTRAIT'?
          `${data?.logo || data?.thumbnail}`
          :
          `${data?.logo_thumb || data?.thumbnail_350_200}`
        } alt="ShowImage" />
          
        )} */}
          <img
            className={!isImageLoad?(thumbnailOrientation==="PORTRAIT"?"portrait":"landscape"):"showImage"}
              src={
                imagURL
              }
              key={data?.show_id}
              // onError={(e)=>replaceImage(e)}
              // onLoad={(e)=>afterImageLoad(e)} 
              onError={(e) => replaceImage(e, thumbnailOrientation, projectInfo)}
              // onLoad={(e) =>
              //   afterImageLoad(
              //     e,
              //     thumbnailOrientation,
              //     data,
              //     setImageURL,
              //     setIsImageLoad,
              //     imageUrlCheck
              //   )
              // }
              alt="ShowImage"
            />
        {
          data?.type === "CONTINUE_WATCHING" &&
          <div className="continueWatchingBar">
            <div className="line" style={{ width: data?.watched_percentage <= 1 ? 1 + "%" : data?.watched_percentage + "%" }}></div>
          </div>
        }

        <div className="extraActions">
          <div
            className="showCardOverlay"
          //  onClick={showHandleClick}
          onClick={() =>
            showHandleClick(
              data,
              type,
              projectInfo,
              thumbnailOrientation,
              getPodcastModal,
              getSelectedChannel,
              getSelectedSchedule
            )
          }
          ></div>
          <div
            className="playIconContainer"
            // onClick={showHandleClick}
            onClick={() =>
              showHandleClick(
                data,
                type,
                projectInfo,
                thumbnailOrientation,
                getPodcastModal,
                getSelectedChannel,
                getSelectedSchedule
              )
            }
          >
            <PlayIcon />
          </div>
          {metaData && data?.show_id && playlist && (
            <>
              {watchlistStatus === "added" ? (
                <div
                  className="addToList"
                  // onClick={() => removeFromWatchlist(data?.show_id)}
                  onClick={() =>
                    removeFromWatchlist(
                      data?.show_id,
                      appInfo,
                      setWatchlistStatus
                    )
                  }
                >
                  <span><Translate textKey={'remove_from_mylist'}/></span>
                </div>
              ) : (
                <div
                  className="addToList"
                  // onClick={() => addToWatchlist(data?.show_id)}
                  onClick={() =>
                    addToWatchlist(
                      user,
                      data?.show_id,
                      appInfo,
                      setWatchlistStatus
                    )
                  }
                >
                  <span><Translate textKey={'add_to_mylist'}/></span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="metaData">
        {data?.type != 'FASTCHANNEL' && <h1 className="title">{data?.show_name || data?.video_title}</h1>}
        {metaData ? (
          <>
            <div className="datas">
              <div className="left">
                {
                  data?.year&&
                <span className="year">{data?.year}</span>
                }
                {
                  data?.duration_text&&
                <span className={`duration ${data?.year && 'showDot'} ${/^[A-Za-z0-9\s]+$/.test(data?.duration_text) ? "ltr" : ""}`}>{data?.duration_text}</span>
                }
              </div>
              <div className="right">
                {
                  data?.rating&&
                <span className="certificate">{data?.rating}</span>
                }
              </div>
            </div>
            <div className="genres">
              {/* {
            data?.category_names?.map((item,index)=>(
              <span key={index}>{item}</span>
            ))
          } */}
            </div>
          </>
        ) : (
        null
        )}
      </div>
    </div>
  );
};

export default ShowCard;
