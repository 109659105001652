import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { getTVCode } from "./service";
import { useSelector } from "react-redux";
import Translate from "../../Components/Multilanguage/Translate";
const LinkTvApp = () => {
  const projectInfo=useSelector((state)=>state?.projectInfo?.value);
  const accessToken=useSelector((state)=>state?.accessToken?.value);
  const [code,setCode] = useState()
  const [loading,setLoading] = useState(true)

  const countDownRef = useRef();
  const appInfo={
    projectDetails:projectInfo,
    accessToken:accessToken
  }

  useEffect(()=>{
    window.scroll(0,0)
    setLoading(true)
    getCode()
  },[])

  const regenerateHandler = () =>{
    setLoading(true)
    getCode()
  }

  const Completionist = () => (
    <div className="authCodeContainer">
    <h1 className="heading"><Translate textKey={'link_tv_app'}/></h1>
    <p><Translate textKey={'enter_tvcode'}/></p>
    <div className="authCodeField expired">{code}</div>
    <div className="codeExpired">
      <span><Translate textKey={'code_expired'}/></span>
      <div className="regenerateButton">
        <button onClick={regenerateHandler}><Translate textKey={'regenerate_code_caps'}/></button>
      </div>
    </div>
   
  </div>
  );

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="authCodeContainer">
        <h1 className="heading"><Translate textKey={'link_tv_app'}/></h1>
        <p><Translate textKey={'enter_tvcode'}/></p>
        {
            loading ? (<span className="loading"><Translate textKey={'generating_code_message'}/></span>)
            :
            (
                <>
                <div className="authCodeField">{code}</div>
                <span className="timer">
                <Translate textKey={'code_expiring_message'}/>{minutes}:{seconds}
                </span>
                </>

            )
        }
      </div>
       
      );
    }
  };

  const getCode = async () => {
    const response = await getTVCode(appInfo)
    if(response.status === 200){
        setLoading(false)
        setCode(response?.data?.code)
        countDownRef.current.getApi().start();
    }else{
        setLoading(false)
    }
  }
  return (
    <div className="linkTvApp">
     
      <Countdown
            date={Date.now() +5 * 6 * 10000} 
            renderer={renderer}
            autoStart={false} 
            ref={(count) => (countDownRef.current = count)}

        />
    </div>
  );
};

export default LinkTvApp;
