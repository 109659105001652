import React from 'react'
import { useNavigate ,useLocation} from 'react-router-dom'
import { convertAdUrl } from '../../utils/utils'
import {useSelector } from "react-redux";
import Translate from '../Multilanguage/Translate';

const WatchWithoutAdsModal = (props) => {

    const navigate= useNavigate()
    const user = useSelector((state) => state?.user?.value);
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const location = useLocation();
    const showId = location?.state?.showDetails?.showId;
    const watchWithoutAdsHandler = async (value) => {

      if(value === "yes")
        {
          if(!user)
            { navigate("/login", { state: { path: location?.pathname, showId: showId } }); }
          else{
              navigate("/subscription", { state: { videoId: props?.videoDetails?.video_id } }) 
            }
        }
        else if(value === "no")
        {
          if(projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true")
            {
              if(!user)
                {
                  navigate("/login", { state: { path: location?.pathname, showId: showId } });
                }
              else if(projectInfo?.projectConfig?.config?.AD_REQUIRED === "true")
              {
                    props?.setAdUrl(convertAdUrl(props?.videoDetails, props?.showDetails, props?.appInfo))
                    props?.playVideo()
                    props?.setWatchWithoutAds(false)
                  }
              else{
                    props?.playVideo()
                    props?.setWatchWithoutAds(false)
                  }
            }
            else{
              if(projectInfo?.projectConfig?.config?.AD_REQUIRED === "true")
                {
                      props?.setAdUrl(convertAdUrl(props?.videoDetails, props?.showDetails, props?.appInfo))
                      props?.playVideo()
                      props?.setWatchWithoutAds(false)
                    }
                else{
                      props?.playVideo()
                      props?.setWatchWithoutAds(false)
                    }
            }
        }
    }
  return (
    <div className="watchWithoutAds">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1><Translate textKey={'watch_without_ad'}/></h1>
        <div className="buttons">
            <div className="buttonContainer" onClick={()=>watchWithoutAdsHandler("yes")}>
            <div className="background"></div>
            <button ><Translate textKey={'yes'}/></button>
            </div>

            <div className="buttonContainer" onClick={()=>watchWithoutAdsHandler("no")}>
            <div className="background"></div>
            <button ><Translate textKey={'no'}/></button>
            </div>

        </div>
      </div>
    </div>
  )
}

export default WatchWithoutAdsModal
