import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRTL: false,
};

const RTLSlice = createSlice({
  name: 'rtl',
  initialState,
  reducers: {
    setRTL: (state, action) => {
      state.isRTL = action.payload?.is_rtl;
    },
  },
});

export const { setRTL } = RTLSlice.actions;
export default RTLSlice.reducer;