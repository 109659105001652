import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginWithTVCode } from "./service";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUserSubscription } from "../../network/service";
import { setSubscriptionPageSkip, setTvCode } from "../../Redux/TVcode/tvCodeSlice"
import Loading from "../../Components/Loading/Loading";
import Translate from "../../Components/Multilanguage/Translate";

const TvActivationReverse = () => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const tvCode = useSelector((state) => state?.tv?.tvCode);
  const viewSubscribePage = useSelector((state) => state?.tv?.subscriptionPageSkip);
  const user = useSelector((state) => state?.user?.value);
  const [inputValue, setInputValue] = useState("");
  const [loading,setLoading] = useState(true)
  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate()  
  const params = new URLSearchParams(location?.search);
  let code = params?.get("code");  
  const navigateToSubscription = location?.state?.subscriptionPage === false ? location?.state?.subscriptionPage : true;
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    dispatch(setTvCode({ value:"" }));
    if (code) {
      setInputValue(code);
    }else{
      setInputValue("")
    }
  }, [code]);

  useEffect(() => {
    fetchUserSubscription()
  },[window.location.href])

  const fetchUserSubscription = async() => {
    const userSubResponse = await getUserSubscription(appInfo);
    if(projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED_FOR_TV_LOGIN === true && userSubResponse?.data?.data?.length === 0  && navigateToSubscription === true){
      dispatch(setSubscriptionPageSkip({ value:true}))  
      if(code){
          dispatch(setTvCode({ value:code }));
        }
      navigate("/subscription", {
        state: { showButton: true, tvSubscriptionList: true },
      });
    }else{
      setLoading(false)
    }
  }
  const registerHandler = (e) => {
    e.preventDefault()
      if(inputValue){
        updateTVActivationCode()
    }else{
        toast.error(<Translate textKey={'fill_field_message'}/>, {
            position: "bottom-center",
          });
    }
    };

  const updateTVActivationCode = async () => {
    try {
      const response = await loginWithTVCode(appInfo,inputValue);
      if(response?.status === 200){
        toast.success(response?.data?.message, {
            position: "bottom-center",
          });
          setInputValue("")
          setTimeout(() => {
              navigate("/home")
          }, 1000);
      }
    } catch (err) {}
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="tvActivationReverse">
      <div className="wrapper">
        <div className="topHeader">
          <h1><Translate textKey={'register_device'}/></h1>
        </div>
        <div className="bottomContainer">
          <h2><Translate textKey={'register_tv_device'}/></h2>
          <p><Translate textKey={'enter_code_to_register'}/> {projectInfo?.projectConfig?.config?.TAB_TITLE} <Translate textKey={'account_small'}/></p>

          <div className="inputContainer">
            <form>
              <label htmlFor="registrationCode"><Translate textKey={'registration_code'}/>:</label>
              <input
                type="text"
                id="registrationCode"
                value={inputValue || ""}
                placeholder="e.g. HQ2W4Z"
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button type="submit" onClick={(e)=>registerHandler(e)}><Translate textKey={'register_device_button'}/></button>
            </form>
            <p className="terms">
            <Translate textKey={'continue_agree_message'}/> <span><Link to="/terms-and-conditions">{projectInfo?.projectConfig?.config?.TAB_TITLE} <Translate textKey={'terms_and_conditions'}/></Link></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TvActivationReverse;
