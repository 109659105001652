import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Loading from '../../Components/Loading/Loading';
import { appInfo, getPlayerToken } from '../../network/service';
import { getNewsDetails } from './service';
import VideoJS from '../../Components/VideoJsPlayer/VideoJS';
const NewsDetails = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const [newsData, setNewsData] = useState([]);
  const [newDate,setNewDate] = useState("")
  const [loading, setLoading] = useState(true);
  const [videoPlayer,setVideoPlayer] = useState()
  const [videoUrl,setVideoUrl] = useState("")
  const playerRef = useRef(null)
  const location =useLocation()
  const {newsId} = useParams()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken,
  };

  useEffect(()=>{

  },[])
  useEffect(()=>{
    if(newsData){
        let dates=new Date(newsData?.schedule_date)
        let month=dates.toLocaleString('en-us', { month: 'short' })
        let year = dates.getFullYear()
        let day = dates.getDate().toString().length == 1 ? '0' + dates.getDate() : dates.getDate()
        let isoFormat = day + " " + month+ " " + year
        setNewDate(isoFormat)
    }
  },[newsData])

  useEffect(()=>{
   fetchNewsDetails();
  },[])

  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };

      setVideoPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
        />
      );
    }
  }, [videoUrl]);

  useEffect(()=>{
    if(newsData){
        if(Object.entries(newsData)?.length>0){
            if(newsData?.video_name){
                playVideo()
            }
        }

    }
  },[newsData])

  const handlePlayerReady = (player) =>{
    playerRef.current = player;

  }
    const fetchNewsDetails = async () => {
        try{    
        const newsDetailsResponse = await getNewsDetails(appInfo,newsId)
        
            if(newsDetailsResponse?.status===200){
                setNewsData(newsDetailsResponse?.data?.data[0])
                setLoading(false)
            }
        }catch(err){    

        }
    // })
    }

    if(loading){
        return(
            <Loading/>
        )
    }
    const playVideo = async () => {
       
        const arr = newsData?.video_name?.split("/").reverse();
        const tokenData= await fetchPlayerToken(arr[1]);
        if(tokenData){
            setVideoUrl(
                "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
                  arr[1] +
                  "&token=" +
                  tokenData +
                  "&type=video" +
                  "&pubid=" +
                  projectInfo?.projectConfig?.pubid
              );
        }

    }
    const fetchPlayerToken = async (videoId) => {
        try{
            const playerTokenResponse = await getPlayerToken(appInfo,videoId);
            if (playerTokenResponse?.status === 200) {      
              return playerTokenResponse?.data?.data;
            }
        }catch(err){

        }
      };
  return (
    <div className={`articleInfo ${isRTL ? "rtl" : ""}`}>
        <div className="wrapper">
            <h1 className="heading">
                {newsData?.title}
            </h1>
            <div className="dateSection">
                {/* <span className="icon">
                </span> */}
                <span className="date">{newDate}</span>
            </div>
            <div className="articleDetails">
                <div className="left">
                    <div className="imageContainer">
                        <img src={newsData?.logo_landscape} alt="Episodes" />
                    </div>
                </div>
                <div className="right">
                    <p>{newsData?.description}</p>
                </div>
            </div>
            {
                videoPlayer&&
            <div className="videoContainer">
                {videoPlayer}
            </div>
            }
        </div>
    </div>
  )
}

export default NewsDetails