import React from 'react'
import { Line } from 'rc-progress';
import Translate from '../../Multilanguage/Translate';
function ProgressBar({ downloadB2bModal }) {

  return (
    <div className="progressBar" style={{ padding: '50px', paddingTop: '10px' }}>
      <p className='downloadText' style={{ color: '#fff' }}><Translate textKey={'downloading'} />-{downloadB2bModal?.filename}...{`${Math.ceil(downloadB2bModal?.downloadProgress)}%`}</p>
      <Line percent={Math.ceil(downloadB2bModal?.downloadProgress)} strokeWidth={1} strokeColor="#4BB543" />
    </div>
  )
}

export default ProgressBar
