import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import {getUserSubscription } from "../../network/service";
import { socialAccountLink ,setCookie} from "../../Screens/Accounts/Login/service";
import { useNavigate } from "react-router-dom";
import Translate from "../Multilanguage/Translate";

const SocialAccountLoginModal = ({
  setShowSocialLoginModal,
  modalType,
  handleClose
}) => {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  let SocialData =  modalType?.values;

  const [msgSuccessLogin, setMsgSucessLogin] = useState("");
  const [isSuccessLoginMsg, setIsSuccessLoginMsg] = useState(false);

  useEffect(() => {
    if (isSuccessLoginMsg) {
      toast.success(msgSuccessLogin, {
        position: "top-center",
      });
    }
  }, [isSuccessLoginMsg, msgSuccessLogin]);

  const closeHandler = () => {
    setShowSocialLoginModal(false);
    if (handleClose) {
      handleClose();
    }
  };

  const onSocialLink = async () => {
    
    try {
      const response = await socialAccountLink(appInfo,modalType?.values);
      const loginSocialData = response.data.data[0];
      const subscriptionResponse = await getUserSubscription(loginSocialData.user_id);
      const user_sub = subscriptionResponse.data;

      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("userName", loginSocialData.first_name);
      setCookie("userId", loginSocialData.user_id, 30);
      setCookie("userEmail", SocialData.email, 30);
      setCookie("isLoggedIn", "true", 30);

      setMsgSucessLogin("You are successfully logged in.");
      setIsSuccessLoginMsg(true);
      setTimeout(() => {
        setIsSuccessLoginMsg(false);
        navigate(-1);
      }, user_sub.length > 0 ? 1000 : 5000);

      dispatch({ type: "LOGIN", payload: true });
    } catch (error) {
      console.error(`Error linking ${modalType?.values?.loginType} account:`, error);
     
    }
  };


  return (
    <div className="socialLoginModal">
      <ToastContainer />
      <div className="overlayModal" onClick={closeHandler}></div>
      {modalType?.values?.loginType && (
        <div className="closeIconContainer" onClick={closeHandler}>
          <CloseIcon />
        </div>
      )}
      
      <div className="contents">
        { 
          <>
            <h1>{modalType?.heading}</h1>
            <div className="buttonContainer">
              <button onClick={onSocialLink}><Translate textKey={'yes'}/></button>
              <button onClick={closeHandler}><Translate textKey={'no'}/></button> 
            </div>
          </>
        }

      </div>
    </div>
  );
};

export default SocialAccountLoginModal;
