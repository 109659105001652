import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import ShowCard from "../ShowCard/ShowCard";
import { ReactComponent as Arrow } from "../../assets/Icons/filledArrow.svg";
import { useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import { Link, useNavigate} from "react-router-dom";
import Translate from "../Multilanguage/Translate";
const ShowsRow = ({ season, data,type, title, metaData,watchVideoHandler,setVideoPlayer,similarVideos,showname ,showDetailsData }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const [rowItemCountNumber, setRowItemCountNumber] = useState(5);
  const [seasonButton, setSeasonButton] = useState(false);
  const [details, setDetails] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [seasonName,setSeasonName] = useState("")
  const navigate = useNavigate();
  const windowSize = useWindowWidth();
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  
    useEffect(() => {
      if (windowSize > 980) {
        if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) {
          setRowItemCountNumber(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT);
        } else {
          setRowItemCountNumber(4);
        }
      } else {
        if(thumbnailOrientation === "LANDSCAPE"){
        if (windowSize > 767){
          setRowItemCountNumber(2);
        } else if (windowSize > 319){
          setRowItemCountNumber(1);
        }
      } else if(thumbnailOrientation === "PORTRAIT"){
        if (windowSize > 767){
          setRowItemCountNumber(4);
        } else if (windowSize > 319){
          setRowItemCountNumber(3);
        }
      }
    }
    }, [projectInfo, windowSize]);

  useEffect(() => {
    if (data && !season) {
      setDetails(data);
    } else {
        setDetails(data[0]?.videos)
      setSeasons(data);
      if (data[0]?.season)
        { setSeasonName(data[0].season); } 
    }
  }, [data]);

  const count = details?.length;
  let displayedData = details;

  if (isRTL && count > rowItemCountNumber) {
    displayedData = [...details].reverse();
  }

  const handleShowAllClick = (selectedSeasonData) => {
    navigate(`/episodes/${showDetailsData?.vanity_url}`, { state: { selectedSeasonData, showname, showDetailsData } });
  };
 
  return (
    <div className={`showsRow ${isRTL ? (count > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl" ) : ""}`}>
      {title && <h1 className="heading">{title}</h1>}
     
      {season && (
        <div
          className={seasonButton?"seasonContainer zIndexController":"seasonContainer"}
          onClick={() => setSeasonButton(!seasonButton)}
        >
          <span className="selected">{seasonName || seasons[0]?.season}</span>
        <div className={season?.length>5?"listContainer scroll":"listContainer"}>

          <div className={seasonButton ? "list active" : "list"}>
            {seasons?.map((item, index) => (
              <div className="item" key={index} onClick={()=>{setDetails(item?.videos);setSeasonName(item?.season)}}>
                <span>{item?.season}</span>
              </div>
            ))}
           
            
          </div>
        </div>
          <Arrow className={seasonButton ? "active" : "no-active"} />
        </div>
      )}
       { title?.props?.textKey !== "you_may_also_like" &&(
        <li className="showall"> 

          <button onClick={() => handleShowAllClick(details)}><Translate textKey={'show_all'}/> ({details.length})</button>

        </li>
      )}
      <Swiper
        key={isRTL ? (count > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl" ) : ""}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={4}
        navigation={true}
        watchSlidesProgress
        loop={false}
        initialSlide={(isRTL && count > rowItemCountNumber) ? count - 1 : 0}
        breakpoints={{
          320: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?3:1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: thumbnailOrientation==="PORTRAIT"?4:2,
            spaceBetween: 10,
          },
          980: {
            slidesPerView:  projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
            spaceBetween: 10,
          },
        }}
      >
        {displayedData?.map((item, index) => (
          <SwiperSlide key={index}>
            {/* <div className="show" onClick={()=>similarVideos?setVideoPlayer(null):watchVideoHandler(item)}> */}
            <div className="show">
              <ShowCard
                season={season ? true : false}
                data={item}
                imageUrl={true}
                metaData={metaData ? true : false}
                type={type}
                thumbnailOrientation={thumbnailOrientation}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ShowsRow;
