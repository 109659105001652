import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Closeicon } from "../../assets/Icons/cross.svg";
import { getPlayerToken } from "../../network/service";
import VideoJS from "../VideoJsPlayer/VideoJS";
import Translate from "../Multilanguage/Translate";

const TrailerModal = ({teaser,showName,setIsTrailer,appInfo,showDetails}) => {
    const [trailerUrl,setTrailerUrl] = useState("")
    const [videoPlayer,setVideoPlayer] = useState()
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const refOne = useRef(null)
    const template = projectInfo?.projectConfig?.config?.TEMPLATE;

  useEffect(() => {
    fetchPlayerToken();
  }, []);
  useEffect(() => {
    setVideoPlayer(null);
    if (trailerUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${trailerUrl}`,
            type: "application/x-mpegURL",
          },
        ],
      // poster:require("../../../images/thumb.png")
      };

      setVideoPlayer(
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      );
    }
  }, [trailerUrl]);

  const handlePlayerReady = (player) => {
    player.play().catch((error) => {
      console.log("Autoplay failed", error);
    });

    player.on("play", () => {
      console.log("Video is playing");
    });
    player.on("autoplay-fail", () => {
      console.log("Autoplay failed, trying to play manually");
      player.play();
    });
  };

  

  const handleClickOutSide = (e)=> {
    if (refOne?.current && !refOne?.current?.contains(e.target)) {
      setIsTrailer(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, []);

  const fetchPlayerToken = async () => {
    let arr = teaser?.split("/").reverse();
    const playerTokenResponse = await getPlayerToken(appInfo, arr[1]);
    if (playerTokenResponse?.status === 200) {
      setTrailerUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
          arr[1] +
          "&token=" +
          playerTokenResponse?.data?.data +
          "&type=trailer&pubid="+ appInfo?.projectDetails?.projectConfig?.pubid
      );
      return playerTokenResponse?.data?.data;
    }
  };
  const trailerHandler = () => {
    setIsTrailer(false)
  }
  return (
    <div className="trailerModal">
      <div className="overlayModal" onClick={handleClickOutSide}></div>
      <div className={template === "TEMPLATE_10" ? "head gaia" : "head"}>
        <div className="left">
            <h4 className="heading"><Translate textKey={'trailer'}/></h4>
            <h1 className="title">{showName}</h1>
            <span className="director">{showDetails?.director}</span>
            <span className="year">{showDetails?.year}</span>
        </div>

        <div className="right">
            <div className="closeIcon" onClick={trailerHandler}>
                <Closeicon/>
            </div>
        </div>
      </div>
   <div className="trailorContainer" ref={refOne}>
      <div className="trailerPlayer" >
        {
            videoPlayer
        }
        
      </div>
      </div>
    </div>
  );
};

export default TrailerModal;
