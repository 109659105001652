import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as service from "./service";
import {
  getPlayerToken,
  getUserSubscription,
  updateWatchlistData,
} from "../../network/service";
import { stripeSession } from "../Subscription/service";
import { onVideoPlayFunction } from "../../utils/utils";
import { useRef } from "react";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import Translate from "../../Components/Multilanguage/Translate";

export default function Util(){

const navigate = useNavigate();
const location = useLocation();
const playerRef = useRef(null);
  
const fetchShowDetails = async ( setLoading, setShowDetails, appInfo, showId ) => {
  setShowDetails(null);
  try {
    setLoading(true);
    const showDetailsResponse = await service.getShowDetails(appInfo, showId);
    if (showDetailsResponse?.status === 200) {
      setShowDetails(showDetailsResponse?.data?.data);
      if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
        setLoading(false);
      }
    }
  } catch (err) {
    setLoading(false);
  }
};

const fetchShowDetailsByVanityUrl = async (    
    vanityUrl,
    appInfo,
    setLoading,
    setShowDetails,
    setShowId,
    setSimilarVideos,
    showId
    ) => {
    setShowDetails(null);
    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(
        appInfo,
        vanityUrl
      );
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        setShowId(showDetailsResponse?.data?.data?.show_id);
        if (showDetailsResponse?.data?.data?.teaser_flag !== 1) {
          setLoading(false);
        }
        if (!showId) {
          fetchSimilarVideos(showDetailsResponse?.data?.data?.show_id, appInfo, setSimilarVideos, showId);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchSimilarVideos = async (   
    sid,
    appInfo,
    setSimilarVideos,
    showId
    ) => {
    setSimilarVideos(null);
    let id = showId ? showId : sid;
    const similarVideosResponse = await service.getSimilarVideos(appInfo, id);
    if (similarVideosResponse?.status === 200) {
      setSimilarVideos(similarVideosResponse?.data?.data);
    }
  };

  const fetchPlayerToken = async (
    isTrailer,
    videoId,
    appInfo,
    setPlayerToken,
    setVideoUrl,
    showDetails,
    projectInfo
  ) => {
    const playerTokenResponse = await getPlayerToken(appInfo, videoId);
    if (playerTokenResponse?.status === 200) {
      setPlayerToken(playerTokenResponse?.data?.data);
      if (isTrailer) {
        let arr = showDetails?.teaser?.split("/").reverse();
        setVideoUrl(
          "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
            arr[1] +
            "&token=" +
            playerTokenResponse?.data?.data +
            "&type=trailer" +
            "&pubid=" +
            projectInfo?.projectConfig?.pubid
        );
      }
      return playerTokenResponse?.data?.data;
    }
  };

  const updateWatchlist = async (showId, flag, appInfo, setWatchlistStatus) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          toast.success(<Translate textKey={'add_to_mylist'}/>, {
            position: "bottom-center",
            toastId: "success1",
          });
          setWatchlistStatus("added");
        } else {
          toast.success(<Translate textKey={'remove_from_mylist'}/>, {
            position: "bottom-center",
          });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {}
  };

  const addToWatchlist = (setWatchlistStatus, appInfo, showId, user) => {
    if (user) {
      updateWatchlist(showId, 1, appInfo, setWatchlistStatus);
    } else {
      navigate("/login", {
        state: { path: location?.pathname, showId: showId },
      });
    }
  };

  const removeFromWatchlist = (setWatchlistStatus, appInfo, showId, user) => {
    updateWatchlist(showId, 0, appInfo, setWatchlistStatus);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) =>
      videoSub.some((video) => user.sub_id === video.subscription_id)
    );
    return hasMatch;
  };

  const playVideo = async (
    videoDetails,
    showDetails,
    categories,
    thumbnailOrientation
  ) => {
    localStorage.setItem("vanityUrl", showDetails?.vanity_url);
    navigate(`/videos/${videoDetails?.vanity_url}`, {
      state: { showDetails, videoDetails, categories, thumbnailOrientation },
    });
  };


  const handleSubscribe = async (
    data,
    user,
    projectInfo,
    appInfo,
    showId
  ) => {
    let vanityUrl = location?.pathname.split("/")[2];
    localStorage.setItem("vanityUrl", vanityUrl);
    if (user) {
      localStorage.setItem("selectedAmount", data?.price);
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        localStorage.setItem("vanityUrl", vanityUrl);
      } else {
        localStorage.setItem("showId", showId);
      }

      localStorage.setItem("selectedAmount", data?.price);
      localStorage.setItem("selectedSubId", data?.subscription_id);
      localStorage.setItem("deviceType", "web");

      if (
        projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED ==
        "true"
      ) {
        proceedToPayment(data?.subscription_id, appInfo);
      } else {
        navigate("/payment", {
          state: { subscription: { ...data, is_coupon: false } },
        });
      }
    } else {
      if (
        projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED ==
        "true"
      ) {
        navigate("/checkout", {
          state: {
            showId: showId,
            subscriptionId: data?.subscription_id,
            subscriptionPrice: data?.price,
          },
        });
      } else {
        navigate("/login", {
          state: { path: location?.pathname, showId: showId },
        });
      }
    }
  };

  const proceedToPayment = async (selectedPlan, appInfo) => {
    const paymentInfo = {
      subId: selectedPlan,
    };
    try {
      const response = await stripeSession(appInfo, paymentInfo);

      if (response?.status === 200) {
        window.open(response?.data?.url, "_self");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const watchVideoHandler = async (
    videoDetails,
    showId,
    setSelectedVideoDetails,
    showDetails,
    categories,
    thumbnailOrientation
  ) => {
    setSelectedVideoDetails({ ...videoDetails, showId: showId });
    playVideo(videoDetails, showDetails, categories, thumbnailOrientation);
  };


  // const handlePlayerReady = (
  //   player,
  //   trailerPlaying,
  //   videoStarted,
  //   videoPlaying,
  //   watchDuration,
  //   debounce,
  //   prevTime,
  //   episodes,
  //   episodeCount,
  //   seconds,
  //   setNextEpisodeModal,
  //   selectedVideoDetails,
  //   appInfo,
  //   categories,
  //   showDetails
  // ) => {
  //   playerRef.current = player;
  //   if (!trailerPlaying) {
  //     player.on("play", () => {
  //       let event = videoStarted === true ? "POP09" : "POP02";
  //       videoStarted = true;
  //       videoPlaying = true;
  //       updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
  //     });
  //     player.on("loadedmetadata", () => {
  //       player.pause();
  //       if (watchDuration > 0) {
  //         player.currentTime(watchDuration.toString());
  //         player.play();
  //         videoPlaying = true;
  //       } else {
  //         player.play();
  //         videoPlaying = true;
  //       }
  //     });
  //     player.on("timeupdate", function (e) {
  //       let event = "POP03";
  //       videoPlaying = true;
  //       var hasPlayedTime = player.currentTime();
  //       var intPlayedTime = parseInt(hasPlayedTime, 10);
  //       var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
  //       if (isSixty && debounce) {
  //         debounce = false;
  //         prevTime = intPlayedTime;
  //         updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
  //       } else {
  //         if (debounce == false && prevTime != intPlayedTime) {
  //           debounce = true;
  //         }
  //       }
  //     });

  //     player.on("pause", () => {
  //       let event = "POP04";
  //       videoPlaying = true;
  //       updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
  //     });
  //     player.on("ended", () => {
  //       let event = "POP05";
  //       videoPlaying = false;
  //       episodeCount = findIndex( episodes, selectedVideoDetails ) + 1;

  //       updateVideoAnalytics(event, player.currentTime(), appInfo, selectedVideoDetails, categories, showDetails);
  //       if (episodes[episodeCount]) {
  //         setNextEpisodeModal(true);
  //       }
  //     });
  //     player.on("dispose", () => {
  //       videoPlaying = false;
  //       videoStarted = false;
  //       player.pause();
  //     });
  //   } else {
  //     player.on("timeupdate", () => {
  //       videoPlaying = true;
  //     });
  //     player.on("dispose", () => {
  //       videoPlaying = false;
  //     });
  //   }
  // };

  const updateVideoAnalytics = async (event, time, appInfo, selectedVideoDetails, categories, showDetails) => {
    const isLive = "0";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      selectedVideoDetails,
      event,
      time,
      isLive,
      categories,
      showDetails?.show_id
    );
  };

  const findIndex = ( episodes, selectedVideoDetails ) => {
    let elementIndex = null;
    if (episodes) {
      episodes?.filter((filteringItem, index) => {
        if (filteringItem?.video_id === selectedVideoDetails?.video_id) {
          elementIndex = index;
        }
      });
    }
    return elementIndex;
  };

  const fetchUserSubscriptionDetails = async ( appInfo, dispatch ) => {
    const userSubResponse = await getUserSubscription(appInfo);
    try{
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
   } catch (error) {
      console.error('Error fetching user subscription:', error);
    }
  };

  return{
    fetchShowDetails,
    fetchShowDetailsByVanityUrl,
    fetchSimilarVideos,
    fetchPlayerToken,
    addToWatchlist,
    removeFromWatchlist,
    subscriptionCheck,
    handleSubscribe,
    watchVideoHandler,
    updateVideoAnalytics,
    findIndex,
    // handlePlayerReady,
    fetchUserSubscriptionDetails,
  };
}