
import React from "react";
import { convertAdUrl ,translateText} from "../../utils/utils";
import { useSelector } from "react-redux";
import Translate from "../Multilanguage/Translate";
const ContinueWatchingModel = (props) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const ContinueWatchingHandler = async (value) => {
    if (value === "yes") {
      props?.setIsContinueWatchingModal(false)
      props?.playVideo(props?.videoDetails?.watched_duration);
  
    } else if (value === "no") {
      props?.setIsContinueWatchingModal(false)
      props?.playVideo();
    }
  };
  return (
    <div className="countinueWatching">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1><Translate textKey={'continue_from_stopped'} /></h1>
        <div className="buttons">
          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("yes")}
          >
            <div className="background"></div>
            <button><Translate textKey={'yes'} /></button>
          </div>

          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("no")}
          >
            <div className="background"></div>
            <button><Translate textKey={'no'} /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueWatchingModel;
