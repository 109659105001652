import React from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as PinterestIcon } from "../../assets/Icons/pinterest.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/Icons/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/Icons/linkedIn.svg";
import { ReactComponent as AppStore } from "../../assets/Icons/appstore.svg";
import { ReactComponent as PlayStore } from "../../assets/Icons/playstore.svg";
import { ReactComponent as DownloadApk } from "../../assets/Icons/downloadapk.svg";
import DownloadAPKModal from "../Modals/DownloadAPKModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Translate from "../Multilanguage/Translate";
const Footer = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [downloadModal, setDownloadModal] = useState(false);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const navigate = useNavigate();
  const copyrightText = projectInfo?.projectConfig?.config?.COPYRIGHT_TEXT;
  const copyrightDocumentLink = projectInfo?.projectConfig?.config?.COPYRIGHT_DOCUMENT;

  const linkHandler = (data) => {
    if (data?.seo_url) {
      navigate(data?.seo_url);
    } else if (data?.title == 'refund_policy') {
      window.open(data?.link, "_self");
    } else if (data?.link) {
      window.open(data?.link);
    }
  };
  
  return (
    <div className={`footerContainer ${isRTL ? "rtl" : ""}`}>
      <div className="wrapper">
      {downloadModal && <DownloadAPKModal setDownloadModal={setDownloadModal} />}
        <div className="topContainer">
          <div className="left">
            <div className="logoContainer">
              <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
            </div>
          </div>
          <div className="right">
            <ul className="social">
              {projectInfo?.projectConfig?.config?.FACEBOOK && (
                <li
                onClick={() =>
                  window.open(
                    projectInfo?.projectConfig?.config?.FACEBOOK,
                    "blank"
                  )
                }
              >
                <FacebookIcon />
              </li>
              )}

              {projectInfo?.projectConfig?.config?.INSTAGRAM && (
                <li
                  onClick={
                    () =>
                    window.open(
                      projectInfo?.projectConfig?.config?.INSTAGRAM,
                      "blank"
                    )
                  }
                >
                  <InstagramIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.TWITTER && (
                <li
                  onClick={
                    () => 
                    window.open(
                      projectInfo?.projectConfig?.config?.TWITTER,
                      "blank"
                    )
                  }
                >
                  <TwitterIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.LINKEDIN && (
                <li
                  onClick={
                    () => 
                    window.open(
                      projectInfo?.projectConfig?.config?.LINKEDIN,
                      "blank"
                    )
                  }
                >
                  <LinkedInIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.PINTEREST && (
                <li
                onClick={
                  () => 
                  window.open(
                    projectInfo?.projectConfig?.config?.PINTEREST,
                    "blank"
                  )
                }
                >
                  <PinterestIcon />
                </li>
              )}
              {projectInfo?.projectConfig?.config?.YOUTUBE && (
                <li
                onClick={
                  () => 
                  window.open(
                    projectInfo?.projectConfig?.config?.YOUTUBE,
                    "blank"
                  )
                }
                >
                  <YoutubeIcon />
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="centerContainer">
          <div className="informationContainer">
            {projectInfo?.projectConfig?.config?.WEBSITE_FOOTERS?.map((data, index) => (
              <div className="items">
                <h6 className="heading"><Translate textKey={data.title} /></h6>
                <ul>
                  {data?.items?.map((item, index) => (
                    <li>
                      <span onClick={() => linkHandler(item)}><Translate textKey={item.title} /></span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bottomContainer">
        <div className="stores">
          {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && (
            <AppStore onClick={() => window.open(projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL, "blank")} />
          )}
          {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && (
            <PlayStore
              onClick={() => window.open(projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL, "blank")}
            />
          )}
          {projectInfo?.projectConfig?.config?.ANDROID_APK_LINK && (
            <DownloadApk
              onClick={()=>setDownloadModal(true)}
            />
          )}
        </div>

        <p>
          <a href={copyrightDocumentLink} target="_blank" >
            {copyrightText}
          </a>
        </p>

        <p><Translate textKey={'all_rights_reserved'} /></p>
        {projectInfo?.projectConfig?.config?.FOOTER_PAYMENTS_REQUIRED === "true" && (
          <div className="typeOfPayments">
            <i className="fa-brands fa-2x fa-cc-discover"></i>
            <i class="fa-brands fa-2x fa-cc-mastercard"></i>
            <i className="fa-brands fa-2x fa-cc-paypal"></i>
            <i className="fa-brands fa-2x fa-cc-visa"></i>
            <i class="fa-solid fa-globe"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
