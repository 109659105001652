import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as MenuIcon } from "../../assets/Icons/MenuIcon.svg";
import { ReactComponent as CircleArrow } from "../../assets/Icons/filledCircleArrow.svg";
import { ReactComponent as SignOutIcon } from "../../assets/Icons/signOut.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getUserDetails } from "../../Redux/UserDetailsSlice/UserDetailsSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { setRTL } from "../../Redux/RTL/RTLSlice";
import {
  appInfo,
  authenticate2,
  getUserSubscription,
  getLanguageList,    
  updateUserLanguage, 
  authenticate,      
} from "../../network/service";
import * as service from "./service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { getLanguageModal } from "../../Redux/LanguageModal/languageModalSlice";
import Translate from "../Multilanguage/Translate";
import { useTranslation } from "react-i18next"; 
import Util from "./util";
import { useTranslateText } from "../Multilanguage/Translate";
const Header = () => {
  const { fetchMenuItems,
    fetchUserSubscriptionDetails,
    searchSubmit,
    searchSubmitBrowseMore,
    logoutHandler,
    tokenAuthenticate,
    languageHandler,
    menuNavigateHandler,
    hexToRgb,
    handleLanguageClick,
    fetchLanguageOptions,
    handleLanguageChange } = Util();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  // const selectedLanguage = useSelector((state) => state?.languageModal?.value?.selectedLanguage);
  const selectedLanguageCode = useSelector((state) => state?.languageModal?.value?.selectedLanguageCode);
  const { i18n } = useTranslation(); 
  
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [menuItems, setMenuItems] = useState();
  const [mainMenuItems, setMainMenuItems] = useState([]);
  const [browseHover, setBrowseHover] = useState("");
  const [browseMoreMediumDevice, setBrowseMoreMediumDevice] = useState(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [previousLocation, setPreviousLocation] = useState("");
  const [isSubscribedUser, setIsSubscribedUser] = useState(false)
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({ short_code: 'en' });
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isRTL, setIsRTL] = useState(useSelector((state) => state?.rtl?.isRTL));
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const searchRefContainer = useRef(null);
  const searchRefSmall = useRef(null);
  const searchRefSmallContainer = useRef(null);
  const browseMoreContainerRef = useRef(null);
  const translateText = useTranslateText();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  const eventId = location?.state?.eventId || localStorage.getItem("eventId")
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;
  const buttonColor = projectInfo?.projectConfig?.config?.BUTTON_COLOR;
  const buttonSeclectionColor = projectInfo?.projectConfig?.config?.BUTTON_SELECTION_COLOR;

  useEffect(() => {
    fetchLanguageOptions(appInfo, setLanguageOptions, setSelectedLanguage, projectInfo, userDetails, user);
  }, [userDetails, user]);

  useEffect(() => {
    dispatch(setRTL(selectedLanguage));
  }, [selectedLanguage, dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      // setHeaderVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  useEffect(() => {
    if (searchActive) {
      // searchRef?.current.focus();
      searchRefSmall?.current?.focus();
      searchRefSmallContainer?.current?.focus();
      setPreviousLocation(location);
    }
  }, [searchActive]);

  useEffect(() => {
    if (accessToken) {
      fetchUserSubscriptionDetails(appInfo, setIsSubscribedUser);
    }
  }, [user, accessToken]);

  useEffect(() => {
    fetchMenuItems(appInfo, setMenuItems, setMainMenuItems);
  }, [user]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !searchRefContainer?.current?.contains(e.target) &&
        !searchRefSmallContainer?.current?.contains(e.target)
      ) {
        setInputValue("");
        setSearchActive(false); //outside click
      }
    };

    window.addEventListener("click", handleOutClick);
    return () => {
      window.addEventListener("click", handleOutClick);
    };
  }, [searchRefContainer, searchRefSmallContainer]);
  
  const buttonColorRgb = buttonColor ? hexToRgb(buttonColor) : '';
  const buttonSelection = buttonSeclectionColor ? hexToRgb(buttonSeclectionColor) : '';

  return (
    <>
      <ToastContainer />
      <div
        className={browseMoreMediumDevice ? "active overlayOne" : "overlayOne"}
      ></div>
      <div
        className={
          position === 0 ? "header-container" : "header-container background"
        }
      >
        <div className="wrapper">
          <div className="leftContainer">
            <div className="menuIconContainer">
              <MenuIcon onClick={() => setBrowseMoreMediumDevice(true)} />
            </div>
            <div className="logoContainer">
              <Link to="/home">
                <h1>
                  <img
                    src={projectInfo?.projectConfig?.config?.LOGO}
                    alt="Logo"
                  />
                </h1>
              </Link>
            </div>
            <ul className={searchActive ? "hide browse" : "browse"}>
              <li
                className={browseHover === "show" ? "active" : "no-active"}
                onClick={() => setBrowseMoreMediumDevice(true)}
                onMouseOver={() => setBrowseHover("show")}
                onMouseLeave={() => setBrowseHover(null)}
              >
                <span><Translate textKey={'browse'} /></span>
              </li>
              {/* {
                projectInfo?.projectConfig?.config?.LIVE_REQUIRED ==="true"&&
              <li className={location?.pathname==="/player"?"active":""} onClick={()=>navigate("/player")}>
                <span>Live</span>
              </li>
              } */}


              {template === 'TEMPLATE_9' && mainMenuItems?.map((item, index) => {

                return (
                  <li
                    key={index}
                    className={location?.pathname === "/category/" + item?.key ? "active mainMenu" : "mainMenu"}
                    onClick={() => menuNavigateHandler(item, setBrowseHover, setBrowseMoreMediumDevice)}
                  // style={{ padding: location?.pathname !== "/category/" + item?.key && '0px' }}
                  >
                    <span>{item?.value}</span>
                  </li>
                );
              })
              }

              {/* <li
                onClick={() => menuNavigateHandler({ key: "movies", value: "Films & Documentaries" })}
              >
                <span>Movies</span>
              </li> */}

              {/* <li
                onClick={() => menuNavigateHandler({ key: "series", value: "Series" })}
              >
                <span>Series</span>
              </li> */}

              {projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" ? (
                <li
                  className={location?.pathname === "/live-channels" ? "active" : ""}
                  onClick={() => navigate("/live-channels")}
                >
                  {/* <span>Live</span> */}
                  <span>
                    {projectInfo?.projectConfig?.pubid === 50095
                      ? <Translate textKey={'free_tv'} />
                      : <Translate textKey={'live'} />}
                  </span>
                </li>
              ) : (
                projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" && (
                  <li
                    className={location?.pathname === "/player" || location?.pathname === "/live" ? "active" : ""}
                    onClick={() => navigate("/player")}
                  >
                    <span>
                      {projectInfo?.projectConfig?.pubid === 50095
                        ? <Translate textKey={'free_tv'} />
                        : <Translate textKey={'live'} />}
                    </span>
                  </li>
                )
              )}
              {projectInfo?.projectConfig?.config?.SHORTS_REQUIRED === "true" && user &&
                <li
                  className={"shorts"}
                  onClick={() => navigate("/shorts")}
                >
                  <span>
                  <Translate textKey={'shorts'} />
                  </span>
                </li>
              }



            </ul>
          </div>
          <div
            className={
              searchActive ? "rightContainer increase" : "rightContainer"
            }
          >
            <div
              className={
                searchActive ? "inputContainer showInput" : "inputContainer"
              }
              ref={searchRefContainer}
              onClick={() => {
                setSearchActive(true);
                searchRef?.current.focus();
              }}
            >
              {searchActive ? (
                <SearchIcon
                  onClick={
                    // () => searchSubmit(inputValue)
                    () => searchSubmit( inputValue, setInputValue, setSearchActive, previousLocation )
                    // setSearchActive(!searchActive)
                  }
                />
              ) : (
                <SearchIcon
                  onClick={() => {
                    searchRef?.current.focus();
                    setSearchActive(true);
                  }}
                />
              )}

              <input
                ref={searchRef}
                onClick={() => setSearchActive(true)}
                type="text"
                value={inputValue}
                // placeholder={searchActive && "Find Movies, TV shows and more"}
                placeholder={
                  !searchActive
                    ? translateText("search_placeholder") || "Find Movies, TV shows and more"
                    : ""
                }
                onChange={(e) => {
                  // searchHandleChange(e?.target?.value);
                  setInputValue(e?.target?.value);
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? 
                  // searchSubmit(inputValue) 
                  searchSubmit( inputValue, setInputValue, setSearchActive, previousLocation )
                  : ""
                }
                style={{
                  fontFamily: template === 'TEMPLATE_9' ? 'Gilroy-Medium' : ''
                }}
              />
            </div>
            {/* <i class="fa-regular fa-globe"></i> */}
            {/* <div className="language" onClick={()=>languageHandler()}>
              <i class="fa-solid fa-globe"></i>
              <span>{selectedLanguage!==null?selectedLanguage:"Language"}</span>

            </div> */}
                        {projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === "true" && (
                  <div 
                  className={`languageDropdown ${isLanguageOpen ? "active" : ""}`}
                  onMouseOver={() => setIsLanguageOpen(true)}
                  onMouseLeave={() => setIsLanguageOpen(false)}
                >
                  <div className="language">
                    <Translate textKey={'change_language'} /> ({selectedLanguageCode})
                  </div>
                  
                  {isLanguageOpen && (
                    <div className="languageOptions">
                      <div className="optionsList">
                        {languageOptions?.map((language) => (
                          <div
                            key={language.language_id}
                            data-selected={language.short_code === selectedLanguage.short_code}
                            className="languageOption"
                            onClick={() => handleLanguageChange(language, setSelectedLanguage, setIsLanguageOpen, appInfo, projectInfo, getLanguageModal, getUserDetails, userDetails, languageOptions)}
                          >
                            ({language.short_code}) {language.language_name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
            ) }
            {user ? (
              <div
                className={userInfo ? "userInfo active" : "userInfo"}
                onMouseEnter={() => setUserInfo(true)}
                onMouseLeave={() => setUserInfo(false)}
              >
                <h4 className={userInfo ? "active" : ""}><Translate textKey={'hi_message'} />, {userDetails?.first_name ? userDetails?.first_name : userDetails?.user_email?.split('@')[0]}</h4>
                <CircleArrow />
              </div>
            ) : (
              <div className="buttonContainer">
                {location?.pathname != '/checkout' && <button
                  className="register"
                  style={projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ? {
                    width: '100%',
                    padding: '10px 15px',
                    borderRadius: '4px'
                  } : {}}
                  onClick={() => {
                    localStorage.setItem("vanityUrl", '')
                    navigate(projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ? "/checkout" : "/register")
                  }}
                >
                  {(projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' && projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT )? <Translate textKey={projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT}/>: <Translate textKey={'register'} />}
                </button>}
                {location?.pathname != '/checkout' && <button className="signIn" onClick={() => {
                  localStorage.setItem("vanityUrl", '')
                  navigate(
                    "/login",
                    { state: { activeLogin: true } }
                  );
                }}>
                {
                  projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === 'true' 
                    ? <Translate textKey={'sign_in'} /> 
                    : <>
                        Sign&nbsp;In
                      </>
                  }
                  {/* Sign&nbsp;In */}
                </button>}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          userInfo && headerVisible
            ? "userInfoContainer active"
            : "userInfoContainer"
        }
        onMouseOver={() => setUserInfo(true)}
        onMouseLeave={() => setUserInfo(false)}
        onClick={() => setUserInfo(!userInfo)}
      >
        <div className="top">
          <ul>
            <Link to="/account">
              <li>
                <span><Translate textKey={'account_settings'} /></span>
              </li>
            </Link>
            <Link to="/my-list">
              <li>
                <span><Translate textKey={'my_list'} /></span>
              </li>
            </Link>
            <Link to="/tv">
              <li>
                <span><Translate textKey={'link_tv_app'} /></span>
              </li>
            </Link>
            {projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === 'true' &&
              (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED === 'true' && !isSubscribedUser ? (<Link to="/checkout">
                  <li>
                    <span><Translate textKey={projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT} /></span>
                  </li>
                </Link>
              ) : !isSubscribedUser && ( <Link to="/subscription">
                  <li>
                    <span> <Translate textKey={'subscription_plans'} /> </span>
                  </li>
                </Link>))
            }
              {/* {projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === "true" && (
                  <Link to="/language-selection" onClick={languageHandler}>
                    <li>
                      <span className="language">
                        <Translate textKey={'change_language'} />
                      </span>
                    </li>
                  </Link>
                ) } */}
          </ul>
        </div>
        <div className="bottom">
          <div className="signOutContainer" onClick={() => logoutHandler(appInfo)}>
            <SignOutIcon />
            <span><Translate textKey={'sign_out'} /></span>
          </div>
        </div>
      </div>

      <div
        className={browseHover === "show" ? "browseMore active" : "browseMore"}
      >
        <div className="overlay"></div>

        {
          template === 'TEMPLATE_9' ?
            (
              <div
                className="browseMoreContainerNew"
                onMouseOver={() => setBrowseHover("show")}
                onMouseLeave={() => setBrowseHover(null)}
              >
                {projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" && (

              <div className="categoryContainer" style={{ '--buttonColorRgb': buttonColorRgb ,'--buttonSelection':buttonSelection}}>
                <h1 className="categoryName"><Translate textKey={'links'} /></h1>
                <li>
                  <li onClick={() => navigate("/live-channels")}>
                  <div className="shadeGradient"
                        style={{
                          background: `linear-gradient(270deg, rgba(${buttonColorRgb}, 0) 0%, rgb(${buttonColorRgb}) 100%)`,
                        }}
                      ></div>
                    <span><Translate textKey={'live_channels'} /></span>
                  </li>
                </li>
              </div>
            )}
            {menuItems?.map((item, index) => (
              <div className="categoryContainer" style={{ '--buttonColorRgb': buttonColorRgb ,'--buttonSelection':buttonSelection}} key={index}>             
                <h1 className="categoryName">{item?.key}</h1>
                <li cl>             
                  {item?.items?.map((itemShow, keyIndex) => (
                    <li key={keyIndex} onClick={() => menuNavigateHandler(itemShow, setBrowseHover, setBrowseMoreMediumDevice)}>
                      <div
                        className="shadeGradient"
                        style={{
                          background: `linear-gradient(270deg, rgba(${buttonColorRgb}, 0) 0%, rgb(${buttonColorRgb}) 100%)`,
                        }}
                      ></div>
                      <span>{itemShow?.value}</span>
                    </li>
                  ))}
                </li>
              </div>
            ))}
          </div>
        ):
        ( 
          <div
            className="browseMoreContainer"
            onMouseOver={() => setBrowseHover("show")}
            onMouseLeave={() => setBrowseHover(null)}
          >
            {
              projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" &&

              <div className="left">
                <h1 className="categoryName"><Translate textKey={'links'} /></h1>
                <ul>
                  <li onClick={() => navigate("/live-channels")}>
                    <span><Translate textKey={'live_channels'} /></span>
                  </li>
                </ul>
              </div>
            }
            {menuItems?.map((item, index) => (
              <div className="left" key={index}>
                <h1 className="categoryName">{item?.key}</h1>
                <ul>
                  {item?.items?.map((itemShow, keyIndex) => (
                    <li key={keyIndex} onClick={() => menuNavigateHandler(itemShow, setBrowseHover, setBrowseMoreMediumDevice)}>

                      <span> {itemShow?.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )
      }        
      </div>

      <div className="browseMoreResponsive">
        <div
          className={browseMoreMediumDevice ? "container active" : "container"}
          ref={browseMoreContainerRef}
        >
          <div className="head large">
            <div className="left">
              <h1><Translate textKey={'browse'} /></h1>
            </div>
            <div className="right">
              <CloseIcon onClick={() => setBrowseMoreMediumDevice(false)} />
            </div>
          </div>
          <div className="head small">
            <div className="left">
              <CloseIcon onClick={() => setBrowseMoreMediumDevice(false)} />
              <span><Translate textKey={'close'} /></span>
            </div>
            <div className={searchActive ? "right increase" : "right"}>
              <div
                className={
                  searchActive ? "inputContainer showInput" : "inputContainer"
                }
                ref={searchRefSmallContainer}
              >
                <SearchIcon
                  onClick={() => {
                    // searchResultsShow();
                    // searchSubmitBrowseMore(inputValue);
                    searchSubmitBrowseMore( inputValue, setInputValue, setSearchActive, setBrowseMoreMediumDevice, previousLocation ) 
                  }}
                />

                <input
                  ref={searchRefSmall}
                  onClick={() => {
                    setSearchActive(true);
                    // console.log("clicked");
                  }}
                  type="text"
                  value={inputValue}
                  placeholder={
                    !searchActive
                      ? translateText("search_placeholder") || "Find Movies, TV shows and more"
                      : ""
                  }
                  onChange={(e) => {
                    // searchHandleChange(e?.target?.value);
                    setInputValue(e?.target?.value);
                  }}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? 
                    // searchSubmitBrowseMore(inputValue) 
                    searchSubmitBrowseMore( inputValue, setInputValue, setSearchActive, setBrowseMoreMediumDevice, previousLocation ) 
                    : ""
                  }
                />
              </div>
            </div>
          </div>

          {/* <div className="language" onClick={()=>languageHandler()}>
              <i class="fa-solid fa-globe"></i>
              <span>{selectedLanguage!==null?selectedLanguage:"Language"}</span>
            </div> */}
          {
            projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" ||
              projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" ? (

              <div className="top">
                <h1 className="categoryName"><Translate textKey={'links'} /></h1>
                <ul>
                  {
                    projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" &&
                    <li onClick={() => { navigate("/player"); setBrowseMoreMediumDevice(false) }}><span><Translate textKey={'live'} /></span></li>
                  }
                  {
                    projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" &&
                    <li onClick={() => { navigate("/live-channels"); setBrowseMoreMediumDevice(false) }}><span><Translate textKey={'live_channels'} /></span></li>
                  }
                </ul>
              </div>
            ) : null
          }
          {projectInfo?.projectConfig?.config?.SHORTS_REQUIRED === "true" && user &&
            <div className="top">
              <h1 className="categoryName"><Translate textKey={'shorts'} /></h1>
              <ul>
                <li onClick={() => { navigate("/shorts"); setBrowseMoreMediumDevice(false) }}><span><Translate textKey={'shorts'} /></span></li>
              </ul>
            </div>
          }
          {menuItems?.map((item, index) => (
            <div className="top" key={index}>
              <h1 className="categoryName">{item?.key}</h1>
              <ul>
                {item?.items?.map((items, i) => (
                  <li key={i} onClick={() => menuNavigateHandler(items, setBrowseHover, setBrowseMoreMediumDevice)}>
                    <span> {items?.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
                    {projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === "true" && (
                  <div 
                  className={`languageDropdown ${isLanguageOpen ? "active" : ""}`}
                  onClick={(e)=>handleLanguageClick(e, setIsLanguageOpen, browseMoreContainerRef)}
                >
                  <h1 className="language">
                    <Translate textKey={'change_language'} /> ({selectedLanguageCode})
                  </h1>
                  
                  {isLanguageOpen && (
                    <div className="languageOptions">
                      <div className="optionsList">
                        {languageOptions?.map((language) => (
                          <div
                            key={language.language_id}
                            data-selected={language.short_code === selectedLanguage.short_code}
                            className="languageOption"
                            onClick={() => handleLanguageChange(language, setSelectedLanguage, setIsLanguageOpen, appInfo, projectInfo, getLanguageModal, getUserDetails, userDetails, languageOptions)}
                          >
                            ({language.short_code}) {language.language_name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
            ) }
        </div>
      </div>
    </>
  );
};

export default Header;
