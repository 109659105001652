import { getUserSubscription, authenticate2 } from '../../network/service';
import { getEventInfo } from '../../utils/utils';
import { getUserSubscriptionData } from '../../Redux/UserSubscriptionDetails/userSubscriptionSlice';
import { getAccessToken } from '../../Redux/AToken/ATokenSlice';
import { getUser } from '../../Redux/UserSlice/UserSlice';
import { getVideoSubscription } from "../Subscription/service";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Translate from '../../Components/Multilanguage/Translate';

const Util = () => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
    };

    const fetchUserSubscriptionDetails = async (appInfo, dispatch) => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
        dispatch(
        getUserSubscriptionData({
            userSubscription: userSubResponse?.data,
        })
        );
    }
    };

    const redirectToLogin = (dispatch, location, eventId) => {
    dispatch(
        getUser({
        user: null,
        })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate(appInfo, dispatch);
    navigate("/login", {
        state: { path: location?.pathname, showId: eventId },
    });
    };

    const tokenAuthenticate = async (appInfo, dispatch) => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
        dispatch(
        getAccessToken({
            accessToken: response?.data?.token,
        })
        );
    }
    };

    const handleSubscribe = (data, user, eventDetails, eventId) => {
    if (user) {
        localStorage.setItem("selectedSubId", data?.subscription_id);
        localStorage.setItem("selectedAmount", data?.price);
        localStorage.setItem("eventId", eventDetails?.event_id);
        localStorage.setItem("vanityUrl", eventId);
        localStorage.setItem("eventType", eventDetails?.type);
        navigate("/payment", { state: { subscription: { ...data, is_coupon: false } } });
    } else {
        navigate("/login", {
        state: { path: "/event/" + eventId, showId: eventId },
        });
    }
    };

    const fetchEventSubscription = async (appInfo, eventDetails) => {
    try {
        const type = "event";
        const videoSubscriptionRespone = await getVideoSubscription(appInfo, eventDetails?.event_id, type);
        if (videoSubscriptionRespone?.status === 200) {
        return videoSubscriptionRespone?.data?.data;
        }
    } catch (err) { }
    };

    const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
    };

    const watchVideoHandler = async (projectInfo, userSubscriptionData, eventDetails, eventId, setModalType, setLogoutModal, appInfo, user) => {
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
        subscriptionCheckAndVideoPlay(projectInfo, userSubscriptionData, eventDetails, eventId, setModalType, setLogoutModal, appInfo, user);
    } else {
        if (userSubscriptionData?.login_needed === false) {
        playVideo(eventDetails);
        } else if (userSubscriptionData?.login_needed === true) {
        setModalType({ type: "videoWatchLimitExpired" });
        setLogoutModal(true);
        } else {
        subscriptionCheckAndVideoPlay(projectInfo, userSubscriptionData, eventDetails, eventId, setModalType, setLogoutModal, appInfo, user);
        }
    }
    };

    const subscriptionCheckAndVideoPlay = async (projectInfo, userSubscriptionData, eventDetails, eventId, setModalType, setLogoutModal, appInfo, user) => {
    if (user) {
        if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
        if (userSubscriptionData?.forcibleLogout === true) {
            setLogoutModal(true);
            setModalType({
            type: "logoutAll",
            heading: <Translate textKey={'reached_maximum_device_message'}/>,
            });
        } else if (userSubscriptionData?.session_expired === true) {
            setModalType({ type: "redirect" });
            setLogoutModal(true);
            setTimeout(() => {
            redirectToLogin(dispatch, location, eventId);
            }, 1000);
        } else {
            if (eventDetails?.subscriptions?.length > 0) {
            let isSubscribedUser;
            const res = await fetchEventSubscription(appInfo, eventDetails)
            isSubscribedUser =
                userSubscriptionData?.data?.length > 0
                ? subscriptionCheck(userSubscriptionData?.data, res)
                : false;

            if (isSubscribedUser === true) {
                playVideo(eventDetails);
            } else {
                if (eventDetails?.free_event === true) {
                playVideo(eventDetails);
                } else {
                localStorage.setItem("eventId", eventDetails?.event_id);
                localStorage.setItem("vanityUrl", eventId);
                localStorage.setItem("eventType", eventDetails?.type);

                navigate("/subscription", {
                    state: { eventId: eventDetails?.event_id },
                });
                }
            }
            } else {
            if (eventDetails) {
                playVideo(eventDetails);
            }
            }
        }
        } else {
        playVideo(eventDetails);
        }
    } else {
        navigate("/login", {
        state: { path: "/event/" + eventId, showId: eventId },
        });
    }
    };

    const playVideo = async (eventDetails) => {
    if (eventDetails?.type !== "UPCOMING") {
        navigate("/player",
        { state: { eventId: eventDetails?.event_id } }
        );
    } else {
        toast.error(<Translate textKey={'event_not_started_yet'}/>)
    }
    };

    const fetchEventInfo = async (appInfo, eventId, setEventDetails, setLoading) => {
    try {
        const response = await getEventInfo(appInfo, eventId);
        setEventDetails(response?.data?.data)
        setLoading(false)
    } catch (err) {
        setLoading(false)
    }
    };

    const showDetailsRedirect = (eventDetails) => {
    navigate(`/show-details/${eventDetails?.vanity_url}`)
    };

    return {
        fetchUserSubscriptionDetails,
        redirectToLogin,
        tokenAuthenticate,
        handleSubscribe,
        fetchEventSubscription,
        subscriptionCheck,
        watchVideoHandler,
        subscriptionCheckAndVideoPlay,
        playVideo,
        fetchEventInfo,
        showDetailsRedirect
    };

}

export default Util;