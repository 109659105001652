import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getDeviceSubscription } from "../../Redux/DeviceSubscriptionData/deviceSubscriptionSlice";
import { getProjectInfo } from "../../Redux/ProjectInfo/ProjectInfoSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getVideoSubscription } from "./service";
import { getAndroidSubscriptionDetails, getSubscriptionList, getUpgradeSubscriptionList, subscriptionUpdate, getUpcomingInvoice } from "./service";
import { getUserDetails } from "../../Redux/UserDetailsSlice/UserDetailsSlice";
import { ReactComponent as RightArrow } from "../../assets/Icons/rightArrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/Icons/leftArrow.svg";
import { setSubscriptionPageSkip, setTvCode } from "../../Redux/TVcode/tvCodeSlice";
import Translate from "../../Components/Multilanguage/Translate";
import { loadStripe } from '@stripe/stripe-js';


const Subscription = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const tvCode = useSelector((state) => state?.tv?.tvCode);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const location = useLocation();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionHeading, setSubscriptionHeading] = useState();
  const [subscriptionConditions, setSubscriptionConditions] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState("")
  const [showConfirmWindow, setShowConfirmWindow] = useState(false);
  const [stripeDeatils, setStripeDeatils] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const showButton = location?.state?.showButton ? location?.state?.showButton : false;

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    const androidToken = urlParams.get("antkn");
    // const androidToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaGVjayI6dHJ1ZSwiaWF0IjoxNjcwNDk4MTc2LCJleHAiOjE2NzA0OTg3NzZ9.InaDjgFOWgVkdLoqf1PSgsex4hNqqCr8JrBVtt0hqYw"
    if (location?.state?.type === "upgrade") {
      // projectInfo.projectConfig.pubid != 50111 && localStorage.setItem('isUpgrade', 'true');
      localStorage.setItem('previousSubId', location?.state?.subId);
      fetchUpgradeSubscription()
    }
    else if (location?.state?.eventId) {
      let type = "event"
      const antkn = {
        androidToken: null,
        isAndroid: false,
      };
      dispatch(
        getDeviceSubscription({
          deviceSubscription: antkn,
        })
      );
      localStorage.setItem("deviceType", "web");

      fetchVideoSubscription(appInfo, location?.state?.eventId, type)
    }
    else if (location?.state?.channelId) {
      let type = "channel"
      const antkn = {
        androidToken: null,
        isAndroid: false,
      };
      dispatch(
        getDeviceSubscription({
          deviceSubscription: antkn,
        })
      );
      localStorage.setItem("deviceType", "web");
      fetchVideoSubscription(appInfo, location?.state?.channelId, type)
    } else if (location?.state?.tvSubscriptionList === true) {
      fetchSubscriptionTv();
    }
    else if (!location?.state?.videoId) {
      if (androidToken) {
        const deviceId = urlParams.get("deviceId");
        // const deviceId=122;
        const info = {
          projectConfig: projectInfo?.projectConfig,
          geoInfo: projectInfo?.geoInfo,
          device_id: deviceId,
        };
        dispatch(
          getProjectInfo({
            projectInfo: info,
          })
        );
        fetchAndroidSubscriptionDetails(androidToken, info);
      } else {
        const antkn = {
          androidToken: null,
          isAndroid: false,
        };
        dispatch(
          getDeviceSubscription({
            deviceSubscription: antkn,
          })
        );
        localStorage.setItem("deviceType", "web");

        fetchSubscriptionList(appInfo);
      }
    } else {
      let type = "video"
      const antkn = {
        androidToken: null,
        isAndroid: false,
      };
      dispatch(
        getDeviceSubscription({
          deviceSubscription: antkn,
        })
      );
      localStorage.setItem("deviceType", "web");

      fetchVideoSubscription(appInfo, location?.state?.videoId, type)
    }
  }, []);

  const fetchUpgradeSubscription = async () => {
    try {
      // const upgradeSubResponse = await getUpgradeSubscriptionList(appInfo, location?.state?.subId);
      const upgradeSubResponse = await getVideoSubscription(appInfo, location?.state?.subId, null, 'list');

      if (upgradeSubResponse?.status === 200) {
        setSubscriptions(upgradeSubResponse?.data?.data);
        setSubscriptionHeading(upgradeSubResponse?.data?.heading)
        setSubscriptionConditions(upgradeSubResponse?.data?.conditions)
      }
    } catch (err) {

    }
  }

  const fetchVideoSubscription = async (appInfo2, videoId, type) => {
    try {
      const videoSubRespone = await getVideoSubscription(appInfo2, videoId, type, 'list');

      if (videoSubRespone?.status === 200) {
        setSubscriptions(videoSubRespone?.data?.data);
        setSubscriptionHeading(videoSubRespone?.data?.heading)
        setSubscriptionConditions(videoSubRespone?.data?.conditions)
      }
    } catch (err) { }
  };

  const fetchSubscriptionTv = async () => {
    try {
      const videoSubRespone = await getVideoSubscription(appInfo, null, null, 'list');

      if (videoSubRespone?.status === 200) {
        setSubscriptions(videoSubRespone?.data?.data);
        setSubscriptionHeading(videoSubRespone?.data?.heading)
        setSubscriptionConditions(videoSubRespone?.data?.conditions)
      }
    } catch (err) { }
  }

  const fetchAndroidSubscriptionDetails = async (androidToken, info) => {
    const appInfo2 = {
      projectDetails: info,
      accessToken: accessToken,
    };
    const videoIdAndroid = urlParams.get("vd");
    const eventIdAndroid = urlParams.get("ed");
    const channelIdAndroid = urlParams.get("cn");
    // const videoId = 16700
    const androidSubResponse = await getAndroidSubscriptionDetails(
      appInfo2,
      androidToken
    );
    if (androidSubResponse?.status === 200) {
      localStorage.setItem(
        "userId",
        androidSubResponse?.data?.data[0]?.user_id
      );
      localStorage.setItem("isAndroid", true);
      dispatch(
        getUser({
          user: androidSubResponse?.data?.data[0]?.user_id,
        })
      );
      const antkn = {
        androidToken,
        isAndroid: true,
        userId: androidSubResponse?.data?.data[0]?.user_id,
      };
      localStorage.setItem("deviceType", "android-web");
      dispatch(
        getUserDetails({
          userDetails: androidSubResponse?.data?.data[0],
        })
      );
      dispatch(
        getDeviceSubscription({
          deviceSubscription: antkn,
        })
      );
      if (videoIdAndroid || eventIdAndroid || channelIdAndroid) {
        let typeID;
        let typeName;
        if (videoIdAndroid) {
          typeID = videoIdAndroid;
          typeName = 'video';
        } else if (eventIdAndroid) {
          typeID = eventIdAndroid;
          typeName = 'event';
        } else if (channelIdAndroid) {
          typeID = channelIdAndroid;
          typeName = 'channel';
        }

        fetchVideoSubscription(appInfo2, typeID, typeName);
      } else {
        fetchSubscriptionList(appInfo2);
      }
    }
  };

  const subscriptionSkip = () => {
    dispatch(setSubscriptionPageSkip({ value: false }));
    if (tvCode) {
      navigate(`/tv?code=${tvCode}`, { state: { subscriptionPage: false } })
    } else {
      navigate("/tv", { state: { subscriptionPage: false } })
    }
    dispatch(setTvCode({ value: "" }));
  }

  const fetchSubscriptionList = async (appInfo) => {
    try {
      // const subListResponse = await getSubscriptionList(appInfo);
      const subListResponse = await getVideoSubscription(appInfo, null, null, 'list');

      if (subListResponse?.status === 200) {
        setSubscriptions(subListResponse?.data?.data);
        setSubscriptionHeading(subListResponse?.data?.heading)
        setSubscriptionConditions(subListResponse?.data?.conditions)
      }
    } catch (err) { }
  };
  const handleSubscribe = (data) => {
    localStorage.setItem("selectedSubId", data?.subscription_id);
    localStorage.setItem("selectedAmount", data?.price);
    navigate("/payment", { state: { subscription: data } });
  };

  const handleUpcomingInvoice = async (data) => {
    setShowModal(true);
    const invoiceDetails = await getUpcomingInvoice(data, appInfo)
    if(invoiceDetails?.status == 200) {
      const responseData = invoiceDetails?.data
      if (responseData && responseData?.success) {
        setShowModal(false);
        setShowConfirmWindow(true)
        const paymentDetails = responseData?.data
        let {
          amount_due,
          unused_time,
          plan_amount,
          description
        } = paymentDetails
        let details = {
          due: amount_due,
          discount: unused_time,
          plan: plan_amount,
          description: description
        };
        setInvoiceDetails(details);
        setStripeDeatils(data);
      } else {
        setShowModal(false);
        setShowFailModal(true);
        setInvoiceDetails("No upcoming invoice for this user")
      }
    } else {
      setShowModal(false);
      setShowFailModal(true);
      setInvoiceDetails("No upcoming invoice for this user")
    }
  }

  const handleClick = async (modal, check) => {
    if(modal == "postPayment") {
      if (check == "success") {
        setShowSucessModal(false);
        navigate("/account")
      }
      if(check == "Fail") {
        setShowFailModal(false);
        setShowProcessing(false);
      }
    }
    if(modal == "payment") {
      if(check) {
        setShowConfirmWindow(false);
        setShowModal(true);
        setShowProcessing(true);
        const stripeUpdate = await subscriptionUpdate(stripeDeatils, appInfo)
        if (stripeUpdate?.status == 200) {
          setShowModal(false);
          var stripeData = stripeUpdate?.data;
          if(stripeData?.requires_action) {
            setShowModal(true)
          let accessKey = stripeData?.access_key;
          let clientSecret = stripeData?.client_secret;
          const stripe = await loadStripe(accessKey);
            const stripePayment = await stripe.confirmCardPayment(clientSecret);
            if (stripePayment?.error) {
              setShowModal(false)
              navigate("/failed")
              window.scrollTo({ top: 0, behavior: 'smooth'});
            } else if (stripePayment.paymentIntent && stripePayment.paymentIntent.status === 'succeeded') {
              let authenticated = true;
             const stripeUpdate = await subscriptionUpdate(stripeDeatils, appInfo, authenticated)
             if (stripeUpdate?.status == 200) {
              setShowModal(false)
              setShowSucessModal(true);
             } else {
              setShowModal(false)
              navigate("/failed")
              window.scrollTo({ top: 0, behavior: 'smooth'});
             }
            } else {
              navigate("/failed")
              window.scrollTo({ top: 0, behavior: 'smooth'});
            }
          } else if(stripeData?.success) {
            setShowSucessModal(true);
          } else {
            setShowFailModal(true);
            setInvoiceDetails("No subscription found for this user.")
          }
        } else {
          setShowModal(false);
          setShowFailModal(true);
          setInvoiceDetails("No subscription found for this user.")
        }
      } else {
        setShowConfirmWindow(false);
      }
    }
  }

  return (
    <>
      {showConfirmWindow && !showModal? (
        <>
          <div className="paymentConfirm ontop">
            <div className="paymentConfirmContainer align-center">
              <div>
                <h5>Payment Confirmation</h5>
              <div class="amount-summary">
                <div class="row">
                  <span class="label">{invoiceDetails?.description?.plan_amount}</span>
                  <span class="value">{invoiceDetails?.plan}</span>
                </div>
                <div class="row">
                  <span class="label">{invoiceDetails?.description?.unused_time}</span>
                  <span class="value">{invoiceDetails?.discount}</span>
                </div>
                <hr/>
                <div class="row">
                  <span class="label amount-due">{invoiceDetails?.description?.amount_due}</span>
                  <span class="value amount-due">{invoiceDetails?.due}</span>
                </div>
              </div>
              <div className="button-container df">
                <button
                  onClick={() => {
                    handleClick("payment",true);
                  }}
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    handleClick("payment",false);
                  }}
                >
                  Cancel
                </button>
              </div>  
              </div>
            </div>
          </div>
        </>
      ) :null}
      {showSucessModal ? (<div className="success ontop">
        <div className="successContainer align-center">
          <div className="checkIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="92"
              height="92"
              fill="#fff"
              className="bi bi-check2"
              viewBox="0 0 16 16"
            >
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            </svg>
          </div>
          <h5>Success</h5>
          <p>Your payment has successfully completed</p>
          <button onClick={() => handleClick("postPayment","success")}>OK</button>
        </div>
      </div>) : null}
      {showModal ? (<div className="paymentConfirm ontop">
        <div className="paymentConfirmContainer align-center min-ht">
          {!showProcessing ? <p className="checking">Loading...</p> : <p className="checking">Payment Processing...</p>}

        </div>
      </div>) : null}
      {showFailModal ? (<div className="paymentConfirm ontop">
        <div className="paymentConfirmContainer align-center min-ht">
          <div>
          <p className="checking">{invoiceDetails}</p>
          <div className = "button-container df" >
            <button onClick={() => handleClick("postPayment","Fail")}>OK</button> 
          </div>
          </div>
        </div>
      </div>) : null}
      <div className="subscriptionList">
        <div className="wrapper">
          {showButton && <div className="arrowContainer" onClick={subscriptionSkip}>
            <h3>
              <LeftArrow />
            </h3>
          </div>}
          <h1 className="heading"><Translate textKey={'subscription_title'}/></h1>
          <div className="subscriptionDetails">
            <h2 className="heading">
              {subscriptionHeading}
            </h2>
            <ul className="conditions">
              {
                subscriptionConditions?.map((item, index) => {
                  return (<li><span key={{ index }}>{item}</span></li>)
                })
              }

            </ul>
          </div>
          <div className="skipButton">
            {showButton && <button onClick={subscriptionSkip}>
              <span>
              <Translate textKey={'continue_without_subscription'}/>
                <RightArrow />
              </span>
            </button>}
          </div>
          <div className="pricing">
            <ul>
              {subscriptions?.map((data, key) => (
                <li key={key}>
                  <button
                    onClick={() =>
                      location?.state?.type == "upgrade"
                        ? handleUpcomingInvoice(data)
                        : handleSubscribe(data)
                    }
                  >
                    {location?.state?.type == "upgrade" ? <Translate textKey={'update'}/> : <Translate textKey={'subscribe'}/>}
                  </button>
                  <span className="category">{data?.subscription_name}</span>

                  <span
                    className={`${data?.discounted_price ? "price discount" : "price"} ${data?.trial_period === 1 ? "trialprice" : "price"}`}
                  >
                    {data?.trial_period ? (
                      data?.subscription_text
                    ) : data?.discounted_price ? (
                      <>
                        <span className="priceCut">{data?.symbol} {data?.price}</span>
                        {data?.symbol} {data?.discounted_price}
                      </>
                    ) : (
                      `${data?.subscription_text}`
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
