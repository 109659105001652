import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { getUserDetails } from "../../Redux/UserDetailsSlice/UserDetailsSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getLanguageModal } from "../../Redux/LanguageModal/languageModalSlice";
import Translate from "../Multilanguage/Translate";
import { setRTL } from "../../Redux/RTL/RTLSlice";
import { useTranslation } from "react-i18next"; 
import * as service from "./service";
import { authenticate, authenticate2, getUserSubscription, getLanguageList, updateUserLanguage } from "../../network/service";

export default function Util() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const fetchMenuItems = async ( appInfo, setMenuItems, setMainMenuItems ) => {
    try {
      const menuResponse = await service.getMenuItems(appInfo);
      if (menuResponse?.status === 200) {
        setMenuItems(menuResponse?.data?.data);

        setMainMenuItems(menuResponse?.data?.menu);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };

  const fetchUserSubscriptionDetails = async ( appInfo, setIsSubscribedUser ) => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      if (userSubResponse?.data?.length > 0) {
        setIsSubscribedUser(true);
      } else {
        setIsSubscribedUser(false);
      }
    }
  };

  const searchSubmit = ( value, setInputValue, setSearchActive, previousLocation ) => {
    if (value) {
      setInputValue("");
      setSearchActive(false);
      navigate(`/search?query=${value}`);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
  };

  const searchSubmitBrowseMore = ( value, setInputValue, setSearchActive, setBrowseMoreMediumDevice, previousLocation ) => {
    setSearchActive(true);
    if (value) {
      setInputValue("");
      navigate(`/search?query=${value}`);
      setBrowseMoreMediumDevice(null);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
  };

  const logoutHandler = ( appInfo ) => {
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedSubId");
    localStorage.removeItem("deviceType");
    localStorage.removeItem("selectedAmount");
    localStorage.removeItem("vanityUrl");
    // localStorage.removeItem("selectedLanguageCode");
    // localStorage.removeItem("selectedLanguageName");
    // localStorage.removeItem("selectedLanguageId");
    tokenAuthenticate(appInfo);
    window?.FB && window.FB.logout(function (response) {});
  };

  const tokenAuthenticate = async ( appInfo ) => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getLanguageModal({
          languageModal: {
            selectedLanguageCode: response?.data?.user_language?.short_code,
          }
        }) )
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      dispatch(
        getUserDetails({
          userDetails: response?.data,
        })
      );
      navigate("/home");
      setTimeout(() => {
        dispatch(
          getUser({
            user: null,
          })
        );
      }, 300);
    }
  };

  const languageHandler = ( selectedLanguage, selectedLanguageCode ) => {
    let data = {
      isOpen: true,
      selectedLanguage: selectedLanguage ? selectedLanguage : null,
      selectedLanguageCode:  selectedLanguageCode ?  selectedLanguageCode : localStorage?.selectedLanguageCode
    };
    dispatch(
      getLanguageModal({
        languageModal: data,
      })
    );
  };

  const menuNavigateHandler = ( data, setBrowseHover, setBrowseMoreMediumDevice ) => {
    setBrowseHover(null);
    setBrowseMoreMediumDevice(false);
    if (data?.type === "LINK") {
      if (data?.seo_url) {
        navigate(data?.seo_url);
      } else if (data?.link) {
        window.open(data?.link, "_self");
      }
    } else {
      navigate(`/category/${data?.key}`, { state: { careers: data?.key } });
    }
  };

  const hexToRgb = (hex) => {
    hex = hex.replace("#", "");
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return `${r}, ${g}, ${b}`;
  };

  const handleLanguageClick = (e, setIsLanguageOpen, browseMoreContainerRef) => {
    e.stopPropagation(); 
    setIsLanguageOpen(prev => !prev);
    
    setTimeout(() => {
      if (browseMoreContainerRef.current) {
        browseMoreContainerRef.current.scrollTo({
          top: browseMoreContainerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 100);
  };

  const fetchLanguageOptions = async (appInfo, setLanguageOptions, setSelectedLanguage, i18n, projectInfo, userDetails, user) => {
    try {
      const response = await getLanguageList(appInfo);
      const data = response?.data?.data;
      setLanguageOptions(data);
  
      let selectedLang;
      if (user && userDetails?.language_id) {
        selectedLang = data.find(
          option => option?.language_id === parseInt(userDetails?.language_id) || option?.short_code === userDetails?.short_code
        );
      }
      
      if (!selectedLang) {
        const savedLanguageId = localStorage.getItem("selectedLanguageId");
        const savedLanguageCode = localStorage.getItem("selectedLanguageCode");
        
        selectedLang = data.find(
          option => 
            option?.language_id === parseInt(savedLanguageId) ||
            option?.short_code === savedLanguageCode
        );
      }
  
      if (!selectedLang) {
        selectedLang = data.find(option => option?.short_code === 'en') || data[0];
      }
  
      setSelectedLanguage(selectedLang);
      
      if (selectedLang?.short_code) {
        i18n.changeLanguage(selectedLang.short_code);
        if (!i18n.hasResourceBundle(selectedLang.short_code, 'translation')) {
          i18n.addResourceBundle(
            selectedLang.short_code,
            'translation',
            projectInfo?.projectConfig?.config?.LANGUAGE_KEYWORDS,
            true,
            true
          );
        }
      }
    } catch (error) {
      console.error("Failed to fetch language options:", error);
    }
  };

  const handleLanguageChange = async (language, setSelectedLanguage, setIsLanguageOpen, appInfo, projectInfo, getLanguageModal, getUserDetails, userDetails, languageOptions) => {
    try {
      setSelectedLanguage(language);
      setIsLanguageOpen(false);
  
      const response = await updateUserLanguage(appInfo, language.language_id);
      
      if (response?.status === 200) {
        const authResponse = await authenticate(appInfo);
        
        localStorage.setItem('selectedLanguageCode', language.short_code);
        localStorage.setItem('selectedLanguageId', language.language_id.toString());
        localStorage.setItem('selectedLanguageName', language.language_name);
  
        const hasResources = i18n.hasResourceBundle(language.short_code, 'translation');
      
        if (!hasResources) {
          try {
            i18n.addResourceBundle(
              language.short_code,
              'translation',
              projectInfo?.projectConfig?.config?.LANGUAGE_KEYWORDS,
              true,
              false  
            );
          } catch (error) {
            console.warn('Resource bundle already exists:', error);
          }
        }
  
        await i18n.changeLanguage(language.short_code);
  
        dispatch(
          getLanguageModal({
            languageModal: {
              selectedLanguageCode: language.short_code
            }
          })
        );
  
        dispatch(
          getUserDetails({
            userDetails: {
              ...userDetails,
              language_id: language.language_id,
              short_code: language.short_code
            }
          })
        );
  
        dispatch(setRTL(language));
  
        toast.success(response?.data?.message, {
          position: "top-center"
        });
      }
    } catch (error) {
      console.error("Failed to update language:", error);
      toast.error(<Translate textKey={'failed_to_update_language'}/>, {
        position: "top-center"
      });
      
      const savedLang = languageOptions.find(
        opt => opt.language_id === parseInt(localStorage.getItem("selectedLanguageId"))
      );
      if (savedLang) {
        setSelectedLanguage(savedLang);
        i18n.changeLanguage(savedLang.short_code).catch(console.error);
      }
    }
    window.location.reload();
  };

  return {
    fetchMenuItems,
    fetchUserSubscriptionDetails,
    searchSubmit,
    searchSubmitBrowseMore,
    logoutHandler,
    tokenAuthenticate,
    languageHandler,
    menuNavigateHandler,
    hexToRgb,
    handleLanguageClick,
    fetchLanguageOptions,
    handleLanguageChange,
  };
}
