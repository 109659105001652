import { ChannelBox, ChannelLogo } from "planby";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSelectedChannel } from "../../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";

export const ChannelItem = ({ channel }) => {
  const { position, logo, uuid, title } = channel;
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const selectedChannelHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(
      getSelectedChannel({
        selectedChannel: { channel_id: uuid }
      })
    )
    dispatch(
      getSelectedSchedule({
        selectedSchedule: { channel_id: uuid }
      })
    )
    navigate("/live-channels?c=" + uuid, { state: { channel_id: uuid, homelive: true } });
  }
  return (
    <ChannelBox {...position} onClick={() => selectedChannelHandler()}>
      {/* Overwrite styles by add eg. style={{ maxHeight: 52, maxWidth: 52,... }} */}
      {/* Or stay with default styles */}
      <div onClick={()=>selectedChannelHandler()}>
      <ChannelLogo
        src={logo ? logo : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE}
        alt="Logo"
        title={title}
        style={{ maxHeight: 52, maxWidth: 52, cursor: 'pointer' }}
      />
      </div>
    </ChannelBox>
  );
};
