import React from "react";
import { imageUrlCheck } from "../../../utils/utils";
import { useSelector } from "react-redux";
import Translate from "../../../Components/Multilanguage/Translate";
const AwardsAndFestivals = ({ showDetails }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  return (
    <div className="awardsAndFestivals" >
      <h1 className="heading"><Translate textKey={'awards_and_festivals'}/></h1>
      <ul className="lists">
        {showDetails?.awards?.map((item, index) => (
          <li className="list" key={index}>
            <div className="imageContainer">
              {item?.thumbnail && (
                <div className="imageContainer">
                  {imageUrlCheck(item?.thumbnail) === true ? (
                    <img src={item?.thumbnail} alt="Image" />
                  ) : (
                    <img
                      src={`${
                        process.env.REACT_APP_IMAGE_URL + item?.thumbnail
                      }`}
                      alt="Image"
                    />
                  )}
                </div>
              )}
            </div>
            <h1 className="title">{item?.award_name}</h1>
            <div className="descriptionAndYear">
              <span className="year">{item?.year}</span>
              <p className="description">{item?.award_description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AwardsAndFestivals;
