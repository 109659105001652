import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../../assets/Icons/arrownext.svg";
import ShowCard from "../../../../Components/ShowCard/GaiaCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { rowItemCount } from "../../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import MagazineCard from "../../../../Components/MagazineCard/MagazineCard";
import ShortsCard from "../../../Shorts/ShortsCard";
import { navigateToCategory } from "../../../../utils/utils";
import Translate from "../../../../Components/Multilanguage/Translate";

const GaiaCategories = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState("0");
  const [rowItemsClass,setRowItemsClass] = useState("");
  const [rowItemCountNumber, setRowItemCountNumber] = useState(5);
  const windowSize = useWindowWidth();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const thumbnailOrientation = data?.thumbnail_orientation ? data?.thumbnail_orientation : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const count = data?.shows?.length;
  let displayedData = data?.shows;
  const isSingleCard = data?.shows?.length === 1;

  const onSlideChange = (swiper) => {
    const lastSlideIndex = swiper.activeIndex + (swiper.params.slidesPerView - 1);

    if (lastSlideIndex >= swiper.slides.length) {
      setActiveIndex(swiper.slides.length - 1);
    } else {
      setActiveIndex(lastSlideIndex);
    }
  };

  const handleSwiperInit = (swiperInstance) => {
    swiperRef.current = swiperInstance;
    onSlideChange(swiperInstance); 
  };
  // useEffect(() => {
  //   if(windowSize>980){
  //     if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
  //       setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
  //     }else {
  //       setRowItemsClass("rowCount5");
  //     }
  //   } else {
  //     setRowItemsClass("rowCount5");
  //   }
  //   setRowItemCountNumber(parseInt(rowItemsClass.replace(/\D/g, ''), 10));
  // }, [projectInfo,windowSize]);
  useEffect(() => {
    if (windowSize > 1200){
      if (data?.type === 'SHORTS'){
        setRowItemCountNumber(5);
      }
    }
    if (windowSize > 980) {
      const newClass = projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
        ? rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT)
        : "rowCount5";
      setRowItemsClass(newClass);
      setRowItemCountNumber(parseInt(newClass.replace(/\D/g, ''), 10));
      if (data?.type === 'FASTCHANNELS'){
        setRowItemCountNumber(9);
      }
      if (data?.type === 'SHORTS'){
        setRowItemCountNumber(4);
      }
    } else {
      setRowItemsClass("rowCount5");
      if (windowSize > 767){
        setRowItemCountNumber(3);
      } else if (windowSize > 479){
        setRowItemCountNumber(2);
      } else if (windowSize > 319){
        setRowItemCountNumber(1);
      }
      if (data?.type === 'FASTCHANNELS'){
        if (windowSize > 767){
          setRowItemCountNumber(4);
        } else if (windowSize > 319){
          setRowItemCountNumber(3);
        }
      }
    }
  }, [projectInfo, windowSize]);

  if (isRTL && count > rowItemCountNumber) {
    displayedData = [...data?.shows].reverse();
  }

  return (
    <div
      className={`gaiaCategoriesContainer ${
        isRTL ? (
        count > rowItemCountNumber
          ? "ltr"
          : windowSize < 980
          ? "rtl"
          : "rtl"
      ) : (
        ""
      )}`}
    >
      <div className="header-row">
        <div className="header">
          <h1>{data?.category_name}</h1>
        </div>
        <div className="view-all"
          onClick={() => navigateToCategory(navigate, data, thumbnailOrientation)}
        >
          <Translate textKey={'view_all'} />
          <ArrowIcon className="arrow-icon" />
        </div>
      </div>
      <div className="wrapper">
        <Swiper
          key={isRTL ? (count > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl" ) : ""}
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={5}
          slidesPerGroup={5}
          navigation={true}
          onSlideChange={onSlideChange}
          watchSlidesProgress
          loop={false}
          onSwiper={handleSwiperInit} 
          initialSlide={(isRTL && count > rowItemCountNumber) ? count - 1 : 0}
          className={thumbnailOrientation===`PORTRAIT`?`items portrait ${rowItemsClass}`:`items landscape ${rowItemsClass}`}
          breakpoints={{
            320: {
              slidesPerView: data?.type == 'FASTCHANNELS' ? 3 : 1,
              slidesPerGroup: data?.type == 'FASTCHANNELS' ? 3 : 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: data?.type == 'FASTCHANNELS' ? 3 : 2,
              slidesPerGroup: data?.type == 'FASTCHANNELS' ? 3 : 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: data?.type == 'FASTCHANNELS' ? 4 : 3,
              slidesPerGroup: data?.type == 'FASTCHANNELS' ? 4 : 3,
              spaceBetween: 20,
            },
            980: {
              slidesPerView: data?.type === 'SHORTS' ? 4 : data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : 4,
              slidesPerGroup: data?.type === 'SHORTS' ? 4 : data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) : 4,
              spaceBetween: data?.type === 'SHORTS' ? 10 : 20,
            },
            1200: {
              slidesPerView: data?.type === 'SHORTS' ? 5 : data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : 5,
              slidesPerGroup: data?.type === 'SHORTS' ? 5 : data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) : 5,
              spaceBetween: data?.type === 'SHORTS' ? 10 : 20,
            },
          }}
        >
          {displayedData?.map((item, index) => (
            <SwiperSlide key={index} className={`${isSingleCard ? "" : index === activeIndex ? "translate-last" : ""} ${data?.type === "SHORTS" ? "no-transform" : ""}`}>
              <div className={data?.type === "SHORTS" ? "shortsCardContainer" : "show"}>
                {data?.type === "MAGAZINES" ? (
                  <MagazineCard
                    data={item}
                    url={data.url}
                    metaData={true}
                    cardHover={true}
                  />
                ) : data?.type === "SHORTS" ? (
                  <ShortsCard
                    data={item}
                    season={false}
                    metaData={true}
                    type={data?.type}
                    vanityUrl={item?.vanity_url}
                  />
                ) : (
                  <ShowCard
                    data={item}
                    season={false}
                    metaData={true}
                    type={data?.type}
                    thumbnailOrientation={thumbnailOrientation}
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="view-all-mobile"
        onClick={() => navigateToCategory(navigate, data, thumbnailOrientation)}
      >
        <Translate textKey={'view_all'} />
        <ArrowIcon className="arrow-icon" />
      </div>
    </div>
  );
};

export default GaiaCategories;
