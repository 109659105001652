import React, { useEffect, useState } from "react";
import { ReactComponent as PlayIcon } from "../../../assets/Icons/play.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { ReactComponent as PremiumIcon } from "../../../assets/Icons/premium.svg";

import { EffectFade, Thumbs, Autoplay } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { imageUrlCheck, getDeviceType } from "../../../utils/utils";
import Translate from "../../../Components/Multilanguage/Translate";
const Featured = ({ data }) => {
  const [activeThumb, setActiveThumb] = useState();
  const navigate = useNavigate();
  const deviceType = getDeviceType();

  const showClickHandler = (item) => {
    if (item?.show_id) {
      navigate(`/show-details/${item?.vanity_url ? item?.vanity_url : item?.show_id}`, {
        state: { showId: item?.show_id },
      })
    } else if (item?.event_id) {
      navigate(`/event/${item?.vanity_url ? item?.vanity_url : item?.event_id}`, {
        state: { showId: item?.event_id },
      })
    }
  }
  return (
    <div className="featured">
      <Swiper
        onSwiper={setActiveThumb}
        slidesPerView={1}
        watchSlidesProgress
        effect={"fade"}
        spaceBetween={0}
        className="mySwiper"
        modules={[EffectFade, Thumbs]}
        allowTouchMove={false}
      // allowSlideNext={false}
      // allowSlidePrev={false}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="bgImageContainer">
              {
                imageUrlCheck(item?.banner) ? (

                  <img src={deviceType == 'mobile' ? item?.banner_portrait : item?.banner} alt="BgImage" />

                ) : (
                  <img src={`${process.env.REACT_APP_IMAGE_URL}${deviceType == 'mobile' ? item?.banner_portrait : item?.banner}`} alt="BgImage" />

                )
              }
            </div>
            <div className="contents">
              <h1 className="title">{item?.show_name}</h1>
              <span className="line"></span>
              <div className="containers">
                <div className="left">
                  <div className="activeThumb">
                    <div
                      className="imageContainer"
                      onClick={() => showClickHandler(item)}
                    >
                      {
                        imageUrlCheck(item?.banner) ? (

                          <img src={item?.banner_portrait} alt="Picture" />
                        ) : (
                          <img src={`${process.env.REACT_APP_IMAGE_URL}${item?.banner_portrait}`} alt="Picture" />

                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="metaData">
                    <div className="datas">
                      <span className="year">{item?.year}</span>
                      <span className={`duration ${item?.year && 'showDot'} ${/^[A-Za-z0-9\s]+$/.test(item?.duration_text) ? "ltr" : ""}`}>{item?.duration_text}</span>
                      <span className="certificate">{item?.rating}</span>
                      <div className="genres">
                        {/* {
                          item?.category_names?.map((item,index)=>(
                            <span key={index}>{item}</span>

                          ))
                        } */}
                      </div>
                    </div>

                    <div
                      className="buttonContainer"
                      onClick={() =>
                        showClickHandler(item)
                      }
                    >
                      <PlayIcon />
                      <span><Translate textKey={'watch_now'}/></span>
                      {item?.is_free_video ? (
                        <span className="free"><Translate textKey={'free'}/></span>
                      ) : (
                        <PremiumIcon />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottomGradient" />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="gallerySection">
        <Swiper
          slidesPerView={3}
          watchSlidesProgress={true}
          spaceBetween={15}
          loop={true}
          className="mySwiper"
          centeredSlides={true}
          // allowSlideNext={false}
          // allowSlidePrev={false}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Thumbs, Autoplay]}
          thumbs={{ swiper: activeThumb }}
          slideToClickedSlide={true}

        >
          {data?.shows?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="imageContainer">
                {
                  imageUrlCheck(item?.banner) ? (

                    <img src={item?.banner_portrait} alt="Gallery imag" />
                  ) : (
                    <img src={`${process.env.REACT_APP_IMAGE_URL}${item?.banner_portrait}`} alt="Gallery imag" />

                  )
                }
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Featured;
