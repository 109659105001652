import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertTimeToLocal, onVideoPlayFunction } from "../../../utils/utils";
import VideoJS from "../../../Components/VideoJsPlayer/VideoJS";
import Translate from "../../../Components/Multilanguage/Translate";
import { Navigation } from 'swiper';
import { ReactComponent as RightArrow } from "../../../assets/Icons/arrownext.svg";
import { getSelectedSchedule } from "../../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import { getLiveScheduleData } from "../../../Redux/LiveScheduleData/liveScheduleDataSlice";
import { getSelectedChannel } from "../../../Redux/SelectedChannel/selectedChannelSlice";

let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;

const Live = ({ data }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);

  const sessionId = useSelector((state) => state?.sessionId?.value);
  const liveScheduleData = useSelector((state) => state?.liveSchedule?.value);
  const [livePlayer, setLivePlayer] = useState("");
  const [liveDetails, setLiveDetails] = useState();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken,
    sessionId,
  };
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {

    if (data) {
      setLiveDetails(data?.shows[0]);
    }
  }, [data]);


  useEffect(() => {
    if (liveDetails) {
      const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: liveDetails?.live_link,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };
      setLivePlayer(
        <VideoJS onReady={handlePlayerReady} options={videoJsOptions} />
      );
    }
  }, [liveDetails]);
  useEffect(() => {
    getLiveChannels()
    return () => {
      dispatch(
        getLiveScheduleData({
          liveSchedule: {},
        })
      );
      dispatch(
        getSelectedSchedule({
          selectedSchedule: {}
        })
      )
    };
  }, [])

  const getLiveChannels = async () => {
    dispatch(
      getLiveScheduleData({
        liveSchedule: data,
      })
    );
    dispatch(
      getSelectedSchedule({
        selectedSchedule: data?.shows[0]
      })
    )
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      updateLiveAnalytics(
        liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateLiveAnalytics(
          liveDetails?.now_playing,
          event,
          player.currentTime()
        );
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      updateLiveAnalytics(
        liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });
    player.on("ended", () => {
      let event = "POP05";
      updateLiveAnalytics(
        liveDetails?.now_playing,
        event,
        player.currentTime()
      );
    });


    player.on("dispose", () => {
      videoStarted = false;
      // player.pause();
    });
  };

  const updateLiveAnalytics = async (nowPlaying, event, time) => {
    const isLive = "1";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      nowPlaying,
      event,
      time,
      isLive
    );
  };

  return (
    <div className="liveSection">
      <div className="wrapper">
        <h1 className="heading">{data?.category_name}</h1>
        <div className="videoContainer">
          {livePlayer}
          <div className="bottom">
            <div className="details">
              <div className="left">
                <h1 className="heading"><Translate textKey={'now_playing'} /></h1>
                <h1 className="title">
                  {liveDetails?.now_playing?.video_title}
                </h1>
                <span className="time">
                  {convertTimeToLocal(liveDetails?.now_playing?.start_time)
                    .toString()
                    .toLowerCase()}
                </span>
                <p className="description">
                  {liveDetails?.now_playing?.video_description}
                </p>
              </div>
              <div className="right">
                <h1 className="heading"><Translate textKey={'up_next'} /></h1>
                <h1 className="title">{liveDetails?.up_next?.video_title}</h1>
                <span className="time">
                  {convertTimeToLocal(liveDetails?.up_next?.start_time)
                    // .toString()
                    // .toLowerCase()
                  }
                </span>

                <p className="description">
                  {liveDetails?.up_next?.video_description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Live;
