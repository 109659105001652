import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

export const useTranslateText = () => {
  const { t } = useTranslation();
  const languageModal = useSelector((state) => state?.languageModal?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  return (textKey) => {
    let translatedText = languageModal?.selectedLanguageCode ? t(`${textKey}.${languageModal?.selectedLanguageCode}`).replace('APP_NAME', projectInfo?.projectConfig?.config?.TAB_TITLE) : '';
    if (translatedText.includes('Sign-In')) {
      translatedText = translatedText.split('Sign-In').join('Sign\u2011In');
    }
    if (translatedText.includes('Sign In')) {
      translatedText = translatedText.split('Sign In').join('Sign\u2011In');
    }

    return translatedText;
  };
};

const Translate = ({ textKey }) => {
  const translateText = useTranslateText();

  return <>{translateText(textKey)}</>;
};

export default Translate;
