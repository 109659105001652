import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import Translate, {useTranslateText} from "../Multilanguage/Translate";
import { useSelector } from "react-redux";

const DownloadAPKModal = ({ setDownloadModal }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const translateText = useTranslateText();

  const renderContentWithBreaks = (textKey) => {
    const content = translateText(textKey);
    return content?.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < content?.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  
  return (
    <div className="downloadContainer">
      <div className="overlayModal"></div>

      <div className="contents">
      <div
        className="closeIconContainer"
        onClick={() => setDownloadModal(false)}
      >
        <CloseIcon />
      </div>
            <h1><Translate textKey={'warning'} /></h1>
            <h2><Translate textKey={'apk_download_heading'} /></h2>
            <p>{renderContentWithBreaks('apk_download_content')}</p>
        <a 
          className="download-button"
          href ={projectInfo?.projectConfig?.config?.ANDROID_APK_LINK}
          onClick={() => setDownloadModal(false)}
        >
          <Translate textKey={'download'} />
        </a>
      </div>
    </div>
  );
};

export default DownloadAPKModal;