import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as RightArrow } from "../../../assets/Icons/rightArrow.svg";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import { useSelector } from "react-redux";
import MagazineCard from "../../../Components/MagazineCard/MagazineCard";
import ShortsCard from "../../../Screens/Shorts/ShortsCard";
import Translate from "../../../Components/Multilanguage/Translate";
import { navigateToCategory, rowItemCount, translateText } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const Categories = ({ data }) => {
  const { t } = useTranslation();
  const languageModal = useSelector((state) => state?.languageModal?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const windowSize = useWindowWidth();
  const [rowItemCountNumber, setRowItemCountNumber] = useState(5);
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;
  const count = data?.shows?.length;
  let displayedData = data?.shows;

    useEffect(() => {
      if (windowSize > 1200){
        if (data?.type === 'SHORTS'){
          setRowItemCountNumber(5);
        }
      }
      if (windowSize > 980) {
      const newClass = projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
        ? rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT)
        : "rowCount5";
      setRowItemCountNumber(parseInt(newClass.replace(/\D/g, ''), 10));
          if (data?.type === 'FASTCHANNELS'){
            setRowItemCountNumber(9);
          }
          if (data?.type === 'SHORTS'){
            setRowItemCountNumber(4);
          }
        } else {
          if (windowSize > 767){
            setRowItemCountNumber(3);
          } else if (windowSize > 479){
            setRowItemCountNumber(2);
          } else if (windowSize > 319){
            setRowItemCountNumber(1);
          }
          if (data?.type === 'FASTCHANNELS'){
            if (windowSize > 767){
              setRowItemCountNumber(4);
            } else if (windowSize > 319){
              setRowItemCountNumber(3);
            }
          }
        }
      if (data?.type === 'FASTCHANNELS'){
        if (windowSize > 767){
          setRowItemCountNumber(4);
        } else if (windowSize > 319){
          setRowItemCountNumber(3);
        }
      }
    }, [projectInfo, windowSize]);

     
  if (isRTL && count > rowItemCountNumber) {
    displayedData = [...data?.shows].reverse();
  }

  const navigate = useNavigate();
  const thumbnailOrientation = data?.thumbnail_orientation ? data?.thumbnail_orientation : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  return (
    <div className={`categoriesContainer ${isRTL ? (count > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl" ) : ""}`}>
      <div className="wrapper">
        <div
          className="categoryNameContainer"
          onClick={() => navigateToCategory(navigate, data, thumbnailOrientation)}
        >
          <h1>{data?.category_name}</h1>
          {template == 'TEMPLATE_9' ? <span> {translateText(languageModal, t, 'show_all')}</span> : <RightArrow />}
        </div>
        <div className="itemsContainer">
          <Swiper
            key={isRTL ? (count > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl" ) : ""}
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={6}
            navigation={true}
            watchSlidesProgress
            loop={false}
            initialSlide={(isRTL && count > rowItemCountNumber) ? count - 1 : 0}
            className={thumbnailOrientation === "PORTRAIT" ? "portrait" : "landscape"}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 2 : data?.type == 'FASTCHANNELS' ? 3 : 1,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 3 : data?.type == 'FASTCHANNELS' ? 3 : 2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: thumbnailOrientation === "PORTRAIT" ? 4 : data?.type == 'FASTCHANNELS' ? 4 : 2,
                spaceBetween: 10,
              },
              980: {
                  slidesPerView: data?.type === 'SHORTS' ? 4 : data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : (thumbnailOrientation === "PORTRAIT" ? 7 : 4),
                  spaceBetween: 10,
                },
              1200: {
                  slidesPerView: data?.type === 'SHORTS' ? 5 : data?.type == 'FASTCHANNELS' ? 9 : projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : (thumbnailOrientation === "PORTRAIT" ? 7 : 4),
                  spaceBetween: data?.type === 'SHORTS' ? 10 : 20,
                },
            }}
          >
            {displayedData?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className={data?.type === "SHORTS" ? "shortsCardContainer" : "show"}>

                  {
                    data?.type === "MAGAZINES" ? (
                      <MagazineCard
                        data={item}
                        url={data.url}
                        metaData={true}
                        cardHover={true}
                      />
                    ) : data?.type === "SHORTS" ? (
                      <ShortsCard
                        data={item}
                        season={false}
                        metaData={true}
                        type={data?.type}
                        vanityUrl={item?.vanity_url}
                      />
                    ) : (
                      <ShowCard
                        data={item}
                        season={false}
                        metaData={true}
                        type={data?.type}
                        thumbnailOrientation={thumbnailOrientation}
                      />
                    )
                  }

                </div>

              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Categories;
