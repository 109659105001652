import React from 'react'
import { useNavigate } from 'react-router-dom'
import Translate from "../Multilanguage/Translate"
const NewsCard = ({data}) => {
  const navigate = useNavigate()

    let dates=new Date(data?.schedule_date)
    let month=dates.toLocaleString('en-us', { month: 'short' })
    let year = dates.getFullYear()
    let day = dates.getDate().toString().length == 1 ? '0' + dates.getDate() : dates.getDate()
    let isoFormat = day + " " + month+ " " + year
    let newDate=isoFormat

    const readMoreHandler = () => {
       navigate(`/news-info/${data?.vanity_url ? data?.vanity_url : data?.news_id }`,{state:{newsId:data?.news_id}})
    }
  return (
    <div className='newsCard'>
        <div className="left">
            <div className="imageContainer">
                <img src={data?.logo_portrait} alt="Thumbnail" />
            </div>
        </div>
        <div className="right">
            <h1 className="title">{data?.title}</h1>
            <p>{data?.description}</p>
            <div className="dateSection">
                {/* <span className="icon">
                    <img src={require("../../images/icon-calendartime.png")} alt="Icon-Calender" />
                </span> */}
                <span className="date">{newDate}</span>
            </div>
            <div className="buttonContainer" onClick={readMoreHandler}>
                <div className="background"></div>
                <button className='bg-button'><Translate textKey={'read_more'}/></button>
            </div>
        </div>
    </div>
  )
}

export default NewsCard
