import React from "react";
import { useState, Fragment } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "country-data-list";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CurrentPasswordModal from "../../../Components/Modals/CurrentPasswordModal";
import CancelSubscriptionModal from "../../../Components/Modals/CancelSubscriptionModal";
import LogoutAllModal from "../../../Components/Modals/LogoutAllModal";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import Loading from "../../../Components/Loading/Loading";
import Translate from "../../../Components/Multilanguage/Translate";
const AccountSettings = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const selectedLanguage = useSelector((state) => state?.languageModal?.value?.selectedLanguage);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const [accountDetails, setAccountDetails] = useState({});
  const [countryListOption, setCountryListOption] = useState([]);
  const [values, setValues] = useState({});
  const [PasswordModal, setPasswordModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [cancelSubModal, setCancelSubModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [profileWill, setProfileWill] = useState()
  const [loading, setLoading] = useState(true)
  const [changedValue, setChangedValue] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentCountry, setCurrentCountry] = useState({})
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;
  useEffect(() => {
    window.scroll(0, 0);
    fetchAccountDetails();
    // setCountryListOption(
    //   countries?.all?.map((item, index) => {
    //     const { alpha2, name } = item;
    //     return (
    //       <option key={index} value={alpha2}>
    //         {name}
    //       </option>
    //     );
    //   })
    // );
  }, []);
  useEffect(() => {
    if (accountDetails) {
      setCountryListOption(
        countries?.all?.map((item, index) => {
          const { alpha2, name } = item;
          if (item?.alpha2 !== accountDetails?.country_code) {
            return (
              <option key={index} value={alpha2}>
                {name}
              </option>
            );
          } else {
            setCurrentCountry({ name, value: alpha2 })
          }
        })
      );
    }
  }, [accountDetails])

  const fetchAccountDetails = async (isUpdate) => {
    setLoading(true)
    try {

      const accountDetailsResponse = await service.getAccountDetails(appInfo);
      if (accountDetailsResponse?.status === 200) {
        setValues({
          ...values,
          email: accountDetailsResponse?.data?.data?.user_email,
          firstName: accountDetailsResponse?.data?.data?.first_name,
          lastName: accountDetailsResponse?.data?.data?.last_name,
          phone: accountDetailsResponse?.data?.data?.phone,
          country: accountDetailsResponse?.data?.data?.country_code
        });
        setAccountDetails(accountDetailsResponse?.data?.data);
        if (isUpdate) {

          dispatch(
            getUserDetails({
              userDetails: accountDetailsResponse?.data?.data,
            })
          );
        }
        setLoading(false)
      }
    } catch (err) {

    }
  };
  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
    setChangedValue({
      ...changedValue,
      [name]: value
    })
  };

  const handleUpdateItem = (item) => {
    const { name, value } = item?.target;
    setValues({
      ...values,
      [name]: value,
    });
  }


  const validation = () => {
    let validationStatus = true;
    let errors = {};
    let nameRegex = /^[a-zA-Z ]{3,30}$/;
    const { firstName, lastName, country } = values;

    if (!firstName) {
      errors.firstName = <Translate textKey={'first_name_required'}/>
      validationStatus = false;
    } else if (!firstName?.match(nameRegex)) {
      errors.firstName =
      <Translate textKey={'name_validation_message'}/>
      validationStatus = false;
    }

    if (!lastName) {
      errors.lastName = <Translate textKey={'last_name_required'}/>
      validationStatus = false;
    } else if (!lastName?.match(nameRegex)) {
      errors.lastName =
      <Translate textKey={'name_validation_message'}/>
      validationStatus = false;
    }

    if (!country) {
      errors.country = <Translate textKey={'country_required'}/>
      validationStatus = false;
    } else if (country === <Translate textKey={'select_country'}/>) {
      errors.country = <Translate textKey={'country_required'}/>
      validationStatus = false;
    }
    setErrors(errors);
    return validationStatus;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validation() === true) {
      setPasswordModal(true);
    } else {
      toast.error(<Translate textKey={'check_the_fields'}/>, {
        position: "bottom-center",
      });
    }
  };
  const updateAccountDetails = async () => {
    try {
      const response = await service.updateAccountdetails(appInfo, changedValue);
      if (response?.data?.success === true) {
        toast.success(<Translate textKey={'account_updated_message'}/>, {
          position: "bottom-center",
        });
        setPasswordModal(false);
        fetchAccountDetails(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const handleFileChange = (e) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e?.target?.files[0]?.name)) {
      alert(<Translate textKey={'invalid_extension_message'}/>)
    } else {

      const name = e?.target?.files[0]?.name;
      const files = e?.target?.files;
      setValues({
        ...values,
        file: files[0],
      });
      setChangedValue({
        ...changedValue,
        file: files[0]
      });
      setProfileWill(URL.createObjectURL(files[0]))
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }
  return (
    <div className={`accountSettings ${isRTL ? "rtl" : ""}`}>
      {PasswordModal && (
        <CurrentPasswordModal
          fetchAccountDetails={fetchAccountDetails}
          updateAccountDetails={updateAccountDetails}
          values={values}
          handleUpdate={handleUpdate}
          handleUpdateItem={handleUpdateItem}
          handleChange={handleChange}
          setValues={setValues}
          setChangedValue={setChangedValue}
          setErrors={setErrors}
          errors={errors}
          setPasswordModal={setPasswordModal}
        />
      )}
      {cancelSubModal && (
        <CancelSubscriptionModal
          fetchAccountDetails={fetchAccountDetails}
          setCancelSubModal={setCancelSubModal}
          subId={accountDetails?.sub_id}
        />
      )}
      {logoutModal && (
        <LogoutAllModal
          setLogoutModal={setLogoutModal}
          modalType={{
            type: "logoutAll",
            heading: <Translate textKey={'logout_all_text'}/>,
          }}
        />
      )}

      <div className="wrapper">
        <div className="head">
          <h1 className="heading"><Translate textKey={'account_settings'}/></h1>
        </div>

        <div className="contents">
          <div className="left">
            <div className="profileImageContainer">
              {
                profileWill ? (
                  <img src={profileWill} alt="Profile" />

                ) : accountDetails?.user_image ? (
                  <img src={accountDetails?.user_image} alt="Profile" />

                ) : (
                  <img src={require("../../../assets/Images/user_avatar.png")} alt="Avatar" />
                )
              }
              <input type="file" id='file' accept="image/*" onChange={(e) => handleFileChange(e)} />

              <label htmlFor="file"><Translate textKey={'change_picture'}/></label>
            </div>
          </div>
          <div className="right">
            <div className="formSection">
              <form className={`${template === 'TEMPLATE_9' ? 'template-9-font' : template === 'TEMPLATE_10' ? 'template-10-font' : ''}`}>
                <div className="half">
                  <div className="fname">
                    <label htmlFor="#fname"><Translate textKey={'first_name'}/></label>
                    <input
                      name="firstName"
                      value={values?.firstName || ""}
                      type="text"
                      id="fname"
                      onChange={(item) => handleUpdate(item)}
                    />
                    {errors?.firstName && (
                      <span className="error text-danger">
                        {errors?.firstName}
                      </span>
                    )}
                  </div>
                  <div className="lname">
                    <label htmlFor="#lname"><Translate textKey={'last_name'}/></label>
                    <input
                      name="lastName"
                      type="text"
                      id="lname"
                      value={values?.lastName || ""}
                      onChange={(item) => handleUpdate(item)}
                    />
                    {errors?.lastName && (
                      <span className="error text-danger">
                        {errors?.lastName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="half">
                  <div className="email">
                    <label htmlFor="email"><Translate textKey={'email'}/></label>
                    <input
                      name="email"
                      value={values?.email || ""}
                      disabled
                      type="email"
                      id="email"
                      onChange={(item) => handleUpdate(item)}
                    />
                  </div>

                  <div className="country">
                    <label htmlFor="text"><Translate textKey={'country'}/></label>
                    <select
                      name="country"
                      id="country"
                      onChange={(item) => handleUpdate(item)}
                    >
                      <option value={currentCountry?.value} >{currentCountry?.name}</option>
                      {countryListOption}
                    </select>
                    {errors?.country && (
                      <span className="error text-danger">
                        {errors?.country}
                      </span>
                    )}
                  </div>
                </div>
                <div className="full">
                  <div className="phone">
                    <label htmlFor="phone"><Translate textKey={'phone'}/></label>
                    <input
                      name="phone"
                      type="tel"
                      id="phone"
                      value={values?.phone || ""}
                      onChange={(item) => handleUpdate(item)}
                    />
                  </div>
                  {accountDetails?.password_exists === true ? (
                    <Link to="/change-password">
                      <span className="cpassword"><Translate textKey={'change_password'}/></span>
                    </Link>
                  ) : (
                    <Link to="/set-password" state={accountDetails}>
                      <span className="cpassword"><Translate textKey={'set_password'}/></span>
                    </Link>
                  )}
                </div>

                <div
                  className="buttonContainer"
                  onClick={(e) => onSubmitHandler(e)}
                >
                  <div className="background"></div>
                  <button type="submit" className="bg-button">
                  <Translate textKey={'save_changes'}/>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {accountDetails?.sub_id && (
          <div className="planDetails">
            <div className="leftContainer">
              <h4 className="heading"><Translate textKey={'plan_details_caps'}/></h4>
            </div>
            <div className="rightContainer">
              <div className="left">
                {accountDetails?.cancel_status === false ? (
                  <>
                    <h2 className="planName">
                      {accountDetails?.subscription_name}
                    </h2>
                    {accountDetails?.subscription_type === 3 || accountDetails?.subscription_type === 4 ?
                      (<div
                        className="buttonContainer"
                        onClick={() => setCancelSubModal(true)}
                      >
                        <div className="background"></div>

                        <button><Translate textKey={'cancel_membership'}/></button>
                      </div>) : null
                    }
                  </>
                ) : (
                  <p><Translate textKey={'subscription_cancelled'}/></p>
                )}
              </div>

              <div className="right">
                <Link to="billing-activity">
                  <span><Translate textKey={'billing_details_title'}/></span>
                </Link>
                {accountDetails?.cancel_status === true ? (
                  <Link to="/subscription">
                    <span><Translate textKey={'subscribe'}/></span>
                  </Link>
                ) : projectInfo?.projectConfig?.config?.CHANGE_PLAN_REQUIRED == "true" && accountDetails?.change_plan_required === true && (accountDetails?.subscription_type === 3 || accountDetails?.subscription_type === 4) &&
                <>
                  {projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ? <Link
                    to="/checkout"
                    state={{ type: "upgrade", subId: accountDetails?.sub_id }}
                  >
                    <span><Translate textKey={'change_plan_caps'}/></span>
                  </Link> :
                    <Link
                      to="/subscription"
                      state={{ type: "upgrade", subId: accountDetails?.sub_id }}
                    >
                      <span><Translate textKey={'change_plan_caps'}/></span>
                    </Link>}
                </>
                }
              </div>
            </div>
          </div>
        )}
        <div className="settings">
          <div className="leftContainer">
            <h4 className="heading"><Translate textKey={'settings_caps'}/></h4>
          </div>
          <div className="rightContainer">
            <ul>
              <Link to="streaming-activity">
                <li>
                  <span><Translate textKey={'recent_device_streaming_activity'}/></span>
                </li>
              </Link>
              <li onClick={() => setLogoutModal(true)}>
                <span><Translate textKey={'sign_out_from_all_devices'}/></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
