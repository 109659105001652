import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/Icons/arrownext.svg";
import ShowCard from "../../Components/ShowCard/GaiaCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { rowItemCount } from "../../utils/utils";
import MagazineCard from "../../Components/MagazineCard/MagazineCard";
import ShortsCard from "../../Screens/Shorts/ShortsCard";
import { navigateToCategory } from "../../utils/utils";
import { useWindowWidth } from "@react-hook/window-size";
import Translate from "../Multilanguage/Translate";

const NewReleases = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState("0");
  const [rowItemsClass,setRowItemsClass] = useState("");
  const [rowItemCountNumber, setRowItemCountNumber] = useState(5);
  const windowSize = useWindowWidth();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const thumbnailOrientation = data?.thumbnail_orientation ? data?.thumbnail_orientation : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const count = data?.shows?.length;
  let displayedData = data?.shows;
  const isSingleCard = data?.shows?.length === 1;

  const onSlideChange = (swiper) => {
    const lastSlideIndex = swiper.activeIndex + (swiper.params.slidesPerView - 1);

    if (lastSlideIndex >= swiper.slides.length) {
      setActiveIndex(swiper.slides.length - 1);
    } else {
      setActiveIndex(lastSlideIndex);
    }
  };

  const handleSwiperInit = (swiperInstance) => {
    swiperRef.current = swiperInstance;
    onSlideChange(swiperInstance); 
  };

  // useEffect(() => {
  //   if(windowSize>980){
  //     if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
  //       setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
  //     }else {
  //       setRowItemsClass("rowCount5");
  //     }
  //   } else {
  //     setRowItemsClass("rowCount5");
  //   }
  // }, [projectInfo,windowSize]);

    useEffect(() => {
      if (windowSize > 980) {
        const newClass = projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
          ? rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT)
          : "rowCount5";
        setRowItemsClass(newClass);
        setRowItemCountNumber(parseInt(newClass.replace(/\D/g, ''), 10));
      } else {
        setRowItemsClass("rowCount5");
        if (windowSize > 767){
          setRowItemCountNumber(3);
        } else if (windowSize > 479){
          setRowItemCountNumber(2);
        } else if (windowSize > 319){
          setRowItemCountNumber(1);
        }
      }
    }, [projectInfo, windowSize]);

  if (isRTL && count > rowItemCountNumber) {
    displayedData = [...data?.shows].reverse();
  }

  return (
    <div className={`newContainer ${
      isRTL ? (
        count > rowItemCountNumber
          ? "ltr"
          : windowSize < 980
          ? "rtl"
          : "rtl"
      ) : (
        ""
      )}`}
      >
      <div className='wrapper'>
      <div className="header-row">
      <div className="header">
          <h1>{data?.category_name}</h1>
        </div>
        <div className="view-all"          
          // onClick={() =>
          //   navigate(
          //     data?.type === 'SHORTS' ? `/shorts` : `/category/${data?.key}`,
          //     {
          //       state: {
          //         careers: data?.key,
          //         type: data?.type,
          //         thumbnailOrientation
          //       },
          //     }
          //   )
          // }
          onClick={() => navigateToCategory(navigate, data, thumbnailOrientation)}
          >
          <Translate textKey={'view_all'} />
        <ArrowIcon className="arrow-icon" />
        </div>
        </div>
        <Swiper
          key={isRTL ? (count > rowItemCountNumber ? "ltr" : windowSize < 980 ? "rtl" : "rtl" ) : ""}
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={5}
          slidesPerGroup={5}
          navigation={true}
          onSlideChange={onSlideChange}
          watchSlidesProgress
          loop={false}
          onSwiper={handleSwiperInit} 
          initialSlide={(isRTL && count > rowItemCountNumber) ? count - 1 : 0}
          className={thumbnailOrientation===`PORTRAIT`?`items portrait ${rowItemsClass}`:`items landscape ${rowItemsClass}`}
          breakpoints={{
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 20,
            },
            980: data?.type === "SHORTS" ? {
              slidesPerView: 4,
              spaceBetween: 10,
            } :
            {
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : 4,
              slidesPerGroup: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) : 4,
              spaceBetween: 20,
            },
            1200: data?.type === "SHORTS" ? {
              slidesPerView: 5,
              spaceBetween: 10,
            } :
            {
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : 5,
              slidesPerGroup: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) : 5,
              spaceBetween: 20,
            },
          }}
          >
            {displayedData?.map((item, index) => (
              <SwiperSlide key={index} className={isSingleCard ? "" : index === activeIndex ? "translate-last" : ""}>
                <div className={data?.type === "SHORTS" ? "shortsCardContainer" : "show"}>

                  {
                    data?.type === "MAGAZINES" ? (
                      <MagazineCard
                        data={item}
                        url={data.url}
                        metaData={true}
                        cardHover={true}
                      />
                    ) : data?.type === "SHORTS" ? (
                      <ShortsCard
                        data={item}
                        season={false}
                        metaData={true}
                        type={data?.type}
                        vanityUrl={item?.vanity_url}
                      />
                    ) : (
                      <ShowCard
                        data={item}
                        season={false}
                        metaData={true}
                        type={data?.type}
                        thumbnailOrientation={thumbnailOrientation}
                      />
                    )
                  }
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          </div>
          <div className="view-all-mobile"          
          onClick={() =>
            navigate(
              data?.type === 'SHORTS' ? `/shorts` : `/category/${data?.key}`,
              {
                state: {
                  careers: data?.key,
                  type: data?.type,
                  thumbnailOrientation
                },
              }
            )
          }>
            <Translate textKey={'view_all'} />
          <ArrowIcon className="arrow-icon" />
        </div>
      </div>
  );
};

export default NewReleases;
