import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play1.svg";
import { ReactComponent as AddToPlaylistIcon } from "../../assets/Icons/addtoplaylist.svg";
import { ReactComponent as RemoveFromPlaylistIcon } from "../../assets/Icons/removefromplaylist.svg";
import { appInfo, fetchEventDetails } from "../../network/service";
import { updateWatchlistData } from "../../network/service";
import { ToastContainer, toast } from "react-toastify";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { ReactComponent as PremiumIcon } from "../../assets/Icons/premium.svg";
import { imageUrlCheck } from "../../utils/utils";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import { getSelectedChannel } from "../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import { Add } from "@mui/icons-material";
import Util from "./util";
import Translate from "../Multilanguage/Translate";

const GaiaCard = ({ data, metaData, imageUrl, type, thumbnailOrientation, playlist = true }) => {
  const {
    showHandleClick,
    replaceImage,
    // afterImageLoad,
    addToWatchlist,
    removeFromWatchlist,
    subscriptionCheck,
  } = Util();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const isRTL = useSelector((state) => state?.rtl?.isRTL);
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [hovered, setHovered] = useState(false);
  const [imagURL, setImageURL] = useState(
    thumbnailOrientation === "PORTRAIT"
      ? require("../../assets/Images/loading-portrait.gif")
      : require("../../assets/Images/loading-landscape.gif")
  );
  const [isImageLoad, setIsImageLoad] = useState(false);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    if (data?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }
  }, [data]);

  useEffect(() => {
    setIsImageLoad(true)
    let image;
    if (
      imageUrlCheck(
        thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200
      )
    ) {
      image =
        thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200;
    } else {
      image =
      process.env.REACT_APP_IMAGE_URL +
        (thumbnailOrientation === "PORTRAIT"
          ? data?.logo || data?.thumbnail || data?.banner_portrait
          : data?.logo_thumb || data?.thumbnail_350_200);
    }

    setImageURL(image);
  }, [data, thumbnailOrientation]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={`gaiaShowCardContainer ${hovered ? "hovered" : ""} ${isRTL ? "rtl" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title={data?.show_name || data?.video_title}
    >
      {/* <ToastContainer /> */}

      <div className={`imageContainer ${hovered ? "hidden" : ""}`}>
        <img
          className={
            !isImageLoad
              ? thumbnailOrientation === "PORTRAIT"
                ? "portrait"
                : "landscape"
              : "showImage"
          }
          src={imagURL}
          key={data?.show_id}
          onError={(e) => replaceImage(e, thumbnailOrientation, projectInfo)}
          // onLoad={(e) =>
          //   afterImageLoad(
          //     e,
          //     thumbnailOrientation,
          //     data,
          //     setImageURL,
          //     setIsImageLoad,
          //     imageUrlCheck
          //   )
          // }
          onClick={() =>
            showHandleClick(
              data,
              type,
              projectInfo,
              thumbnailOrientation,
              getPodcastModal,
              getSelectedChannel,
              getSelectedSchedule
            )
          }
          alt="ShowImage"
        />
        <div className="premiumStatus">
          {data?.is_free_video === true ? (
            <div className="free">
              <img
                src={require("../../assets/Images/free.png")}
                alt="FreeIcon"
              />
            </div>
          ) : data?.is_locked === true ? (
            <div className="lock">
              <img
                src={require("../../assets/Images/lock.png")}
                alt="LockIcon"
              />
            </div>
          ) : null}
        </div>
        {data?.type === "CONTINUE_WATCHING" && (
          <div className="continueWatchingBar">
            <div
              className="line"
              style={{
                width:
                  data?.watched_percentage <= 1
                    ? 1 + "%"
                    : data?.watched_percentage + "%",
              }}
            ></div>
          </div>
        )}

        <div className={`hover-content ${hovered ? "visible" : ""}`}>
          <img
            className={
              !isImageLoad
                ? thumbnailOrientation === "PORTRAIT"
                  ? "portrait"
                  : "landscape"
                : "showImage"
            }
            src={imagURL}
            key={data?.show_id}
            onError={(e) => replaceImage(e, thumbnailOrientation, projectInfo)}
            // onLoad={(e) =>
            //   afterImageLoad(
            //     e,
            //     thumbnailOrientation,
            //     data,
            //     setImageURL,
            //     setIsImageLoad,
            //     imageUrlCheck
            //   )
            // }
            onClick={() =>
              showHandleClick(
                data,
                type,
                projectInfo,
                thumbnailOrientation,
                getPodcastModal,
                getSelectedChannel,
                getSelectedSchedule
              )
            }
            alt="ShowImage"
          />
          <div className="premiumStatus">
            {data?.is_free_video === true ? (
              <div className="free">
                <img
                  src={require("../../assets/Images/free.png")}
                  alt="FreeIcon"
                />
              </div>
            ) : data?.is_locked === true ? (
              <div className="lock">
                <img
                  src={require("../../assets/Images/lock.png")}
                  alt="LockIcon"
                />
              </div>
            ) : null}
          </div>
          {data?.type === "CONTINUE_WATCHING" && (
            <div className="continueWatchingBar">
              <div
                className="line"
                style={{
                  width:
                    data?.watched_percentage <= 1
                      ? 1 + "%"
                      : data?.watched_percentage + "%",
                }}
              ></div>
            </div>
          )}
          <div className="extraActions">
            <button
              className="playIconContainer"
              onClick={() =>
                showHandleClick(
                  data,
                  type,
                  projectInfo,
                  thumbnailOrientation,
                  getPodcastModal,
                  getSelectedChannel,
                  getSelectedSchedule
                )
              }
            >
              <PlayIcon />
              <Translate textKey={'watch'} />
            </button>
            {metaData && data?.show_id && playlist && (
              <>
                {watchlistStatus === "added" ? (
                  <button
                    className="addToList"
                    onClick={() =>
                      removeFromWatchlist(
                        data?.show_id,
                        appInfo,
                        setWatchlistStatus
                      )
                    }
                  >
                    <RemoveFromPlaylistIcon />
                  </button>
                ) : (
                  <button
                    className="addToList"
                    onClick={() =>
                      addToWatchlist(
                        user,
                        data?.show_id,
                        appInfo,
                        setWatchlistStatus
                      )
                    }
                  >
                    <AddToPlaylistIcon />
                  </button>
                )}
              </>
            )}
          </div>
          <h1
            className="title"
            onClick={() =>
              showHandleClick(
                data,
                type,
                projectInfo,
                thumbnailOrientation,
                getPodcastModal,
                getSelectedChannel,
                getSelectedSchedule
              )
            }
          >
            {data?.show_name || data?.video_title}
          </h1>
          <div
            className={`episode ${data?.single_video !== 2 ? "hidden" : ""}`}
          >
            {data?.video_title && <span>{data?.video_title}</span>}
          </div>
          <div className="duration">
            {data?.duration_text && (
              <span className={`duration ${data?.year && "showDot"} ${/^[A-Za-z0-9\s]+$/.test(data?.duration_text) ? "ltr" : ""}`}>
                {data?.duration_text}
              </span>
            )}
            {data?.rating && (
              <span className="certificate">{data?.rating}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GaiaCard;
