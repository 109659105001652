import { updateWatchlistData } from "../../network/service";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Util() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchWatchlist = async (appInfo) => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };

  const updateWatchlist = async (showId, flag, appInfo, setWatchlistStatus) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        fetchWatchlist(appInfo);
        if (flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {}
  };

  const addToWatchlist = (user, showId, appInfo, setWatchlistStatus) => {
    if (user) {
      updateWatchlist(showId, 1, appInfo, setWatchlistStatus);
    } else {
      navigate("/login");
    }
  };

  const removeFromWatchlist = (showId, appInfo, setWatchlistStatus) => {
    updateWatchlist(showId, 0, appInfo, setWatchlistStatus);
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub?.some((user) =>
      videoSub?.some((video) => user.sub_id === video.subscription_id)
    );
    return hasMatch;
  };

  const showHandleClick = (
    data,
    type,
    projectInfo,
    thumbnailOrientation,
    getPodcastModal,
    getSelectedChannel,
    getSelectedSchedule
  ) => {
    if (data?.type === "LIVE_EVENT") {
      navigate(
        `/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`
      );
    } else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data,
      };
      dispatch(
        getPodcastModal({
          podcastModal: podcastData,
        })
      );
    } else if (data?.type === "NEWS") {
      navigate("/news");
    } else if (
      data?.type === "CONTINUE_WATCHING" ||
      type === "CONTINUE_WATCHING"
    ) {
      navigate(`/videos/${data?.video_vanity_url}`, {
        state: { videoDetails: data, continue: true },
      });
    } else if (data?.type === "UPCOMING_EVENT") {
      navigate(
        `/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`
      );
    } else if (data?.type === "VIDEO") {
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails: data },
      });
    } else if (data?.type === "SHOW") {
      navigate(`/show-details/${data?.vanity_url}`, {
        state: {
          showId: data?.show_id,
          type: data?.type,
          thumbnailOrientation,
        },
      });
    } else if (data?.type === "FASTCHANNEL" || data?.type === "FASTCHANNELS" || type === "FASTCHANNELS") {
      if (
        projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true"
      ) {
        dispatch(
          getSelectedChannel({
            selectedChannel: { channel_id: data?.channel_id },
          })
        );
        dispatch(
          getSelectedSchedule({
            selectedSchedule: { channel_id: data?.channel_id },
          })
        );
        navigate("/live-channels");
      } else {
        navigate("/player");
      }
    } else if (data?.type === "EVENT") {
      navigate(
        `/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`
      );
    } else if (type === "Episode") {
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails: data },
      });
    } else if (type === "TOP_TRENDING") {
      navigate(`/videos/${data?.vanity_url}`, {
        state: { videoDetails: data },
      });
    } else if (type !== "episodes") {
      navigate(`/show-details/${data?.vanity_url}`, {
        state: {
          showId: data?.show_id,
          type: data?.type,
          thumbnailOrientation,
        },
      });
    }
  };

  const replaceImage = (error, thumbnailOrientation, projectInfo) => {
    error.target.src =
      thumbnailOrientation === "PORTRAIT"
        ? projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE_PORTRAIT
        : projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE;
  };

  return {
    fetchWatchlist,
    updateWatchlist,
    addToWatchlist,
    removeFromWatchlist,
    subscriptionCheck,
    showHandleClick,
    replaceImage,
  };
}
